import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';

import { BaseUseApiProps } from 'src/types/api/api.types';

import {
  makeApiAdminCreateInvitation,
  makeApiAdminDeleteInvitation,
  makeApiAdminSendInvitations,
} from '../functions/makeApiAdminManage';

export const useApiCreateInvitation = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiAdminCreateInvitation(), []);

  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiDeleteInvitation = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiAdminDeleteInvitation(), []);

  return useApi({ doRequest, onSuccess, onResponse, onError });
};

export const useApiSendInvitations = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiAdminSendInvitations(), []);

  return useApi({ doRequest, onSuccess, onResponse, onError });
};
