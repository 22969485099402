import { FC } from 'react';

import PageContainer from 'src/components/PageContainer';

import Price from 'src/features/pages/private/Price';

const PricePage: FC = () => {
  return (
    <PageContainer>
      <Price />
    </PageContainer>
  );
};

export default PricePage;
