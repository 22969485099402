import { FC } from 'react';

import AgentEditForm from 'src/features/components/agent/AgentEditForm';

import useNavigationBlock from 'src/hooks/useNavigationBlock';
import useTexts from 'src/hooks/useTexts';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import { UserTier } from 'src/types/dao/user.types';

import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

const CreateAgent: FC = () => {
  const { initialData, handleSave, canRedirect, setIsInitialDataChanged } =
    useComponentProps();

  const { t } = useTexts();

  const currentUser = useAppSelector(getCurrentUser);

  const { NavigationGuard } = useNavigationBlock({
    when: !canRedirect,
    message: t('general.have_unsaved_changes'),
  });

  return (
    <>
      <NavigationGuard />
      <div className={styles['container']}>
        <AgentEditForm
          initialData={initialData}
          onSave={handleSave}
          setIsInitialDataChanged={setIsInitialDataChanged}
          isAdmin={currentUser?.access_tier === UserTier.ADMIN}
        />
      </div>
    </>
  );
};

export default CreateAgent;
