import classNames from 'classnames';
import { FC } from 'react';

import useTexts from 'src/hooks/useTexts';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

const NothingToShow: FC<Props> = ({ className }) => {
  const { t } = useTexts();

  return (
    <div className={classNames(className, styles['wrapper'])}>
      <p>{t('general.nothing_to_show')}...</p>
    </div>
  );
};

export default NothingToShow;
