import { getTierWeight, UserTier } from 'common-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApiGetAgent } from 'src/api/agent/hooks/useApiGet';
import { useApiUpdateAgent } from 'src/api/agent/hooks/useApiManage';
import { useApiAdminAgentSummon } from 'src/api/game/hooks/useApiAdminGet';

import { prepareInitialAgentFormData } from 'src/features/components/agent/AgentEditForm/functions/formData';

import usePageLoader from 'src/hooks/usePageLoader';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import { GetAgentResponse } from 'src/types/api/agent.types';
import { AgentFormData } from 'src/types/dao/agent.types';

type Props = {
  id: string;
};

const useComponentProps = ({ id }: Props) => {
  const [initialData, setInitialData] = useState<AgentFormData | undefined>();
  const [isInitialDataChanged, setIsInitialDataChanged] =
    useState<boolean>(false);

  const history = useHistory();

  const currentUser = useAppSelector(getCurrentUser);
  const userAccessTier = useMemo<number>(() => {
    return getTierWeight(currentUser?.access_tier);
  }, [currentUser]);

  const { doRequest: getAgent, isLoading: agentLoading } = useApiGetAgent({
    onResponse: useCallback((data: GetAgentResponse) => {
      setInitialData(prepareInitialAgentFormData(data));
    }, []),
    onError: useCallback(() => {
      history.push('/');
    }, [history]),
  });

  const handleGet = useCallback(() => {
    getAgent({ agentId: id });
  }, [getAgent, id]);

  const { doRequest: save, isLoading: saveLoading } = useApiUpdateAgent({
    onSuccess: handleGet,
  });

  usePageLoader({
    loading: saveLoading || !!(agentLoading && initialData),
  });

  const handleSave = useCallback(
    (formData: AgentFormData) => {
      save({ agentId: id, formData });
    },
    [id, save],
  );

  const { doRequest: startGame } = useApiAdminAgentSummon();

  const canRedirect = useMemo<boolean>(() => {
    if (!initialData) {
      return true;
    }
    return isInitialDataChanged;
  }, [initialData, isInitialDataChanged]);

  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdmin = useMemo<boolean>(
    () => userAccessTier >= getTierWeight(UserTier.ADMIN),
    [userAccessTier],
  );

  return {
    isLoading: agentLoading,
    startGame,
    handleSave,
    initialData,
    canRedirect,
    setIsInitialDataChanged,
    isAdmin,
  };
};

export default useComponentProps;
