import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import styles from '../../styles.module.scss';

export type Props = {
  children: React.ReactNode;
};

const DotsActions: FC<Props> = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles['dots-actions-wrapper'], {
        [styles['active']]: isActive,
      })}
      onClick={handleClick}
    >
      <div className={styles['dots-wrapper']}>
        <div className={styles['dots']}></div>
        <div className={styles['dots']}></div>
        <div className={styles['dots']}></div>
      </div>
      <div className={styles['children-wrapper']}>{children}</div>
    </div>
  );
};

export default DotsActions;
