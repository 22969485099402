import { api } from 'common-ui';
import { URLS } from 'common-ui';

import {
  ManageInvitationRequestData,
  ManageInvitationsRequestData,
} from 'src/types/api/invitation.types';

export const makeApiAdminCreateInvitation =
  () =>
  ({ formData }: { formData: ManageInvitationRequestData }) =>
    api.post<void>(
      `${URLS.invitation.admin.createInvitation}?count=${formData.count}`,
      formData,
    );

export const makeApiAdminDeleteInvitation =
  () =>
  ({ id }: { id: string }) =>
    api.delete<void>(URLS.invitation.admin.deleteInvitation, {
      params: { invitation_id: id },
    });

export const makeApiAdminSendInvitations =
  () =>
  ({ formData }: { formData: ManageInvitationsRequestData }) =>
    api.post<void>(URLS.invitation.admin.sendInvitations, formData);
