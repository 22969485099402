import classNames from 'classnames';

import Button from 'src/components/Buttons/Button';

import useTexts from 'src/hooks/useTexts';

import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

const GoogleLogo = require('src/assets/brand_icon/google_icon.svg').default;

const GoogleLogin = () => {
  const { googleLogin } = useComponentProps();
  const { t } = useTexts();

  return (
    <div className={styles['page']}>
      <div className={classNames(styles['content-wrapper'], styles['simple'])}>
        <h1 className={styles['title']}>{t('googleLogin.title')}</h1>
        <div className={styles['content']}>
          <Button
            variant='contained'
            onClick={() => googleLogin()}
            className={classNames(styles['login-button'])}
            startIcon={
              <img
                src={GoogleLogo}
                alt='Google sign-in'
                className={classNames(styles['google-logo'])}
              />
            }
          >
            {t('googleLogin.login_button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GoogleLogin;
