import { CurrentUserResponse } from 'common-ui';
import { UserTier } from 'common-ui';

export const mockUserResponse: CurrentUserResponse = {
  id: 'uuid',
  name: 'Nancy Anderson',
  email: 'nancyanderson@mailforspam.com',
  picture:
    'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png',
  access_tier: UserTier.ADMIN,
  minutes: 100,
  subscription: null,
  last_login: '2021-10-01T00:00:00Z',
  created_at: '2021-10-01T00:00:00Z',
  social_media: 'google',
  profile: 'test',
};

// export const mockUserGroupResponse: CurrentUserResponse = {
//   data: [
//     {
//       id: "uuid",
//       name: "Nancy Anderson",
//       email: "nancyanderson@mailforspam.com",
//       picture:
//         "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
//       access_tier: UserTier.ADMIN,
//       minutes: 100,
//       subscription: null,
//       last_login: "2021-10-01T00:00:00Z",
//     },
//     {
//       id: "uuid",
//       name: "Nancy Anderson",
//       email: "nancyanderson@mailforspam.com",
//       picture:
//         "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
//       access_tier: UserTier.ADMIN,
//       minutes: 100,
//       subscription: null,
//       last_login: "2021-10-01T00:00:00Z",
//     },
//     {
//       id: "uuid",
//       name: "Nancy Anderson",
//       email: "nancyanderson@mailforspam.com",
//       picture:
//         "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
//       access_tier: UserTier.ADMIN,
//       minutes: 100,
//       subscription: null,
//       last_login: "2021-10-01T00:00:00Z",
//     },
//     {
//       id: "uuid",
//       name: "Nancy Anderson",
//       email: "nancyanderson@mailforspam.com",
//       picture:
//         "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
//       access_tier: UserTier.ADMIN,
//       minutes: 100,
//       subscription: null,
//       last_login: "2021-10-01T00:00:00Z",
//     },
//     {
//       id: "uuid",
//       name: "Nancy Anderson",
//       email: "nancyanderson@mailforspam.com",
//       picture:
//         "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
//       access_tier: UserTier.ADMIN,
//       minutes: 100,
//       subscription: null,
//       last_login: "2021-10-01T00:00:00Z",
//     },
//     {
//       id: "uuid",
//       name: "Nancy Anderson",
//       email: "nancyanderson@mailforspam.com",
//       picture:
//         "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
//       access_tier: UserTier.ADMIN,
//       minutes: 100,
//       subscription: null,
//       last_login: "2021-10-01T00:00:00Z",
//     },
//   ],
// };
