import CloseIcon from '@mui/icons-material/Close';
import { closeSnackbar, SnackbarProvider } from 'notistack';

import Loader from 'src/components/Loader';
import AppRouter from 'src/components/Router';

import { useAppSelector } from 'src/store/hooks';
import { getAppState } from 'src/store/reducers/appSlice/selectors';

const AppRoot = () => {
  const { isLoading } = useAppSelector(getAppState);

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      action={(snackbarId) => (
        <CloseIcon onClick={() => closeSnackbar(snackbarId)}></CloseIcon>
      )}
    >
      {isLoading && <Loader fullPage />}
      <AppRouter />
    </SnackbarProvider>
  );
};

export default AppRoot;
