import { api } from 'common-ui';
import { URLS } from 'common-ui';
import { UserLoginResponse } from 'common-ui';

export const makeApiGoogleLogin =
  () =>
  ({ token }: { token: string }) =>
    api.get<UserLoginResponse>(URLS.user.loginGoogle, {
      headers: {
        Authorization: token,
      },
    });

export const makeApiLogout = () => () => api.get<void>(URLS.user.logout);
