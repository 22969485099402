import { ReactNode, useCallback } from 'react';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

type Props = Omit<RouteProps, 'component' | 'render'> & {
  component: ReactNode;
};

const AppRoute = ({ path, component, ...rest }: Props) => {
  const render = useCallback(() => component, [component]);

  return <Route {...rest} path={path} render={render} />;
};

export default AppRoute;
