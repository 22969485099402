import { FC } from 'react';

import Loader from 'src/components/Loader';
import PageContainer from 'src/components/PageContainer';

import RunningGameInstances from 'src/features/components/game/RunningGameInstances';

import styles from './styles.module.scss';

const RunningGamePlaysPage: FC = () => {
  return (
    <PageContainer simple width={false}>
      <div className={styles['page']}>
        <RunningGameInstances
          additionalClassName={styles['info-group']}
          customLoader={<Loader absolute backdrop />}
        />
      </div>
    </PageContainer>
  );
};

export default RunningGamePlaysPage;
