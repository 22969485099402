import { useMemo } from 'react';

import {
  makeApiAdminAgentSummon,
  makeApiAdminGetGameInstanceLog,
  makeApiAdminGetGameInstances,
} from 'src/api/game/functions/makeApiAdminGet';

import { useApi } from 'src/services/api/hooks/useApi';
import { useListApi } from 'src/services/api/hooks/useListApi';

import { BaseUseApiProps, BaseUseListApiProps } from 'src/types/api/api.types';
import {
  AdminGetGameInstancesResponse,
  GetDownloadLinkResponse,
} from 'src/types/api/game.types';
import { GameInstance } from 'src/types/dao/game.types';

import adminGameInstancesMock from '../mock/adminGameInstances';
import adminInstanceLogMock from '../mock/adminInstanceLog';

export const useApiAdminGetGameInstances = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseListApiProps<AdminGetGameInstancesResponse> = {}) => {
  const doRequest = useMemo(() => makeApiAdminGetGameInstances(), []);
  return useListApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
    mockData: adminGameInstancesMock,
  });
};

export const useApiAdminGetGameInstanceLog = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<GetDownloadLinkResponse> = {}) => {
  const doRequest = useMemo(() => makeApiAdminGetGameInstanceLog(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
    mockData: adminInstanceLogMock,
  });
};

export const useApiAdminAgentSummon = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<GameInstance> = {}) => {
  const doRequest = useMemo(() => makeApiAdminAgentSummon(), []);
  return useApi({ doRequest, onSuccess, onResponse, onError });
};
