import { AllFilter, User } from 'common-ui';

import { GameInstance } from './game.types';

export type AgentCharacteristics = Record<string, string | any>;

export enum AgentStatus {
  PUBLIC = 'public',
  PRIVATE = 'private',
  ARCHIVED = 'archived',
  FEATURED = 'featured',
  ENTERPRISE = 'enterprise',
}

export enum AgentInstanceStatusBackendFormat {
  CONSUMER_ACTIVE = 'consumer_active',
  CONSUMER_ARCHIVED = 'consumer_archived',
  ENTERPRISE_ACTIVE = 'enterprise_active',
  ENTERPRISE_ARCHIVED = 'enterprise_archived',
}

export type AgentStatusFilter = AgentStatus | AllFilter;

export type Agent = {
  id: string;
  name: string;
  inner_state: any;
  avatar_url: string;
  advanced?: string;
  memory: {
    long_term_memory: string;
  };
  status: AgentStatus;
  owner_id: string;
  owner_name?: string;
  owner_access?: string;
  total_players?: number;
  total_play_sessions?: number;
  total_play_time?: number;
  admin_snapshot: any;
  safety_analysis: any;
  created_at?: Date;
  last_updated_time?: Date;
  image_url: string;
  thumbnail_url: string;
  animation_url: string;
  minecraft_skin_url: string;
};

export type AgentFormData = Pick<
  Agent,
  | 'name'
  | 'inner_state'
  | 'avatar_url'
  | 'status'
  | 'admin_snapshot'
  | 'safety_analysis'
  | 'image_url'
  | 'animation_url'
  | 'thumbnail_url'
  | 'minecraft_skin_url'
> & {
  advanced: string;
  memory: { long_term_memory: string };
};

export type NewCharacteristicForm = {
  name: string;
  value: string;
};

export type AgentInstanceCheckpoint = {
  // The unique ID of the agent instance checkpoint
  id: string;

  // The unique ID of the agent instance, which is the instance that the checkpoint is associated with.
  instance_id: string | null;

  // The ID of the agent prototype
  proto_id: string;

  // ID of the user who created the AgentInstanceCheckpoint
  initiator_id: string;

  // Reference to the initiator (optional, as it might be null if the user was deleted)
  initiator?: User;

  // The ID of the GameInstance that the agent instance checkpoint is associated with
  game_instance_id: string | null;

  // Reference to the GameInstance (optional, as it might be null if the game instance was deleted)
  game_instance?: GameInstance;

  // The status of the agent instance
  status: string;

  // Additional metadata of the agent instance checkpoint.
  meta_data: any;

  // The initial state of the agent instance checkpoint
  agent_proto: any;

  // Raw json data of the agent instance checkpoint
  readable_agent_snapshot: any;

  // When the agent instance was last updated
  last_updated_time: string;
};

export type InstanceResponse = {
  description: string;
  id: string;
  instance_id: string;
  last_updated_time: string;
  name: string;
  status: string;
};
