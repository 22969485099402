export enum StoreFetchStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type BaseSliceState = {
  status: StoreFetchStatus;
  error?: string;
};
