import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React from 'react';

import IconButton from 'src/components/Buttons/IconButton';

import { InvitationData } from 'src/types/dao/invitation.types';

import { InvitationCodesTableActionConfig } from './index';
import styles from './styles.module.scss';

export const renderInvitationCell = (
  invitation: InvitationData,
  tableActionConfig: InvitationCodesTableActionConfig,
) => ({
  invitationCode: invitation.id,
  minutes: invitation.minutes,
  createdAt: invitation.created_at
    ? new Date(invitation.created_at).toLocaleString()
    : null,
  redeemTime: invitation.redeem_time,
  copyCode: (
    <div className={styles['action-item']}>
      <IconButton
        tooltip={tableActionConfig?.t('invitation.copy_code')}
        className={styles['action']}
        onClick={() =>
          tableActionConfig?.copyInvitationCode(invitation.id || '')
        }
      >
        <ContentCopyIcon />
      </IconButton>
    </div>
  ),
  deleteCode: (
    <div className={styles['action-item']}>
      <IconButton
        tooltip={tableActionConfig?.t('invitation.delete_code')}
        className={styles['action']}
        onClick={() => tableActionConfig?.deleteCode(invitation.id || '')}
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </div>
  ),
});

export const tableHeaders = [
  'Invitation Code',
  'Minutes',
  'Created At',
  'Redeem Time',
  'Copy Code',
  'Delete Code',
];
