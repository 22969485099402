import { FC } from 'react';

import StripePricingTable from 'src/components/PricingTable';

import useTexts from 'src/hooks/useTexts';

import styles from './styles.module.scss';

const Price: FC = () => {
  const { t } = useTexts();

  return (
    <div className={styles['wrapper']}>
      <h1 className={styles['title']}>{t('payment.choose_your_plan')}</h1>
      <div className={styles['table-wrapper']}>
        <StripePricingTable />
      </div>
    </div>
  );
};

export default Price;
