import { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import PageContainer from 'src/components/PageContainer';

import CreateAgent from 'src/features/pages/private/CreateAgent';

import { getUrlSearchParam } from 'src/helpers/urls';

const CreateAgentPage: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const fromUrl = useMemo<string | undefined>(
    () => getUrlSearchParam(location.search, 'from'),
    [location],
  );

  const handleGoBack = useCallback(() => {
    fromUrl && history.push(fromUrl);
  }, [fromUrl, history]);

  return (
    <PageContainer width='lg'>
      {fromUrl && <button onClick={handleGoBack}>Back to game editing</button>}
      <CreateAgent />
    </PageContainer>
  );
};

export default CreateAgentPage;
