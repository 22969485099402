import { useMemo } from 'react';

import { useListApi } from 'src/services/api/hooks/useListApi';

import { BaseUseApiProps } from 'src/types/api/api.types';
import { ChatResponse } from 'src/types/api/chat.types';

import { makeApiChat } from '../functions/makeApiChat';

export const useApiAdminGet = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<ChatResponse> = {}) => {
  const doRequest = useMemo(() => makeApiChat(), []);
  return useListApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};
