import {
  AddCircleOutline,
  Logout,
  SettingsOutlined,
} from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { googleLogout } from '@react-oauth/google';
import classNames from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useApiLogout } from 'src/api/user/hooks/useApiAuth';

import Dropdown, { DropdownArrayItem } from 'src/components/Dropdown';

import {
  ADMIN_COMPLETED_GAME_PLAYS_ROUTE,
  ADMIN_INVITATION_ROUTE,
  ADMIN_RUNNING_GAME_PLAYS_ROUTE,
  ADMIN_USERS_ROUTE,
  AGENTS_ROUTE,
  SETTINGS_ROUTE,
} from 'src/constants/routes';

import InvitationModal from 'src/features/components/invitations/InvitationModal';

import useTexts from 'src/hooks/useTexts';

import { useAppDispatch } from 'src/store/hooks';
import { userSlice } from 'src/store/reducers/userSlice';

import { User } from 'src/types/dao/user.types';

import styles from './styles.module.scss';

export type Props = {
  user: User;
};

const UserNavbarContent: FC<Props> = ({ user }) => {
  const [invitationOpen, setInvitationOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const { t } = useTexts();

  const history = useHistory();

  const handleLogout = useCallback(() => {
    googleLogout();
    dispatch(userSlice.actions.setUserProfile(undefined));
  }, [dispatch]);

  const goToSettingsPage = useCallback(() => {
    history.push(SETTINGS_ROUTE);
  }, [history]);

  const { doRequest: logout } = useApiLogout({
    onSuccess: handleLogout,
    onError: handleLogout,
  });

  const isRunningGamePlaysLinkActive = useMemo<boolean>(
    () => location.pathname.includes(ADMIN_RUNNING_GAME_PLAYS_ROUTE),
    [location],
  );
  const isCompletedGamePlaysLinkActive = useMemo<boolean>(
    () => location.pathname.includes(ADMIN_COMPLETED_GAME_PLAYS_ROUTE),
    [location],
  );
  const isInvitationsLinkActive = useMemo<boolean>(
    () => location.pathname.includes(ADMIN_INVITATION_ROUTE),
    [location],
  );
  const isUsersLinkActive = useMemo<boolean>(
    () => location.pathname.includes(ADMIN_USERS_ROUTE),
    [location],
  );
  const isAgentsLinkActive = useMemo<boolean>(
    () => location.pathname.includes(AGENTS_ROUTE),
    [location],
  );

  const profileDropdownContent = useMemo(
    () => [
      {
        label: (
          <div className={classNames(styles['dropdown-row'], styles['center'])}>
            <Avatar src={user.picture} />
          </div>
        ),
        type: 'info',
      },
      {
        label: (
          <div className={classNames(styles['dropdown-row'], styles['center'])}>
            <span className={styles['user-name']}>{user.name}</span>
          </div>
        ),
        type: 'info',
      },
      {
        label: (
          <div className={classNames(styles['dropdown-row'], styles['center'])}>
            <span className={styles['text']}>{user.email}</span>
          </div>
        ),
        type: 'info',
      },
      {
        label: (
          <div className={classNames(styles['dropdown-row'], styles['center'])}>
            <span className={styles['text']}>
              {t('invitation.minutes')}: {user.minutes}
            </span>
          </div>
        ),
        type: 'info',
      },
      ...(user.subscription
        ? [
            {
              label: (
                <div
                  className={classNames(
                    styles['dropdown-row'],
                    styles['center'],
                  )}
                >
                  <span className={styles['text']}>
                    {t('general.subscription')}: {user.subscription}
                  </span>
                </div>
              ),
              type: 'info',
            },
          ]
        : []),
      {
        label: (
          <div className={classNames(styles['dropdown-row'], styles['center'])}>
            <span className={styles['text']}>
              {t('user.access_tier')}: {t('user.' + user.access_tier)}
            </span>
          </div>
        ),
        type: 'info',
      },
      {
        label: (
          <div className={classNames(styles['dropdown-row'])}>
            <AddCircleOutline className={styles['icon']} fontSize='small' />
            <span className={styles['text']}>
              {t('invitation.add_play_minutes')}
            </span>
          </div>
        ),
        callback: () => setInvitationOpen(true),
      },
      {
        label: (
          <div className={classNames(styles['dropdown-row'])}>
            <SettingsOutlined className={styles['icon']} fontSize='small' />
            <span className={styles['text']}>{t('settings.settings')}</span>
          </div>
        ),
        callback: goToSettingsPage,
      },
      {
        label: (
          <div className={styles['dropdown-row']}>
            <Logout className={styles['icon']} fontSize='small' />
            <span className={styles['text']}>{t('general.log_out')}</span>
          </div>
        ),
        divider: true,
        callback: logout,
      },
    ],
    [
      goToSettingsPage,
      logout,
      t,
      user.email,
      user.picture,
      user.name,
      user.minutes,
      user.access_tier,
      user.subscription,
    ],
  ) as Array<DropdownArrayItem>;

  return (
    <div className={styles['nav-content']}>
      <div className={styles['nav-links-wrapper']}>
        <Link
          to={ADMIN_RUNNING_GAME_PLAYS_ROUTE}
          className={classNames(styles['nav-link'], {
            [styles['active']]: isRunningGamePlaysLinkActive,
          })}
        >
          <span>{t('pages.runningGamePlays')}</span>
        </Link>
        <Link
          to={ADMIN_COMPLETED_GAME_PLAYS_ROUTE}
          className={classNames(styles['nav-link'], {
            [styles['active']]: isCompletedGamePlaysLinkActive,
          })}
        >
          <span>{t('pages.completedGamePlays')}</span>
        </Link>
        <Link
          to={ADMIN_USERS_ROUTE}
          className={classNames(styles['nav-link'], {
            [styles['active']]: isUsersLinkActive,
          })}
        >
          <span>{t('pages.users')}</span>
        </Link>
        <Link
          to={ADMIN_INVITATION_ROUTE}
          className={classNames(styles['nav-link'], {
            [styles['active']]: isInvitationsLinkActive,
          })}
        >
          <span>{t('pages.invitationCodes')}</span>
        </Link>
        <Link
          to={AGENTS_ROUTE}
          className={classNames(styles['nav-link'], {
            [styles['active']]: isAgentsLinkActive,
          })}
        >
          <span>{t('pages.agents')}</span>
        </Link>
      </div>
      <div className={styles['profile-section']}>
        <Dropdown list={profileDropdownContent}>
          <Avatar
            className={styles['profile-section--image']}
            src={user.picture}
          />
        </Dropdown>
      </div>
      {invitationOpen && (
        <InvitationModal setOpen={setInvitationOpen} currentUser={user} />
      )}
    </div>
  );
};

export default UserNavbarContent;
