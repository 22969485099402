import { FC } from 'react';

import PageContainer from 'src/components/PageContainer';

import Settings from 'src/features/pages/private/Settings';

const SettingsPage: FC = () => {
  return (
    <PageContainer simple width={false}>
      <Settings />
    </PageContainer>
  );
};

export default SettingsPage;
