import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

import styles from './styles.module.scss';

export type Props = {
  start: Dayjs;
  end: Dayjs;
  setStart: (start: Dayjs) => void;
  setEnd: (end: Dayjs) => void;
};

const DatePickerComponent = ({ start, end, setStart, setEnd }: Props) => {
  const datePickerStyle = {
    '.MuiInputBase-root': {
      color: 'white',
      backgroundColor: '#333',
    },
    '.MuiFormLabel-root': {
      color: 'white',
    },
    '.MuiSvgIcon-root': {
      fill: 'white',
    },
  };

  return (
    <div className={styles['date-pickers-wrapper']}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles['date-pickers']}>
          <DatePicker
            sx={datePickerStyle}
            label='Start date'
            value={start}
            onChange={(newDate) => newDate && setStart(newDate)}
          />
          <DatePicker
            sx={datePickerStyle}
            label='End date'
            value={end}
            onChange={(newDate) => newDate && setEnd(newDate)}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DatePickerComponent;
