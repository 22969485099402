import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { object, string } from 'yup';

import { getRandomFromArray } from 'src/helpers/array';
import { removeKey } from 'src/helpers/object';
import { toSnakeCase } from 'src/helpers/text';

import useFormData from 'src/hooks/useFormData';

import {
  AgentFormData,
  AgentStatus,
  NewCharacteristicForm,
} from 'src/types/dao/agent.types';

import {
  avatarUrls,
  firstNames,
  lastNames,
  occupations,
} from '../data/formData';
import { prepareAgentFormData } from '../functions/formData';
import { prepareNewCharacteristicSchema } from '../functions/schemas';
import { Props as ComponentProps } from '../index';

type Props = {
  initialData: ComponentProps['initialData'];
  onSave: ComponentProps['onSave'];
  setIsInitialDataChanged: ComponentProps['setIsInitialDataChanged'];
};

type ImprovisedData = {
  name: string | null;
  occupation: string | null;
  avatarUrl: string | null;
};

const FormDataValidationSchema = object<Pick<AgentFormData, 'name'>>({
  name: string().required(),
});

const NewCharacteristicInitialData: NewCharacteristicForm = {
  name: '',
  value: '',
};

const useComponentProps = ({
  initialData,
  onSave,
  setIsInitialDataChanged,
}: Props) => {
  const [improvisedData, setImprovisedData] = useState<
    ImprovisedData | undefined
  >(undefined);

  const newCharacteristicValidationSchema = useMemo(
    () => prepareNewCharacteristicSchema(initialData?.inner_state),
    [initialData?.inner_state],
  );

  const {
    formData: newCharacteristicFormData,
    formDataSetters: newCharacteristicFormDataSetters,
    resetFormData: resetNewCharacteristicFormData,
    validate: validateNewCharacteristic,
    errors: newCharacteristicFormErrors,
  } = useFormData<NewCharacteristicForm>({
    initialData: NewCharacteristicInitialData,
    validationSchema: newCharacteristicValidationSchema,
  });

  const {
    formData,
    formDataSetters,
    validate: validateForm,
    errors: formErrors,
  } = useFormData<AgentFormData>({
    initialData: useMemo(
      () => prepareAgentFormData(initialData),
      [initialData],
    ),
    validationSchema: FormDataValidationSchema,
  });

  const setStatus = useCallback(
    (value: AgentStatus) => {
      formDataSetters.status(value);
    },
    [formDataSetters],
  );

  const changeCharacteristic = useCallback(
    (key: string, value: string) => {
      formDataSetters.inner_state({
        ...formData.inner_state,
        [key]: value,
      });
    },
    [formData.inner_state, formDataSetters],
  );

  const changeSafetyAnalysis = useCallback(
    (value: string) => {
      formDataSetters.safety_analysis(value);
    },
    [formDataSetters],
  );

  const changeAdminSnapshot = useCallback(
    (value: string) => {
      formDataSetters.admin_snapshot(value);
    },
    [formDataSetters],
  );

  const changeInnerState = useCallback(
    (value: string) => {
      formDataSetters.inner_state(value);
    },
    [formDataSetters],
  );

  const addNewCharacteristic = useCallback(() => {
    const key = toSnakeCase(newCharacteristicFormData.name.trim());
    if (!validateNewCharacteristic()) {
      return;
    }

    changeCharacteristic(key, newCharacteristicFormData.value);
    resetNewCharacteristicFormData();
  }, [
    changeCharacteristic,
    newCharacteristicFormData,
    resetNewCharacteristicFormData,
    validateNewCharacteristic,
  ]);

  const removeCharacteristic = useCallback(
    (key: string) => {
      const innerState = removeKey(formData.inner_state, key);
      formDataSetters.inner_state(innerState);
    },
    [formData.inner_state, formDataSetters],
  );

  const setMemory = useCallback(
    (key: keyof AgentFormData['memory'], value: string) => {
      formDataSetters.memory({ ...formData.memory, [key]: value });
    },
    [formData.memory, formDataSetters],
  );
  const setLongTermMemory = useCallback(
    (value: string) => setMemory('long_term_memory', value),
    [setMemory],
  );
  const setSafetySnapshot = useCallback(
    (value: string) => {
      formDataSetters.safety_analysis(value);
    },
    [formDataSetters],
  );

  const handleImprovise = useCallback(() => {
    const name = `${getRandomFromArray(firstNames)} ${getRandomFromArray(
      lastNames,
    )}`;
    const occupation = getRandomFromArray(occupations);
    const avatarUrl = getRandomFromArray(avatarUrls);

    const occupationKey = toSnakeCase('occupation');

    const improvised: ImprovisedData = {
      name:
        formData.name && formData.name !== improvisedData?.name ? null : name,
      occupation:
        formData.inner_state[occupationKey] &&
        formData.inner_state[occupationKey] !== improvisedData?.occupation
          ? null
          : occupation,
      avatarUrl:
        formData.avatar_url && formData.avatar_url !== improvisedData?.avatarUrl
          ? null
          : avatarUrl,
    };

    const innerStateToSet: Record<string, string> = {};

    if (improvised.name && improvised.name !== formData.name) {
      formDataSetters.name(name);
    }
    if (improvised.avatarUrl && improvised.avatarUrl !== formData.avatar_url) {
      formDataSetters.avatar_url(avatarUrl);
    }
    if (
      improvised.occupation &&
      improvised.occupation !== formData.inner_state[occupationKey]
    ) {
      innerStateToSet[occupationKey] = occupation;
    }
    if (Object.keys(innerStateToSet).length) {
      formDataSetters.inner_state({
        ...formData.inner_state,
        ...innerStateToSet,
      });
    }
    setImprovisedData(improvised);
  }, [formData, formDataSetters, improvisedData]);

  const save = useCallback(() => {
    if (onSave && validateForm()) {
      try {
        const admin_snapshot = formData.admin_snapshot
          ? JSON.parse(formData.admin_snapshot)
          : null;
        onSave({ ...formData, admin_snapshot });
      } catch (error) {
        console.error(error);
      }
    }
  }, [formData, onSave, validateForm]);

  useEffect(() => {
    if (setIsInitialDataChanged) {
      setIsInitialDataChanged(_.isEqual(formData, initialData));
    }
  }, [formData, initialData, setIsInitialDataChanged]);

  return {
    formData,
    formDataSetters,
    setStatus,

    newCharacteristicFormData,
    newCharacteristicFormDataSetters,
    newCharacteristicFormErrors,

    addNewCharacteristic,
    removeCharacteristic,
    changeCharacteristic,
    setLongTermMemory,
    setSafetySnapshot,

    handleImprovise,

    save,
    formErrors,

    changeAdminSnapshot,
    changeSafetyAnalysis,
    changeInnerState,
  };
};

export default useComponentProps;
