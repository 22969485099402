import { GetApiKeysResponse } from 'src/types/api/keys.types';

const getApiKeysMock: GetApiKeysResponse = {
  data: [
    {
      id: 'uuid1',
      name: 'name1',
      key: 'key1',
      user_id: 'user_id',
      created_at: '',
    },
    {
      id: 'uuid2',
      name: 'name2',
      key: 'key2',
      user_id: 'user_id',
      created_at: '',
    },
  ],
};
export default getApiKeysMock;
