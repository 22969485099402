import classNames from 'classnames';
import React from 'react';

import TableLine from './components/TableLine';
import styles from './styles.module.scss';

interface Identifiable {
  id?: string;
}

export type TableListProps<T extends Identifiable, C> = {
  data: T[] | undefined;
  className?: string;
  headers: string[];
  renderCell: (
    item: T,
    config?: C,
  ) => { [key: string]: React.ReactNode | string };
  onCellClick?: (item: T) => void;
  onActionClick?: (item: T) => void;
  tableActionConfig?: C;
};

const TableList = <T extends Identifiable, C>({
  headers,
  data,
  className,
  renderCell,
  onActionClick,
  onCellClick,
  tableActionConfig,
}: TableListProps<T, C>) => {
  return (
    <div className={styles['table-wrapper']}>
      <div
        className={classNames(className, styles['grid-table'])}
        style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}
      >
        {headers.map((header, index) => (
          <div key={index} className={styles['grid-header']}>
            {header}
          </div>
        ))}
        {data?.map((item) => (
          <div key={item.id} className={styles['grid-line']}>
            <TableLine
              lineData={renderCell(item, tableActionConfig)}
              onCellClick={() => onCellClick?.(item)}
              onActionClick={() => onActionClick?.(item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableList;
