import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  options: string[];
  onChange: (value: string[]) => void;
  label: string;
  placeHolder: string;
  theme?: 'white' | 'black';
  className?: string;
  disabled?: boolean;
  value?: string[];
};

const MultiAutoComplete: FC<Props> = ({
  options,
  onChange,
  label,
  placeHolder,
  theme = 'white',
  className,
  disabled,
  value = [],
}) => {
  return (
    <Autocomplete
      disabled={disabled}
      multiple
      className={classNames(
        styles[theme || 'white'],
        styles['multi-select'],
        className,
        {
          [styles['disabled']]: disabled,
        },
      )}
      options={options}
      getOptionLabel={(option) => option}
      disableCloseOnSelect
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label={label}
          placeholder={placeHolder}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option}
          value={option}
          sx={{ justifyContent: 'space-between' }}
        >
          {option}
          {selected ? <CheckIcon color='info' /> : null}
        </MenuItem>
      )}
      onChange={(_, newValue) => {
        onChange(newValue as string[]);
      }}
    />
  );
};

export default MultiAutoComplete;
