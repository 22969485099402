import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';

import { BaseUseApiProps } from 'src/types/api/api.types';

import { makeApiSendFeedback } from '../functions/makeApiManage';

export const useApiSendFeedback = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiSendFeedback(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};
