import classNames from 'classnames';
import { FC } from 'react';

import Avatar from 'src/components/Avatar';

import { dateTimeWithCommaFormat } from 'src/constants/date';

import { dateToFormat } from 'src/helpers/datetime';

import useTexts from 'src/hooks/useTexts';

import { User } from 'src/types/dao/user.types';

import styles from './styles.module.scss';

export type Props = {
  data: User;
  className?: string;
  readonly?: boolean;
};

const UserBox: FC<Props> = ({ data, className }) => {
  const { t } = useTexts();

  return (
    <div className={classNames(styles['user-box'], className)}>
      <div className={styles['content']}>
        <div className={styles['content-header']}>
          <div className={styles['image-row']}>
            <Avatar
              avatarClassName={styles['user-avatar']}
              defaultAvatarClassName={styles['default-avatar']}
              image={data.picture}
            />
          </div>
          <p className={styles['user-name']}>{data.name}</p>
          <p className={styles['user-email']}>{data.email}</p>
        </div>
        <div className={styles['content-info']}>
          <div className={styles['content-info_wrap']}>
            <div className={styles['info-row']}>
              <p>{t('invitation.minutes')}:</p>
              <p>{data.minutes}</p>
            </div>
            <div className={styles['info-row']}>
              <p>{t('general.subscription')}:</p>
              <p>{data.subscription || t('general.n/a')}</p>
            </div>
            <div className={styles['info-row']}>
              <p>{t('user.access_tier')}:</p>
              <p>{t('user.' + data.access_tier)}</p>
            </div>
            <div className={styles['info-row']}>
              <p>{t('user.profile')}:</p>
              <p>{data.profile || 'Placeholder user profile'}</p>
            </div>
          </div>
          <div className={styles['last-login-container']}>
            <p>{'Last login:'}</p>
            <strong>{`${dateToFormat(
              new Date(data.last_login),
              dateTimeWithCommaFormat,
            )}`}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBox;
