import { useMemo } from 'react';

import useGetAndUpdateUserData from 'src/hooks/useGetAndUpdateUserData';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

export const useValidateUser = () => {
  const currentUser = useAppSelector(getCurrentUser);
  const { isLoading, success } = useGetAndUpdateUserData();

  const isValid = useMemo<boolean>(() => !!currentUser, [currentUser]);
  const isLoaded = useMemo<boolean>(() => success !== null, [success]);

  return {
    isValid: isValid,
    isLoaded: isLoaded,
    isLoading,
    accessTier: currentUser?.access_tier,
  };
};
