import React, { FC } from 'react';

import ViewAgent from 'src/features/pages/private/ViewAgent';

import { useAppSelector } from 'src/store/hooks';
import { selectSelectedAgentInstance } from 'src/store/reducers/agentSlice';

import SidePanel from '../../index';

type SidePanelProps = {
  onPanelClose: () => void;
  isOpen: boolean;
};

const AgentPanel: FC<SidePanelProps> = ({ isOpen, onPanelClose }) => {
  const selectedAgent = useAppSelector(selectSelectedAgentInstance);
  return (
    <>
      {selectedAgent && (
        <SidePanel onPanelClose={onPanelClose} isOpen={isOpen}>
          <ViewAgent id={selectedAgent.id} />
        </SidePanel>
      )}
    </>
  );
};

export default AgentPanel;
