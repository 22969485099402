import { api } from 'common-ui';
import { URLS } from 'common-ui';

import {
  GetAgentInstanceByIdResponse,
  GetAgentInstancesResponse,
  GetGroupAgentsResponse,
} from 'src/types/api/agent.types';
import { AgentStatus } from 'src/types/dao/agent.types';

type GetAgentsProps = {
  page?: number;
  perPage?: number;
  filters?: { status?: AgentStatus; owned_by?: string; ids?: string[] };
};

export const makeApiAdminGetAgents =
  () =>
  ({ page, perPage, filters }: GetAgentsProps = {}) =>
    api.get<GetGroupAgentsResponse>(URLS.agent.admin.getAgents, {
      params: {
        ...(page && {
          page,
        }),
        ...(perPage && {
          per_page: perPage,
        }),
        ...(filters || {}),
      },
    });

type GetAgentCheckpointsProps = {
  page?: number;
  perPage?: number;
  filters?: {
    agent_id?: string;
    owned_by?: string;
    ids?: string[];
    game_instance_id?: string;
  };
};

interface GetAgentInstancesProps {
  page?: number;
  perPage?: number;
  ids?: string[];
  owned_by?: string;
  agent_proto_id?: string;
  status?: string;
  from_time?: number;
  to_time?: number;
}

export const makeApiAdminGetAgentCheckpoints =
  () =>
  ({ page, perPage, filters }: GetAgentCheckpointsProps = {}) =>
    api.get<GetAgentInstancesResponse>(URLS.agent.admin.getAgentCheckpoints, {
      params: {
        ...(page && {
          page,
        }),
        ...(perPage && {
          per_page: perPage,
        }),
        ...(filters || {}),
      },
    });

/**
 * Creates a function to fetch agent instances from the admin API.
 * @returns A function that takes AgentInstanceListParams and returns a Promise with the agent instances response.
 */
export const makeApiAdminGetAgentInstances =
  () =>
  ({ page, perPage, owned_by }: GetAgentInstancesProps = {}) =>
    api.get<GetAgentInstancesResponse>(URLS.agent.admin.getAgentInstances, {
      params: {
        ...(page && {
          page,
        }),
        ...(perPage && {
          per_page: perPage,
        }),
        ...(owned_by && {
          owned_by,
        }),
      },
    });

export const makeApiAdminGetInstanceById =
  () =>
  ({ agent_instance_id }: { agent_instance_id: string }) =>
    api.get<GetAgentInstanceByIdResponse>(URLS.agent.admin.getInstanceById, {
      params: { agent_instance_id },
    });
