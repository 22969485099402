import React, { FC } from 'react';

import DatePickerComponent from 'src/components/DatePickerComponent';
import NumberField from 'src/components/Inputs/NumberField';
import Loader from 'src/components/Loader';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import NothingToShow from 'src/components/NothingToShow';
import Pagination from 'src/components/Pagination';
import TableList from 'src/components/TableList';

import CheckoutButton from 'src/features/components/payment/CheckoutButton';

import useTexts from 'src/hooks/useTexts';

import { InvitationData } from 'src/types/dao/invitation.types';

import { renderInvitationCell, tableHeaders } from './common';
import { useComponentProps } from './hooks/useComponentProps';
import styles from './styles.module.scss';

type TextGetter = (key: string) => string | undefined;

export type InvitationCodesTableActionConfig = {
  deleteCode: (codeId: string) => void;
  copyInvitationCode: (code: string) => void;
  t: TextGetter;
};

const InvitationCodes: FC = () => {
  const {
    user,
    isAdminInvitationsLoading,
    formData,
    formDataSetters,
    handleRemoveInvitation,
    isCreateInvitationLoading,
    handleAddInvitation,
    handleAddInvitationClick,
    setInvitationModalOpen,
    invitationModalOpen,
    canLoadNextPage,
    canLoadPrevPage,
    invitationData,
    loadNextPage,
    loadPrevPage,
    activeInvitationPage,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    copyInvitationCode,
  } = useComponentProps();

  const { t } = useTexts();

  const tableActionConfig = {
    deleteCode: handleRemoveInvitation,
    copyInvitationCode: copyInvitationCode,
    t,
  };

  return (
    <>
      <div className={styles['page']}>
        <div
          className={styles['create-button-wrapper']}
          onClick={handleAddInvitationClick}
        >
          <div className={styles['create-button']}>
            {t('invitation.create_code')}
          </div>
        </div>
        <div className={styles['title-wrapper']}>
          <div className={styles['invitation-title-wrapper']}>
            <h2>{t('invitation.invitation_codes')}:</h2>
          </div>
          <DatePickerComponent
            start={startDate}
            end={endDate}
            setStart={setStartDate}
            setEnd={setEndDate}
          />
        </div>

        <div className={styles['info-group']}>
          {isAdminInvitationsLoading ? (
            <Loader />
          ) : (
            <div className={styles['chat-list-wrapper']}>
              {invitationData.length ? (
                <>
                  <TableList<InvitationData, InvitationCodesTableActionConfig>
                    headers={tableHeaders}
                    data={invitationData}
                    renderCell={(item) =>
                      renderInvitationCell(item, tableActionConfig)
                    }
                    tableActionConfig={tableActionConfig}
                  />
                  <div className={styles['pagination-wrapper']}>
                    <Pagination
                      loadNextPage={loadNextPage}
                      loadPrevPage={loadPrevPage}
                      pageNumber={activeInvitationPage}
                      canLoadNext={canLoadNextPage}
                      canLoadPrev={canLoadPrevPage}
                    />
                  </div>
                </>
              ) : (
                <NothingToShow />
              )}
            </div>
          )}
        </div>
      </div>
      {invitationModalOpen &&
        (!user ? (
          <Loader fullPage />
        ) : (
          <ConfirmModal
            setOpen={setInvitationModalOpen}
            title={t('invitation.create_invitation_code')}
            onConfirm={handleAddInvitation}
            confirmButtonLabel={t('form.save')}
            isConfirmLoading={isCreateInvitationLoading}
            size='sm'
          >
            <div>
              <p className={styles['remaining-play-minutes']}>
                {t('invitation.remaining_play_minutes')}: {user.minutes}
              </p>
              {!user.minutes && (
                <CheckoutButton wrapperClassName={styles['checkout-btn']} />
              )}
              <NumberField
                value={formData.minutes}
                onChange={formDataSetters.minutes}
                wrapperClassName={styles['minutes-input']}
                label={t('invitation.play_minutes')}
                theme='black'
              />
              <NumberField
                value={formData.count}
                onChange={formDataSetters.count}
                wrapperClassName={styles['minutes-input']}
                label={t('invitation.invitation_count')}
                theme='black'
              />
            </div>
          </ConfirmModal>
        ))}
    </>
  );
};

export default InvitationCodes;
