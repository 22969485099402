import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import { useApiDeleteApiKey } from 'src/api/keys/hooks/useApiManage';

import useTexts from 'src/hooks/useTexts';

type Props = {
  reload: () => void;
};

export const useComponentProps = ({ reload }: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [apiKeyId, setApiKeyId] = useState<string>('');

  const { t } = useTexts();

  const { doRequest: deleteApiKey, isLoading: isDeleteApiKeyLoading } =
    useApiDeleteApiKey({
      onSuccess: useCallback(() => {
        setDeleteModalOpen(false);
        reload();
      }, [reload]),
    });

  const handleCopyApiKey = useCallback(
    (key: string) => {
      navigator.clipboard.writeText(key);

      enqueueSnackbar(t('general.copied'), { variant: 'success' });
    },
    [t],
  );

  const handleDeleteApiKey = useCallback((id: string) => {
    setApiKeyId(id);
    setDeleteModalOpen(true);
  }, []);

  return {
    t,
    deleteApiKey,
    isDeleteApiKeyLoading,
    handleCopyApiKey,
    handleDeleteApiKey,
    apiKeyId,
    deleteModalOpen,
    setDeleteModalOpen,
  };
};
