import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';

import { BaseUseApiProps } from 'src/types/api/api.types';
import { ChatMessagesResponse } from 'src/types/api/chat.types';

import { makeApiGet } from '../functions/makeApiGet';

export const useApiGetChatMessage = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<ChatMessagesResponse> = {}) => {
  const doRequest = useMemo(() => makeApiGet(), []);
  return useApi({ doRequest, onSuccess, onResponse, onError });
};
