import { useEffect } from 'react';

import { useAppDispatch } from '../store/hooks';
import { appSlice } from '../store/reducers/appSlice';

const usePageLoader = ({ loading }: { loading: boolean }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loading) {
      dispatch(appSlice.actions.setIsLoading(true));
    }

    return () => {
      dispatch(appSlice.actions.setIsLoading(false));
    };
  }, [dispatch, loading]);
};

export default usePageLoader;
