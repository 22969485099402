import { useThrottleCallback } from '@react-hook/throttle';
import { useCallback, useState } from 'react';
import { AnyObject, ObjectSchema, ValidationError } from 'yup';

import { Errors, prepareValidationErrors } from '../helpers/errors';

const useValidation = <DataType extends AnyObject>({
  schema,
  data,
}: {
  schema: ObjectSchema<Partial<DataType>> | undefined;
  data: DataType;
}) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [errors, setErrors] = useState<Errors<DataType> | null>(null);

  const validate = useCallback((): boolean => {
    if (!schema) {
      throw new Error('Schema is missing');
    }

    let validationResult = false;

    try {
      validationResult = !!schema.validateSync(data, { abortEarly: false });
      setErrors(null);
    } catch (err) {
      if (err instanceof ValidationError) {
        validationResult = false;
        setErrors(prepareValidationErrors<DataType>(err.inner));
      }
    }

    setIsValid(validationResult);
    return validationResult;
  }, [data, schema]);

  const validateThrottle = useThrottleCallback(validate);

  const resetErrors = useCallback(() => setErrors(null), []);

  return {
    isValid,
    validate,
    validateThrottle,
    errors,
    resetErrors,
  };
};

export default useValidation;
