import classNames from 'classnames';
import { FC, MouseEvent, ReactNode } from 'react';

import styles from './styles.module.scss';

export type Props = {
  onClick: (event: MouseEvent) => void;
  className?: string;
  tooltip?: string;
  children: ReactNode;
  theme?: 'default' | 'red';
};

const IconButton: FC<Props> = ({
  onClick,
  className,
  tooltip,
  children,
  theme = 'default',
}) => (
  <button
    onClick={onClick}
    className={classNames(className, styles['icon-button'], styles[theme])}
    title={tooltip}
  >
    {children}
  </button>
);

export default IconButton;
