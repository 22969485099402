import { GetGroupAgentsResponse } from 'src/types/api/agent.types';
import { AgentStatus } from 'src/types/dao/agent.types';

const groupAgentsMock: GetGroupAgentsResponse = {
  data: [
    {
      id: 'uuid',
      name: 'test',
      inner_state: {
        mock_key: 'mock_value',
      },
      avatar_url: '',
      advanced: '',
      memory: {
        long_term_memory: '',
      },
      status: AgentStatus.PUBLIC,
      owner_id: 'uuid',
      admin_snapshot: { name: '' },
      safety_analysis: { name: '' },
      image_url: '',
      thumbnail_url: '',
      animation_url: '',
      minecraft_skin_url: '',
    },
    {
      id: 'uuid',
      name: 'test',
      inner_state: {
        mock_key: 'mock_value',
      },
      avatar_url: '',
      advanced: '',
      memory: {
        long_term_memory: '',
      },
      status: AgentStatus.PUBLIC,
      owner_id: 'uuid',
      admin_snapshot: { name: '' },
      safety_analysis: { name: '' },
      image_url: '',
      thumbnail_url: '',
      animation_url: '',
      minecraft_skin_url: '',
    },
    {
      id: 'uuid',
      name: 'test',
      inner_state: {
        mock_key: 'mock_value',
      },
      avatar_url: '',
      advanced: '',
      memory: {
        long_term_memory: '',
      },
      status: AgentStatus.PUBLIC,
      owner_id: 'uuid',
      admin_snapshot: { name: '' },
      safety_analysis: { name: '' },
      image_url: '',
      thumbnail_url: '',
      animation_url: '',
      minecraft_skin_url: '',
    },
    {
      id: 'uuid',
      name: 'test',
      inner_state: {
        mock_key: 'mock_value',
      },
      avatar_url: '',
      advanced: '',
      memory: {
        long_term_memory: '',
      },
      status: AgentStatus.PUBLIC,
      owner_id: 'uuid',
      admin_snapshot: { name: '' },
      safety_analysis: { name: '' },
      image_url: '',
      thumbnail_url: '',
      animation_url: '',
      minecraft_skin_url: '',
    },
    {
      id: 'uuid',
      name: 'test',
      inner_state: {
        mock_key: 'mock_value',
      },
      avatar_url: '',
      advanced: '',
      memory: {
        long_term_memory: '',
      },
      status: AgentStatus.PUBLIC,
      owner_id: 'uuid',
      admin_snapshot: { name: '' },
      safety_analysis: { name: '' },
      image_url: '',
      thumbnail_url: '',
      animation_url: '',
      minecraft_skin_url: '',
    },
    {
      id: 'uuid',
      name: 'test',
      inner_state: {
        mock_key: 'mock_value',
      },
      avatar_url: '',
      advanced: '',
      memory: {
        long_term_memory: '',
      },
      status: AgentStatus.PUBLIC,
      owner_id: 'uuid',
      admin_snapshot: { name: '' },
      safety_analysis: { name: '' },
      image_url: '',
      thumbnail_url: '',
      animation_url: '',
      minecraft_skin_url: '',
    },
    {
      id: 'uuid',
      name: 'test',
      inner_state: {
        mock_key: 'mock_value',
      },
      avatar_url: '',
      advanced: '',
      memory: {
        long_term_memory: '',
      },
      status: AgentStatus.PUBLIC,
      owner_id: 'uuid',
      admin_snapshot: { name: '' },
      safety_analysis: { name: '' },
      image_url: '',
      thumbnail_url: '',
      animation_url: '',
      minecraft_skin_url: '',
    },
  ],
  pagination: {
    current_page: 1,
    having_next_page: false,
  },
};

export default groupAgentsMock;
