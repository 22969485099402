import { useCallback, useEffect, useMemo, useState } from 'react';

import { useApiAdminGet } from 'src/api/chat/hooks/useApiAdminGet';
import { useApiGetChatMessage } from 'src/api/chat/hooks/useApiGet';

import {
  ChatData,
  ChatMessagesData,
  ChatMessagesResponse,
  ChatResponse,
} from 'src/types/api/chat.types';

type Props = {
  chatId: string | null;
};

export const useComponentProps = ({ chatId }: Props) => {
  const [chatListData, setChatListData] = useState<ChatData[]>([]);
  const [activeChatPage, setActiveChatPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [chatMessagesData, setChatMessagesData] = useState<ChatMessagesData[]>(
    [],
  );
  const [activeMessagesPage, setActiveMessagesPage] = useState<number>(1);
  const [hasNextMessagesPage, setHasMessagesPage] = useState<boolean>(false);

  const { doRequest: getChatList, isLoading: isChatListLoading } =
    useApiAdminGet({
      onResponse(response: ChatResponse) {
        if (response?.data) {
          setChatListData(response.data);
          setActiveChatPage(response.pagination.current_page);
          setHasNextPage(response.pagination.having_next_page);
        }
      },
    });

  const { doRequest: getChatMessages, isLoading: isChatMessagesLoading } =
    useApiGetChatMessage({
      onResponse: useCallback((response: ChatMessagesResponse) => {
        if (response?.data) {
          setChatMessagesData(response.data);
          setActiveMessagesPage(response.pagination.current_page);
          setHasMessagesPage(response.pagination.having_next_page);
        }
      }, []),
    });

  useEffect(() => {
    if (chatId) {
      getChatMessages({ chatIds: [chatId] }); // Only call if chatId is valid
    }
  }, [chatId, getChatMessages]);

  useEffect(() => {
    getChatList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canLoadNextPage = useMemo(() => hasNextPage, [hasNextPage]);
  const canLoadPrevPage = useMemo(() => activeChatPage > 1, [activeChatPage]);
  const canLoadNextMessagesPage = useMemo(
    () => hasNextMessagesPage,
    [hasNextMessagesPage],
  );
  const canLoadPrevMessagesPage = useMemo(
    () => activeMessagesPage > 1,
    [activeMessagesPage],
  );

  const loadNextPage = () => {
    if (hasNextPage) {
      setChatListData([]);
      getChatList({ page: activeChatPage + 1 });
      setActiveChatPage((prevPage) => prevPage + 1);
    }
  };

  const loadPrevPage = () => {
    if (activeChatPage > 1) {
      setChatListData([]);
      getChatList({ page: activeChatPage - 1 });
      setActiveChatPage((prevPage) => prevPage - 1);
    }
  };

  const loadNextMessagesPage = () => {
    if (hasNextMessagesPage && chatId) {
      setChatMessagesData([]);
      getChatMessages({ page: activeMessagesPage + 1, chatIds: [chatId] });
      setActiveMessagesPage((prevPage) => prevPage + 1);
    }
  };

  const loadPrevMessagesPage = () => {
    if (activeMessagesPage > 1 && chatId) {
      setChatMessagesData([]);
      getChatMessages({ page: activeMessagesPage - 1, chatIds: [chatId] });
      setActiveMessagesPage((prevPage) => prevPage - 1);
    }
  };

  return {
    isChatListLoading,
    chatListData,
    canLoadNextPage,
    canLoadPrevPage,
    loadNextPage,
    loadPrevPage,
    activeChatPage,
    isChatMessagesLoading,
    chatMessagesData,
    canLoadNextMessagesPage,
    canLoadPrevMessagesPage,
    loadNextMessagesPage,
    loadPrevMessagesPage,
    activeMessagesPage,
  };
};
