import 'react-quill/dist/quill.snow.css';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import classNames from 'classnames';
import { FC, ReactNode, SyntheticEvent, useCallback, useState } from 'react';

import styles from './styles.module.scss';

export type Props = {
  label?: string;
  description?: string;
  className?: string;
  wrapperClassName?: string;
  expanded?: boolean;
  children: (setAccordionExpanded: (value: boolean) => void) => ReactNode;
  buttonReverse?: boolean;
};

const Accordion: FC<Props> = ({
  label,
  description,
  expanded,
  className,
  wrapperClassName,
  buttonReverse = true,
  children,
}) => {
  const [accordionExpanded, setAccordionExpanded] =
    useState<boolean>(!!expanded);

  const handleChange = useCallback(
    (event: SyntheticEvent, expanded: boolean) => {
      setAccordionExpanded(expanded);
    },
    [],
  );

  return (
    <div className={classNames(styles['accordion-wrapper'], wrapperClassName)}>
      <MuiAccordion
        disableGutters
        elevation={0}
        expanded={accordionExpanded}
        onChange={handleChange}
        className={classNames(className, styles['accordion'], {
          [styles['reverse']]: buttonReverse,
        })}
      >
        <MuiAccordionSummary
          className={classNames('summary', styles['summary'], {
            [styles['expanded']]: accordionExpanded,
          })}
          id={`${label}-header`}
          aria-controls={`${label}-content`}
          expandIcon={
            <ArrowForwardIosSharpIcon className={styles['arrow-icon']} />
          }
        >
          <div className={styles['summary-content']}>
            {label && <h2 className={'label'}>{label}</h2>}
            {description && (
              <p className={styles['description']}>{description}</p>
            )}
          </div>
        </MuiAccordionSummary>
        <MuiAccordionDetails className={styles['details']}>
          {children(setAccordionExpanded)}
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
};

export default Accordion;
