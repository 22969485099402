import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Accordion from '../Accordion';
import { PageKey } from '../Navbar';
import styles from './styles.module.scss';

export type Props = {
  currentPageKey: PageKey;
  list: Array<{ key: PageKey; label: string; path: string }>;
};

const PageSelector: FC<Props> = ({ currentPageKey, list }) => {
  const history = useHistory();

  return (
    <Accordion
      className={styles['accordion']}
      wrapperClassName={styles['accordion-wrapper']}
      buttonReverse={false}
      label={list.find((page) => page.key === currentPageKey)?.label || ''}
    >
      {(setAccordionExpanded) => (
        <div className={styles['pages-wrapper']}>
          {list
            .filter((page) => page.key !== currentPageKey)
            .map((page, index) => (
              <button
                className={styles['page']}
                key={index}
                onClick={() => {
                  history.push(page.path);
                  setAccordionExpanded(false);
                }}
              >
                {page.label}
              </button>
            ))}
        </div>
      )}
    </Accordion>
  );
};

export default PageSelector;
