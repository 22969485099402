export const getUrlSearchParam = (
  search: string,
  key: string,
): string | undefined =>
  search
    .replace('?', '')
    .replaceAll('%2F', '/')
    .split('&')
    .find((item) => item.includes(key + '='))
    ?.replace(key + '=', '');
