import React, { FC } from 'react';

import Pagination from 'src/components/Pagination';

import useTexts from 'src/hooks/useTexts';

import { ChatMessagesData } from 'src/types/api/chat.types';

import SidePanel from '../../index';
import styles from './styles.module.scss';

type SidePanelProps = {
  onPanelClose: () => void;
  isOpen: boolean;
  panelConfig: {
    isChatMessagesLoading: boolean;
    chatMessagesData: ChatMessagesData[];
    canLoadNextMessagesPage: boolean;
    canLoadPrevMessagesPage: boolean;
    loadNextMessagesPage: () => void;
    loadPrevMessagesPage: () => void;
    activeMessagesPage: number;
  };
};

const ChatMessagesPanel: FC<SidePanelProps> = ({
  isOpen,
  onPanelClose,
  panelConfig,
}) => {
  const { t } = useTexts();
  const { chatMessagesData } = panelConfig;

  return (
    <>
      {isOpen && (
        <SidePanel onPanelClose={onPanelClose} isOpen={isOpen}>
          <div className={styles.section}>
            <h3>{t('chats.messages')}:</h3>
            <div className={styles.messagesWrapper}>
              {chatMessagesData.map((message) => (
                <div
                  key={message.id}
                  style={{
                    alignSelf: message.user_id ? 'flex-end' : 'flex-start',
                    padding: '8px',
                    maxWidth: '70%',
                  }}
                >
                  <div className={styles.message}>{message.content}</div>
                  <div
                    className={styles.date}
                    style={{
                      justifyContent: message.user_id
                        ? 'flex-end'
                        : 'flex-start',
                    }}
                  >
                    {message.created_at
                      ? new Date(message.created_at).toLocaleString()
                      : 'Unknown time'}
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              loadNextPage={panelConfig.loadNextMessagesPage}
              loadPrevPage={panelConfig.loadPrevMessagesPage}
              pageNumber={panelConfig.activeMessagesPage}
              canLoadNext={panelConfig.canLoadNextMessagesPage}
              canLoadPrev={panelConfig.canLoadPrevMessagesPage}
            />
          </div>
        </SidePanel>
      )}
    </>
  );
};

export default ChatMessagesPanel;
