import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

import useTexts from 'src/hooks/useTexts';

import SearchField from '../Inputs/SearchField';
import styles from './styles.module.scss';

export type Props = {
  value: string;
  onChange: (value: string) => void;
  searchOpen: boolean;
  setSearchOpen: (searchOpen: boolean) => void;
  iconClassName?: string;
};

const Search: FC<Props> = ({
  value,
  onChange,
  searchOpen,
  setSearchOpen,
  iconClassName,
}) => {
  const { t } = useTexts();

  const toggleSearchBtnClick = useCallback(() => {
    setSearchOpen(!searchOpen);
    onChange('');
  }, [onChange, searchOpen, setSearchOpen]);

  return (
    <div
      className={classNames(styles['wrapper'], {
        [styles['open']]: searchOpen,
      })}
    >
      <div className={styles['content']}>
        <div className={styles['search-wrapper']}>
          <div
            className={classNames(styles['input-wrapper'], {
              [styles['hidden']]: !searchOpen,
            })}
          >
            <SearchField
              id='search-field'
              value={value}
              onChange={onChange}
              className={styles['input']}
              label={t('general.search')}
              closeIcon={searchOpen}
              onIconClick={toggleSearchBtnClick}
            />
          </div>
        </div>
      </div>
      <button
        id='search-btn'
        className={classNames(styles['search-btn'], iconClassName, {
          [styles['hidden']]: searchOpen,
        })}
        onClick={toggleSearchBtnClick}
      >
        <SearchIcon className={styles['icon']} />
      </button>
    </div>
  );
};

export default Search;
