import { object, string } from 'yup';

import { toSnakeCase } from 'src/helpers/text';

import {
  AgentFormData,
  NewCharacteristicForm,
} from 'src/types/dao/agent.types';

export const prepareNewCharacteristicSchema = (
  innerState?: AgentFormData['inner_state'],
) => {
  return object<Pick<NewCharacteristicForm, 'name'>>({
    name: string().test({
      name: 'is-valid',
      test(value, ctx) {
        if (!value) {
          return ctx.createError({ message: 'Field is required' });
        }
        if (!isNaN(Number(value[0]))) {
          return ctx.createError({
            message: 'Characteristics must not start with a number',
          });
        }
        if (innerState) {
          const key = toSnakeCase(value.trim());
          const isExist = Object.keys(innerState).find((item) => item === key);
          if (isExist) {
            return ctx.createError({
              message: 'Characteristic with the same name already exists',
            });
          }
        }
        return true;
      },
    }),
  });
};
