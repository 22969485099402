import dayjs, { Dayjs } from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useApiAdminGetInvitations } from 'src/api/invitation/hooks/useApiAdminGet';
import {
  useApiCreateInvitation,
  useApiDeleteInvitation,
} from 'src/api/invitation/hooks/useApiAdminManage';

import { dateToUnixTimestamp } from 'src/helpers/datetime';

import useFormData from 'src/hooks/useFormData';
import useGetAndUpdateUserData from 'src/hooks/useGetAndUpdateUserData';
import usePageLoader from 'src/hooks/usePageLoader';
import useTexts from 'src/hooks/useTexts';

import { InvitationsListResponse } from 'src/types/api/invitation.types';
import {
  InvitationData,
  InvitationFormData,
} from 'src/types/dao/invitation.types';

const initialFormData: InvitationFormData = {
  minutes: 0,
  count: 1,
};

export const useComponentProps = () => {
  const { responseData: user } = useGetAndUpdateUserData();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const now = new Date();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(sevenDaysAgo));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(now));
  const [invitationData, setInvitationData] = useState<InvitationData[]>([]);
  const [activeInvitationPage, setActiveInvitationPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [invitationModalOpen, setInvitationModalOpen] =
    useState<boolean>(false);
  const { t } = useTexts();
  const { enqueueSnackbar } = useSnackbar();

  const { formData, formDataSetters, resetFormData } =
    useFormData<InvitationFormData>({ initialData: initialFormData });

  // INVITATIONS API
  const {
    doRequest: adminGetInvitations,
    isLoading: isAdminInvitationsLoading,
    responseData: adminInvitations,
  } = useApiAdminGetInvitations({
    onResponse: useCallback((response: InvitationsListResponse) => {
      if (response?.data) {
        setInvitationData(response.data);
        setActiveInvitationPage(response.pagination.current_page);
        setHasNextPage(response.pagination.having_next_page);
      }
    }, []),
  });

  const {
    doRequest: adminCreateInvitation,
    isLoading: isCreateInvitationLoading,
  } = useApiCreateInvitation({
    onSuccess: useCallback(() => {
      adminGetInvitations({});
      setInvitationModalOpen(false);
      resetFormData();
    }, [resetFormData, adminGetInvitations]),
  });
  const {
    doRequest: adminDeleteInvitation,
    isLoading: isInvitationDeleteLoading,
  } = useApiDeleteInvitation({
    onSuccess: useCallback(() => {
      adminGetInvitations({});
    }, [adminGetInvitations]),
  });

  const handleAddInvitationClick = useCallback(() => {
    setInvitationModalOpen(true);
  }, []);

  const handleAddInvitation = useCallback(() => {
    mixpanel.track('Create invitation');
    adminCreateInvitation({ formData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCreateInvitation, formData]);

  usePageLoader({ loading: isInvitationDeleteLoading });

  const handleRemoveInvitation = useCallback(
    (id: string) => {
      adminDeleteInvitation({ id });
    },
    [adminDeleteInvitation],
  );

  const canLoadNextPage = useMemo(() => hasNextPage, [hasNextPage]);
  const canLoadPrevPage = useMemo(
    () => activeInvitationPage > 1,
    [activeInvitationPage],
  );

  const copyInvitationCode = (code: string) => {
    navigator.clipboard.writeText(code);
    enqueueSnackbar(t('general.copied'), { variant: 'success' });
  };

  const loadNextPage = () => {
    if (hasNextPage) {
      setInvitationData([]);
      adminGetInvitations({
        page: activeInvitationPage + 1,
        filters: {
          from_time: dateToUnixTimestamp(startDate.toDate()),
          to_time: dateToUnixTimestamp(endDate.toDate()),
        },
      });
      setActiveInvitationPage((prevPage) => prevPage + 1);
    }
  };

  const loadPrevPage = () => {
    if (activeInvitationPage > 1) {
      setInvitationData([]);
      adminGetInvitations({
        page: activeInvitationPage - 1,
        filters: {
          from_time: dateToUnixTimestamp(startDate.toDate()),
          to_time: dateToUnixTimestamp(endDate.toDate()),
        },
      });
      setActiveInvitationPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    adminGetInvitations({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    adminGetInvitations({
      page: activeInvitationPage,
      filters: {
        from_time: dateToUnixTimestamp(startDate.toDate()),
        to_time: dateToUnixTimestamp(endDate.toDate()),
      },
    });
  }, [startDate, endDate, adminGetInvitations, activeInvitationPage]);

  return {
    user,
    isAdminInvitationsLoading,
    formData,
    formDataSetters,
    adminInvitations,
    handleRemoveInvitation,
    isCreateInvitationLoading,
    handleAddInvitation,
    handleAddInvitationClick,
    setInvitationModalOpen,
    invitationModalOpen,
    canLoadNextPage,
    canLoadPrevPage,
    invitationData,
    loadNextPage,
    loadPrevPage,
    activeInvitationPage,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    copyInvitationCode,
  };
};
