import { api } from 'common-ui';
import { URLS } from 'common-ui';

import { ChatResponse } from 'src/types/api/chat.types';

type GetChatProps = {
  page?: number;
  perPage?: number;
  filters?: {
    ids?: string[];
  };
};

export const makeApiChat =
  () =>
  ({ page, perPage, filters }: GetChatProps = {}) =>
    api.get<ChatResponse>(URLS.chat.getChatList, {
      params: {
        ...(page && {
          page,
        }),
        ...(perPage && {
          per_page: perPage,
        }),
        ...(filters || {}),
      },
    });
