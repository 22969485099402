import { Agent, AgentFormData, AgentStatus } from 'src/types/dao/agent.types';

const formatAdminSnapshot = (data?: Agent | AgentFormData) =>
  typeof data?.admin_snapshot === 'object' &&
  JSON.stringify(data?.admin_snapshot) !== 'null'
    ? JSON.stringify(data?.admin_snapshot, null, 4)
    : data?.admin_snapshot;

const formatToObjectInnerState = (data?: Agent | AgentFormData) =>
  typeof data?.inner_state === 'string' &&
  JSON.parse(data?.inner_state) !== 'null'
    ? JSON.parse(data?.inner_state)
    : data?.inner_state;

const formatMemory = (data: string | string[] | undefined): string => {
  if (!data) return '';
  if (typeof data === 'string') return data;
  return data.join('');
};

const formatSafetyAnalysis = (
  data?: Agent | AgentFormData,
): string | undefined => {
  if (!data || data.safety_analysis === undefined) {
    return undefined;
  }
  if (
    typeof data.safety_analysis === 'object' &&
    data.safety_analysis !== null
  ) {
    return JSON.stringify(data.safety_analysis, null, 4);
  }
  return data.safety_analysis as string;
};

export const prepareInitialAgentFormData = (data?: Agent): AgentFormData => ({
  name: data?.name || '',
  inner_state: formatToObjectInnerState(data) || {},
  avatar_url: data?.avatar_url || '',
  advanced: data?.advanced || '',
  memory: {
    long_term_memory: formatMemory(data?.memory?.long_term_memory) || '',
  },
  status: data?.status || AgentStatus.PUBLIC,
  admin_snapshot: formatAdminSnapshot(data),
  safety_analysis: formatSafetyAnalysis(data) || '',
  image_url: data?.image_url || '',
  thumbnail_url: data?.thumbnail_url || '',
  animation_url: data?.animation_url || '',
  minecraft_skin_url: data?.minecraft_skin_url || '',
});

export const prepareAgentFormData = (data?: AgentFormData): AgentFormData => ({
  name: data?.name || '',
  inner_state: formatToObjectInnerState(data) || {},
  avatar_url: data?.avatar_url || '',
  advanced: data?.advanced || '',
  memory: {
    long_term_memory: formatMemory(data?.memory?.long_term_memory) || '',
  },
  status: data?.status || AgentStatus.PUBLIC,
  admin_snapshot: formatAdminSnapshot(data),
  safety_analysis: data?.safety_analysis || '',
  image_url: data?.image_url || '',
  thumbnail_url: data?.thumbnail_url || '',
  animation_url: data?.animation_url || '',
  minecraft_skin_url: data?.minecraft_skin_url || '',
});
