import { User } from 'common-ui';
import { useSnackbar } from 'notistack';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { object, string } from 'yup';

import { useApiSubmitInvitation } from 'src/api/invitation/hooks/useApiManage';

import TextField from 'src/components/Inputs/TextField';
import ConfirmModal from 'src/components/Modals/ConfirmModal';

import { SHOW_PAYMENT_BUTTON } from 'src/constants/app';

import useFormData from 'src/hooks/useFormData';
import useGetAndUpdateUserData from 'src/hooks/useGetAndUpdateUserData';
import useTexts from 'src/hooks/useTexts';

import { AcceptInvitationFormData } from 'src/types/api/invitation.types';

import CheckoutButton from '../../payment/CheckoutButton';
import styles from './styles.module.scss';

export type Props = {
  currentUser?: User;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const validationSchema = object({
  invitationCode: string().required(),
});

const initialData: AcceptInvitationFormData = {
  invitationCode: '',
};

const InvitationModal: FC<Props> = ({ currentUser, setOpen }) => {
  const { formData, formDataSetters, resetFormData, errors, validate } =
    useFormData<AcceptInvitationFormData>({
      initialData,
      validationSchema,
    });

  const { t } = useTexts();

  const { enqueueSnackbar } = useSnackbar();

  const { getUser } = useGetAndUpdateUserData();
  const { doRequest, isLoading } = useApiSubmitInvitation({
    onSuccess: useCallback(() => {
      resetFormData();
      getUser();
      setOpen(false);
      enqueueSnackbar(t('invitation.submitted_sccessfully'), {
        variant: 'success',
      });
    }, [resetFormData, getUser, setOpen, enqueueSnackbar, t]),
  });

  const onSubmitInvitation = useCallback(() => {
    if (validate()) {
      doRequest({ code: formData.invitationCode });
    }
  }, [doRequest, formData.invitationCode, validate]);

  return (
    <ConfirmModal
      setOpen={setOpen}
      onConfirm={onSubmitInvitation}
      isLoading={isLoading}
      title={t('invitation.submit_invitation')}
      description={`${t('landing.remaining_play_time')}: ${
        currentUser?.minutes || 0
      }`}
      size='sm'
      confirmButtonLabel={t('invitation.submit_invitation')}
      cancelButtonLabel={t('form.skip')}
    >
      <div className={styles['invitation-wrapper']}>
        {(currentUser?.minutes || 0) < 60 && SHOW_PAYMENT_BUTTON && (
          <CheckoutButton />
        )}
        <TextField
          value={formData.invitationCode}
          label={t('invitation.invitation_code')}
          onChange={(value) => {
            formDataSetters.invitationCode(value.trim());
          }}
          {...(errors?.invitationCode && {
            error: true,
            helperText: errors.invitationCode,
          })}
          theme='black'
        />
      </div>
    </ConfirmModal>
  );
};

export default InvitationModal;
