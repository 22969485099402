import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';
import { useListApi } from 'src/services/api/hooks/useListApi';

import {
  GetAgentInstanceByIdResponse,
  GetAgentInstancesResponse,
  GetGroupAgentsResponse,
} from 'src/types/api/agent.types';
import { BaseUseApiProps, BaseUseListApiProps } from 'src/types/api/api.types';

import {
  makeApiAdminGetAgentCheckpoints,
  makeApiAdminGetAgentInstances,
  makeApiAdminGetAgents,
  makeApiAdminGetInstanceById,
} from '../functions/makeApiAdminGet';

export const useApiAdminGetAgents = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseListApiProps<GetGroupAgentsResponse> = {}) => {
  const doRequest = useMemo(() => makeApiAdminGetAgents(), []);

  return useListApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiAdminGetAgentCheckpoints = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseListApiProps<GetAgentInstancesResponse> = {}) => {
  const doRequest = useMemo(() => makeApiAdminGetAgentCheckpoints(), []);

  return useListApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiAdminGetAgentInstances = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseListApiProps<GetAgentInstancesResponse> = {}) => {
  const doRequest = useMemo(() => makeApiAdminGetAgentInstances(), []);

  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};

export const useApiAdminGetInstanceById = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<GetAgentInstanceByIdResponse> = {}) => {
  const doRequest = useMemo(() => makeApiAdminGetInstanceById(), []);

  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};
