import Modal from '@mui/material/Modal';
import classNames from 'classnames';
import { Dispatch, FC, ReactNode, SetStateAction, useCallback } from 'react';

import Button from 'src/components/Buttons/Button';
import Loader from 'src/components/Loader';

import useTexts from 'src/hooks/useTexts';

import commonStyles from '../base-modal.module.scss';
import styles from './styles.module.scss';

export type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  description?: ReactNode;
  onConfirm: () => void;
  isLoading?: boolean;
  isConfirmLoading?: boolean;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg';
};

const ConfirmModal: FC<Props> = ({
  setOpen,
  title,
  description,
  onConfirm,
  isLoading,
  isConfirmLoading,
  confirmButtonLabel,
  cancelButtonLabel,
  size,
  children,
}) => {
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { t } = useTexts();

  return (
    <Modal open id='confirm-modal' onClose={handleClose}>
      <div
        className={classNames(
          commonStyles['modal-wrapper'],
          commonStyles[size || 'md'],
        )}
      >
        <div className={commonStyles['modal']}>
          <div className={commonStyles['header']}>
            <p className={commonStyles['title']}>{title}</p>
          </div>
          <div className={styles['modal-content']}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {description && (
                  <div className={commonStyles['description-wrapper']}>
                    <p className={commonStyles['description']}>{description}</p>
                  </div>
                )}
                {children && (
                  <div className={styles['content']}>{children}</div>
                )}
                <div className={styles['buttons-section']}>
                  <Button
                    id='confirm-btn'
                    color='success'
                    variant='contained'
                    onClick={onConfirm}
                    className={classNames(styles['button'], {
                      [styles['loading']]: isConfirmLoading,
                    })}
                  >
                    <span>{confirmButtonLabel || t('form.confirm')}</span>
                    {isConfirmLoading && (
                      <Loader absolute className={styles['btn-loader']} />
                    )}
                  </Button>
                  <Button
                    id='close-btn'
                    variant='contained'
                    onClick={handleClose}
                    className={styles['button']}
                  >
                    {cancelButtonLabel || t('general.close')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
