import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { BasePaginationResponse } from 'src/types/api/api.types';

import { ApiHook, ApiHookProps, useApi } from './useApi';

export type ListApiHook<
  RequestParams extends unknown[],
  ResponseData extends BasePaginationResponse,
> = Omit<ApiHook<RequestParams, ResponseData>, 'doRequest'> & {
  doRequest: (filters?: object, page?: number) => void;
  nextPage: number | null;
  prevPage: number | null;
  perPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
};

const DEFAULT_PER_PAGE = 500;

export const useListApi = <
  Params extends unknown[],
  ResponseData extends BasePaginationResponse,
>({
  doRequest,
  onResponse,
  onSuccess,
  onError,
  mockData,
  initialPerPage = DEFAULT_PER_PAGE,
}: ApiHookProps<Params, ResponseData> & {
  initialPerPage?: number;
}): ListApiHook<Params, ResponseData> => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [havingNextPage, setHavingNextPage] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(initialPerPage);

  const onRequestStart = useCallback(() => {
    setIsLoading(true);
  }, []);

  const listOnResponse = useCallback(
    (responseData: ResponseData) => {
      setCurrentPage(responseData.pagination.current_page);
      setHavingNextPage(responseData.pagination.having_next_page);
      setIsLoading(false);
      onResponse && onResponse(responseData);
    },
    [onResponse],
  );

  const {
    doRequest: doRequestData,
    success,
    error,
    responseData,
  } = useApi({
    doRequest,
    onResponse: listOnResponse,
    onRequestStart,
    onSuccess,
    onError,
    mockData,
  });

  const nextPage = useMemo<number | null>(() => {
    if (havingNextPage) {
      return currentPage + 1;
    }

    return null;
  }, [currentPage, havingNextPage]);

  const prevPage = useMemo<number | null>(() => {
    if (currentPage > 1) {
      return currentPage - 1;
    }

    return null;
  }, [currentPage]);

  const doRequestPageData = useCallback(
    (filters = {}, page = currentPage) => {
      doRequestData(...([{ filters, page, perPage }] as Params));
    },
    [doRequestData, perPage, currentPage],
  );

  return {
    success,
    error,
    responseData,
    doRequest: doRequestPageData,
    isLoading,
    nextPage,
    prevPage,
    perPage,
    setPerPage,
  };
};
