import { api } from 'common-ui';
import { URLS } from 'common-ui';

export const makeApiStopGameInstance =
  () =>
  ({ gameInstanceId }: { gameInstanceId: string }) =>
    api.get<void>(URLS.game.admin.stopGameInstance, {
      params: {
        game_instance_id: gameInstanceId,
      },
    });
