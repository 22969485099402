import { useMemo } from 'react';

import { makeApiAdminGetUsers } from 'src/api/user/functions/makeApiAdminGet';

import { useListApi } from 'src/services/api/hooks/useListApi';

import { BaseUseApiProps } from 'src/types/api/api.types';
import { AdminGetUsersResponse } from 'src/types/api/user.types';

export const useApiAdminGetUsers = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<AdminGetUsersResponse> = {}) => {
  const doRequest = useMemo(() => makeApiAdminGetUsers(), []);
  return useListApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};
