import { api, URLS } from 'common-ui';

import {
  GetAgentResponse,
  GetGroupAgentsResponse,
} from 'src/types/api/agent.types';

export const makeApiGetAgent =
  () =>
  ({ agentId }: { agentId: string }) =>
    api.get<GetAgentResponse>(URLS.agent.admin.getAgent, {
      params: {
        agent_id: agentId,
      },
    });

type GetGruopAgentsProps = {
  page?: number;
  perPage?: number;
  filters?: { group_id?: string };
};

export const makeApiGetAgentsByGroup =
  () =>
  ({ page, perPage, filters }: GetGruopAgentsProps = {}) =>
    api.get<GetGroupAgentsResponse>(URLS.agent.getAgentsByGroup, {
      params: {
        ...(page && {
          page,
        }),
        ...(perPage && {
          per_page: perPage,
        }),
        ...(filters || {}),
      },
    });

export const makeApiSearchAgent =
  () =>
  ({ search }: { search: string }) =>
    api.get<GetGroupAgentsResponse>(URLS.agent.search, { params: { search } });
