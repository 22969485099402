import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import { ChangeEventHandler, FC, useCallback } from 'react';

import styles from './styles.module.scss';

type ComponentProps = {
  value?: string | number;
  onChange?: (value: string) => void;
  selectOptions?: Array<{ value: string; label: string }>;
  changed?: boolean;
  theme?: 'white' | 'black';
};

export type Props = Omit<TextFieldProps, 'onChange' | 'value'> & ComponentProps;

const TextField: FC<Props> = ({
  onChange,
  className,
  select,
  selectOptions,
  value,
  error,
  changed,
  disabled,
  theme = 'white',
  ...props
}) => {
  const handleChange = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >(
    (event) => {
      const value = event.target.value;
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    <MuiTextField
      {...props}
      className={classNames(
        className,
        styles[theme || 'white'],
        styles['text-field'],
        {
          [styles['no-value']]: typeof value === 'string' && !value.length,
          [styles['error']]: error,
          [styles['changed']]: changed,
          [styles['disabled']]: disabled,
        },
      )}
      onChange={handleChange}
      select={select}
      value={value}
      error={error}
      disabled={disabled}
    >
      {select &&
        selectOptions?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </MuiTextField>
  );
};

export default TextField;
