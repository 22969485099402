import { ValidationError } from 'yup';

import { capitalizeFirstLetter, toTextCase } from './text';

export type Errors<DataType> = {
  [key in keyof DataType]: string | undefined;
};

export const prepareValidationErrors = <DataType>(
  errors: ValidationError[],
): Errors<DataType> =>
  Object.fromEntries(
    errors.map((error) => [
      error.path,
      capitalizeFirstLetter(toTextCase(error.message)),
    ]),
  );
