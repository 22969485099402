import { getRandomInt } from './numbers';

export const splitArrayToSubArrays = <Data>(
  arr: Array<Data>,
  size: number,
): Array<Array<Data>> => {
  const tempArr = [...arr];
  const subarray = [];
  for (let i = 0; i < Math.ceil(tempArr.length / size); i++) {
    subarray[i] = tempArr.slice(i * size, i * size + size);
  }

  return subarray;
};

export const getRandomFromArray = <Arr extends Array<T>, T extends string>(
  array: Arr,
): T => {
  return array[getRandomInt(array.length)];
};
