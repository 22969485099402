import {
  TokenResponse,
  useGoogleLogin as useGoogle,
} from '@react-oauth/google';
import { UserLoginResponse } from 'common-ui';
import { UserTier } from 'common-ui';
import { useCallback } from 'react';

import { useApiGoogleLogin } from 'src/api/user/hooks/useApiAuth';

import { useAppDispatch } from 'src/store/hooks';
import { userSlice } from 'src/store/reducers/userSlice';

import { BaseUseApiProps } from 'src/types/api/api.types';

type UseApiGoogleLoginProps = {
  onSuccessLogin?: BaseUseApiProps<UserLoginResponse>['onSuccess'];
  onErrorLogin?: BaseUseApiProps<UserLoginResponse>['onError'];
};

type UseApiGoogleLoginResult = {
  googleLogin: () => void;
};

const useGoogleLogin = ({
  onSuccessLogin,
  onErrorLogin,
}: UseApiGoogleLoginProps = {}): UseApiGoogleLoginResult => {
  const dispatch = useAppDispatch();

  const { doRequest } = useApiGoogleLogin({
    onSuccess: onSuccessLogin,
    onError: onErrorLogin,
    onResponse: useCallback(
      (response: UserLoginResponse) => {
        if (response.access_tier !== UserTier.ADMIN) {
          return;
        }
        dispatch(
          userSlice.actions.setUserProfile({
            id: response.id,
            name: response.name,
            email: response.email,
            picture: response.picture,
            access_tier: response.access_tier,
            subscription: response.subscription,
            minutes: response.minutes,
            last_login: response.last_login ?? new Date(),
            created_at: response.created_at ?? new Date(),
            social_media: response.social_media,
            profile: response.profile,
          }),
        );
      },
      [dispatch],
    ),
  });

  const googleLogin = useGoogle({
    flow: 'implicit',
    onSuccess: useCallback(
      (
        tokenResponse: Omit<
          TokenResponse,
          'error' | 'error_description' | 'error_uri'
        >,
      ) => {
        doRequest({ token: tokenResponse.access_token });
      },
      [doRequest],
    ),
  });

  return { googleLogin };
};

export default useGoogleLogin;
