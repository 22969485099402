import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import classNames from 'classnames';
import { FC } from 'react';

import IconButton from 'src/components/Buttons/IconButton';
import ConfirmModal from 'src/components/Modals/ConfirmModal';

import { Agent } from 'src/types/dao/agent.types';

import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

const defaultImage = require('src/assets/images/DefaultAgentAvatar.png');

export type Props = {
  agent: Agent;
  isSelected?: boolean;
  onSelect?: (agent: Agent) => void;
  readonly?: boolean;
  reload?: () => void;
  users?: any[];
  onAgentClick?: (agent: Agent) => void;
};

const AgentBox: FC<Props> = ({
  agent,
  isSelected,
  onSelect,
  onAgentClick,
  readonly,
  reload,
  users,
}) => {
  const {
    t,
    mouseOver,
    handleClick,
    handleEdit,
    handleCopy,
    onMouseEnter,
    onMouseLeave,
    onViewClick,
    canEdit,
    canCopy,
    canArchive,
    archiveModalOpen,
    setArchiveModalOpen,
    archiveAgent,
    isArchiveAgentLoading,
    copyModalOpen,
    setCopyModalOpen,
    copyAgent,
    isCopyAgentLoading,
  } = useComponentProps({
    agent,
    onSelect,
    reload,
    readonly,
  });

  const agentCreatedOrUpdatedTime = agent.last_updated_time ?? agent.created_at;
  const agentOwner = users?.find((user) => user.id === agent.owner_id);

  return (
    <div
      className={classNames(styles['agent'])}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classNames(styles['agent-image'], {
          [styles['selected']]: isSelected,
          [styles['hovered']]: mouseOver,
        })}
        onClick={handleClick}
        role='presentation'
      >
        <img src={agent.thumbnail_url || defaultImage} alt='avatar' />
      </div>
      <p className={styles['agent-name']}>{agent.name}</p>
      <p className={styles['creator-name']}>{agentOwner?.name}</p>
      <p className={styles['create-time']}>
        {agentCreatedOrUpdatedTime
          ? new Date(agentCreatedOrUpdatedTime).toDateString()
          : new Date().toDateString()}
      </p>
      <div className={styles['btns-wrapper']}>
        {mouseOver && (
          <>
            <IconButton
              onClick={() => {
                if (onAgentClick) {
                  onAgentClick(agent);
                } else {
                  onViewClick();
                }
              }}
              tooltip={t('form.view')}
            >
              <VisibilityRoundedIcon />
            </IconButton>
            {canEdit && (
              <IconButton onClick={handleEdit} tooltip={t('form.edit')}>
                <EditRoundedIcon />
              </IconButton>
            )}
            {canCopy && (
              <IconButton onClick={handleCopy} tooltip={t('form.copy')}>
                <ContentCopyRoundedIcon />
              </IconButton>
            )}
            {canArchive && (
              <IconButton
                onClick={() => setArchiveModalOpen(true)}
                theme='red'
                className={styles['archive-button']}
                tooltip={t('form.archive')}
              >
                <ArchiveRoundedIcon />
              </IconButton>
            )}
          </>
        )}
      </div>
      {archiveModalOpen && (
        <ConfirmModal
          setOpen={setArchiveModalOpen}
          title={t('agent.archive_agent')}
          onConfirm={() => archiveAgent({ agentId: agent.id })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isArchiveAgentLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('agent.archive_agent_desc')}
          </p>
        </ConfirmModal>
      )}
      {copyModalOpen && (
        <ConfirmModal
          setOpen={setCopyModalOpen}
          title={t('agent.copy_agent')}
          onConfirm={() => copyAgent({ agentId: agent.id })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isCopyAgentLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('agent.copy_agent_desc')}
          </p>
        </ConfirmModal>
      )}
    </div>
  );
};

export default AgentBox;
