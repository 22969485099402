import { FeedBackFrom } from 'common-ui';
import { useSnackbar } from 'notistack';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { object, string } from 'yup';

import { useApiSendFeedback } from 'src/api/user/hooks/useApiManage';

import { NO_FEEDBACK_ROUTES } from 'src/constants/routes';

import useFormData from 'src/hooks/useFormData';
import useTexts from 'src/hooks/useTexts';

import { useAppSelector } from 'src/store/hooks';
import { getAppState } from 'src/store/reducers/appSlice/selectors';

const FormDataValidationSchema = object<FeedBackFrom>({
  content: string().required(),
});

type Props = {
  maxWidth?: number;
  useFullHeight?: boolean;
};

const useComponentProps = ({ maxWidth, useFullHeight }: Props) => {
  const showFeedbackState = useState<boolean>(true);

  const [showFeedback, setShowFeedback] = showFeedbackState;
  const [feedbackPopupOpen, setFeedbackPopupOpen] = useState<boolean>(false);

  const { headerHeight } = useAppSelector(getAppState);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTexts();

  const location = useLocation();

  const { formData, formDataSetters, resetFormData, validate, errors } =
    useFormData<FeedBackFrom>({
      initialData: useMemo(() => ({ content: '' }), []),
      validationSchema: FormDataValidationSchema,
    });

  const closeFeedbackPopup = useCallback(() => {
    setFeedbackPopupOpen(false);
    resetFormData();
  }, [resetFormData]);

  const { doRequest: sendFeedback, isLoading: isSendFeedbackLoading } =
    useApiSendFeedback({
      onSuccess: useCallback(() => {
        closeFeedbackPopup();
        enqueueSnackbar(t('general.feedback_sent_successfully'), {
          variant: 'success',
        });
      }, [closeFeedbackPopup, enqueueSnackbar, t]),
    });

  const toggleFeedbackPopupOpen = useCallback(() => {
    if (feedbackPopupOpen) {
      closeFeedbackPopup();
    } else {
      setFeedbackPopupOpen(true);
    }
  }, [closeFeedbackPopup, feedbackPopupOpen]);

  const style = useMemo<CSSProperties>(() => {
    const result: CSSProperties = { maxWidth };
    if (useFullHeight) {
      result.minHeight = `calc(100vh - ${headerHeight}px)`;
    }

    return result;
  }, [maxWidth, useFullHeight, headerHeight]);

  const handleSendFeedBack = useCallback(() => {
    if (validate()) {
      sendFeedback({ formData });
    }
  }, [formData, sendFeedback, validate]);

  const allowFeedback = useMemo<boolean>(() => {
    if (!showFeedback) {
      return false;
    }
    return !NO_FEEDBACK_ROUTES.some((route) =>
      location.pathname.includes(route),
    );
  }, [location.pathname, showFeedback]);

  return {
    formData,
    formDataSetters,
    feedbackPopupOpen,
    toggleFeedbackPopupOpen,
    handleSendFeedBack,
    isSendFeedbackLoading,
    allowFeedback,
    style,
    errors,
    showFeedbackState,
  };
};

export default useComponentProps;
