import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';

import { BaseUseApiProps } from 'src/types/api/api.types';

import { makeApiStopGameInstance } from '../functions/makeApiManage';

export const useApiStopGameInstance = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<void> = {}) => {
  const doRequest = useMemo(() => makeApiStopGameInstance(), []);
  return useApi({ doRequest, onSuccess, onResponse, onError });
};
