import { User } from 'common-ui';

export type UserCell = {
  name: string;
  email: string;
  minutes: string;
  access_tier: string;
  tenure: string;
  last_login: string;
};

export const renderUserCell = (user: User): UserCell => ({
  name: user.name,
  email: `${user.email} (${user.social_media})`,
  minutes: user.minutes.toString(),
  access_tier: user.access_tier,
  tenure: calculateTenure(user.created_at, user.last_login),
  last_login: new Date(user.last_login).toLocaleString(),
});

const calculateTenure = (
  createdAt: string | Date,
  lastLogin: string | Date,
): string => {
  const start = new Date(createdAt).getTime();
  const end = new Date(lastLogin).getTime();
  const tenureInMs = Math.max(0, end - start); // Ensure non-negative duration

  // Return "N/A" if duration is less than 1 second
  if (tenureInMs < 1000) {
    return 'N/A';
  }

  const tenureInHours = tenureInMs / (1000 * 60 * 60);
  const tenureInDays = tenureInHours / 24;

  if (tenureInHours < 24) {
    return `${Math.floor(tenureInHours)} hour${Math.floor(tenureInHours) !== 1 ? 's' : ''}`;
  } else if (tenureInDays < 30) {
    return `${Math.floor(tenureInDays)} day${Math.floor(tenureInDays) !== 1 ? 's' : ''}`;
  } else if (tenureInDays < 365) {
    const months = Math.floor(tenureInDays / 30);
    return `${months} month${months !== 1 ? 's' : ''}`;
  } else {
    const years = Math.floor(tenureInDays / 365);
    return `${years} year${years !== 1 ? 's' : ''}`;
  }
};

export const tableHeaders = [
  'Name',
  'Email',
  'Minutes',
  'Access Tier',
  'Tenure',
  'Last login',
];
