import { useGoogleLogin } from '@react-oauth/google';
import { api, URLS } from 'common-ui';
import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

const useComponentProps = () => {
  const currentUser = useAppSelector(getCurrentUser);
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [apiKey, setApiKey] = useState<string>('');
  const [googleToken, setGoogleToken] = useState<string>('');

  const redirectToApp = useCallback((key: string) => {
    const link = document.createElement('a');
    link.href = `alteraapp://response&apiKey=${key}`;
    document.body.appendChild(link);
    link.click();
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const token = response?.access_token || null;
        if (!token) {
          return;
        }
        setGoogleToken(token);
        const userData = await api.get(URLS.user.loginGoogle, {
          headers: { Authorization: token },
        });
        if (userData?.data) {
          getOrCreateApiKey();
        }
      } catch (error) {
        console.error(error);
      }
    },
    flow: 'implicit',
  });

  const getOrCreateApiKey = useCallback(async () => {
    try {
      let { data: apiKeysData = {} } = await api.get(URLS.keys.getApiKeys);
      // create a new key if none exists
      if (!apiKeysData || apiKeysData.data.length === 0) {
        api.post(URLS.keys.createApiKey, { name: 'minecraft-bot' });
        apiKeysData = await api.get(URLS.keys.getApiKeys);
      }
      setApiKey(apiKeysData.data[0]);
      redirectToApp(apiKeysData.data[0].key);
      setSuccess(true);
    } catch (error) {
      console.error(error);
    }
  }, [redirectToApp]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        window.close();
      }, 5000);
    }
  }, [success]);

  useEffect(() => {
    if (currentUser) {
      getOrCreateApiKey();
    }
  }, [currentUser, googleToken, getOrCreateApiKey]);

  return {
    googleLogin,
    success,
  };
};

export default useComponentProps;
