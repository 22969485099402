import { htmlToMemoryConverter } from 'src/api/agent/converters/memory';

import { ManageGameAgentRequestData } from 'src/types/api/agent.types';
import { AgentFormData } from 'src/types/dao/agent.types';

export const convertFormDataToRequestData = (
  data: AgentFormData,
): ManageGameAgentRequestData => {
  const requestData: ManageGameAgentRequestData = {
    name: data.name,
    inner_state: data.inner_state,
    avatar_url: data.avatar_url,
    memory: {
      long_term_memory: data.memory.long_term_memory,
    },
    status: data.status,
    admin_snapshot: data.admin_snapshot ? data.admin_snapshot : null,
    safety_analysis: data.safety_analysis ? data.safety_analysis : null,

    image_url: data.image_url,
    thumbnail_url: data.thumbnail_url,
    animation_url: data.animation_url,
    minecraft_skin_url: data.minecraft_skin_url,
  };
  data.advanced && (requestData.advanced = data.advanced);

  return requestData;
};
