import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { FC } from 'react';

import Loader from 'src/components/Loader';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import NothingToShow from 'src/components/NothingToShow';

import { dateTimeWithCommaFormat } from 'src/constants/date';

import { dateToFormat } from 'src/helpers/datetime';

import { ApiKey } from 'src/types/dao/keys.types';

import { useComponentProps } from './hooks/useComponentProps';
import styles from './styles.module.scss';

export type Props = {
  apiKeys: Array<ApiKey>;
  reload: () => void;
  isLoading: boolean;
};

const ApiKeysList: FC<Props> = ({ apiKeys, reload, isLoading }) => {
  const {
    t,
    deleteApiKey,
    isDeleteApiKeyLoading,
    handleCopyApiKey,
    handleDeleteApiKey,
    apiKeyId,
    deleteModalOpen,
    setDeleteModalOpen,
  } = useComponentProps({ reload });

  if (!apiKeys.length) {
    return <NothingToShow className={styles['nothing-to-show']} />;
  }

  return (
    <>
      <div className={styles['api-keys-list']}>
        {apiKeys.map((apiKey, index) => (
          <div className={styles['api-key-item']} key={index}>
            <div className={styles['api-key-name']}>{apiKey.name}</div>
            <div className={styles['api-key']}>{apiKey.key}</div>
            <div className={styles['api-key-created-at']}>
              {dateToFormat(
                new Date(apiKey.created_at),
                dateTimeWithCommaFormat,
              )}
            </div>
            <div className={styles['api-key-icons']}>
              <ContentCopyIcon
                className={styles['action-icon']}
                onClick={() => handleCopyApiKey(apiKey.key)}
              />
              <DeleteRoundedIcon
                className={styles['action-icon']}
                onClick={() => handleDeleteApiKey(apiKey.id)}
              />
            </div>
          </div>
        ))}
        {isLoading && (
          <div className={styles['loader-wrapper']}>
            <Loader absolute backdrop />
          </div>
        )}
      </div>
      {deleteModalOpen && (
        <ConfirmModal
          setOpen={setDeleteModalOpen}
          title={t('settings.delete_api_key')}
          onConfirm={() => deleteApiKey({ apiKeyId })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isDeleteApiKeyLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('settings.delete_api_key_desc')}
          </p>
        </ConfirmModal>
      )}
    </>
  );
};

export default ApiKeysList;
