export const APP_ROUTE = '/';
export const GOOGLE_LOGIN_ROUTE = '/login/connect-account';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';

export const HUB_ROUTE = '/hub';
export const SETTINGS_ROUTE = '/settings';

export const GAME_WEB_SIMULATION_ROUTE = '/web-simulation';

export const AGENTS_ROUTE = '/agents';
export const AGENT_CREATE_ROUTE = '/agent/create';
export const AGENT_EDIT_ROUTE = '/agent/edit';
export const AGENT_VIEW_ROUTE = '/agent/view';

export const PAYMENT_CANCELED_ROUTE = '/payment/canceled';
export const PRICE_ROUTE = '/price';

export const ADMIN_RUNNING_GAME_PLAYS_ROUTE = '/running-game-plays';
export const ADMIN_COMPLETED_GAME_PLAYS_ROUTE = '/completed-game-plays';
// export const ADMIN_BRANCHES_ROUTE = "/branches"; TODO: remove whole branches page related code
export const ADMIN_CHATS_ROUTE = '/chats';
export const ADMIN_GAME_INSTANCE_ROUTE = '/game-instance';
export const ADMIN_INVITATION_ROUTE = '/invitation';
export const ADMIN_USERS_ROUTE = '/users';
export const ADMIN_USER_ROUTE = '/user';

// Routes that should not show the feedback button
export const NO_FEEDBACK_ROUTES = [
  ADMIN_INVITATION_ROUTE,
  ADMIN_RUNNING_GAME_PLAYS_ROUTE,
  ADMIN_COMPLETED_GAME_PLAYS_ROUTE,
  ADMIN_USERS_ROUTE,
  APP_ROUTE,
];
