import { UserTier } from 'common-ui';
import { FC } from 'react';

import Loader from 'src/components/Loader';
import ConfirmModal from 'src/components/Modals/ConfirmModal';

import AgentEditForm from 'src/features/components/agent/AgentEditForm';
import AgentCheckpointsDisplay from 'src/features/components/agent/AgentInstance';

import useTexts from 'src/hooks/useTexts';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

export type Props = {
  id: string;
};

const ViewAgent: FC<Props> = ({ id }) => {
  const {
    initialData,
    isLoading,
    copyModalOpen,
    setCopyModalOpen,
    canCopy,
    copyAgent,
    isCopyAgentLoading,
    agentInstances: instances,
  } = useComponentProps({
    id,
  });

  const { t } = useTexts();
  const currentUser = useAppSelector(getCurrentUser);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles['container']}>
      <AgentEditForm
        initialData={initialData}
        {...(canCopy && {
          onCopy: () => setCopyModalOpen(true),
        })}
        isAdmin={currentUser?.access_tier === UserTier.ADMIN}
        readonly
      />
      {copyModalOpen && (
        <ConfirmModal
          setOpen={setCopyModalOpen}
          title={t('agent.copy_agent')}
          onConfirm={() => copyAgent({ agentId: id })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isCopyAgentLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('agent.copy_agent_desc')}
          </p>
        </ConfirmModal>
      )}
      <div className={styles['section']}>
        <h2 className={styles['title']}>{t('user.agent_instances')}:</h2>
        <AgentCheckpointsDisplay instances={instances} />
      </div>
    </div>
  );
};

export default ViewAgent;
