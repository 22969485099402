import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';

import { BaseUseApiProps } from 'src/types/api/api.types';
import { GetApiKeysResponse } from 'src/types/api/keys.types';

import { makeApiGetApiKeys } from '../functions/makeApiGet';
import mockApiKeysResponse from '../mock/getApiKeys';

export const useApiGetApiKeys = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<GetApiKeysResponse> = {}) => {
  const doRequest = useMemo(() => makeApiGetApiKeys(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
    mockData: mockApiKeysResponse,
  });
};
