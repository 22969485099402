import { useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';

import Loader from 'src/components/Loader';

import AgentEditForm from 'src/features/components/agent/AgentEditForm';

import useNavigationBlock from 'src/hooks/useNavigationBlock';
import useTexts from 'src/hooks/useTexts';

import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

export type Props = {
  id: string;
};

const EditAgent: FC<Props> = ({ id }) => {
  const {
    initialData,
    isLoading,
    startGame,
    handleSave,
    canRedirect,
    setIsInitialDataChanged,
    isAdmin,
  } = useComponentProps({ id });
  const { t } = useTexts();

  const { NavigationGuard } = useNavigationBlock({
    when: !canRedirect,
    message: t('general.have_unsaved_changes'),
  });

  const { enqueueSnackbar } = useSnackbar();
  const handleStart = useCallback(() => {
    startGame({ agent_proto_id: id });
    enqueueSnackbar('Starting agent...', { variant: 'info' });
  }, [enqueueSnackbar, id, startGame]);

  if (isLoading && !initialData) {
    return <Loader />;
  }

  return (
    <>
      <NavigationGuard />
      <div className={styles['container']}>
        <AgentEditForm
          initialData={initialData}
          onSave={handleSave}
          onStart={handleStart}
          setIsInitialDataChanged={setIsInitialDataChanged}
          isAdmin={isAdmin}
        />
      </div>
    </>
  );
};

export default EditAgent;
