import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface PaginationProps {
  loadNextPage: () => void;
  loadPrevPage: () => void;
  pageNumber: number;
  canLoadNext: boolean;
  canLoadPrev: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  loadNextPage,
  loadPrevPage,
  pageNumber,
  canLoadNext,
  canLoadPrev,
}) => {
  const handlePrevPageClick = () => {
    loadPrevPage();
  };

  const handleNextPageClick = () => {
    loadNextPage();
  };

  return (
    <div className={styles.pagination}>
      <button
        className={classNames(styles.buttonArrow, {
          [styles.disabled]: !canLoadPrev,
        })}
        onClick={handlePrevPageClick}
        disabled={!canLoadPrev}
      >
        &laquo;
      </button>
      {pageNumber}
      <button
        className={classNames(styles.buttonArrow, {
          [styles.disabled]: !canLoadNext,
        })}
        onClick={handleNextPageClick}
        disabled={!canLoadNext}
      >
        &raquo;
      </button>
    </div>
  );
};

export default Pagination;
