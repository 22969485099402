import { AxiosResponse } from 'axios';
import { api } from 'common-ui';
import { URLS } from 'common-ui';

import { ChatMessagesResponse } from 'src/types/api/chat.types';

type GetMessagesProps = {
  chatIds: string[];
  page?: number;
  perPage?: number;
  filters?: {
    from_time?: number;
    to_time?: number;
  };
};

export const makeApiGet =
  () =>
  ({
    page,
    perPage,
    filters,
    chatIds,
  }: GetMessagesProps): Promise<AxiosResponse<ChatMessagesResponse>> =>
    api.get<ChatMessagesResponse>(URLS.chat.getChatMessages, {
      params: {
        ...(page && {
          page,
        }),
        ...(perPage && {
          per_page: perPage,
        }),
        ...(filters || {}),
        chat_ids: chatIds,
      },
    });
