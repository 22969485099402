export const HTMLTagRegExp = new RegExp(/<(.|\n)*?>/g);

export const textVariableReplacer = (
  text: string,
  options: { [key: string]: string | number },
  translation = false,
) => {
  let replacedText: string = text.slice();
  for (const [variable, value] of Object.entries(options)) {
    if (translation) {
      replacedText = replacedText.replaceAll(`{{${variable}}}`, `${value}`);
    } else {
      replacedText = replacedText.replaceAll(`{${variable}}`, `${value}`);
    }
  }

  return replacedText;
};

export const clearHTML = (html: string) => {
  return html.replace(HTMLTagRegExp, '').trim();
};

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const toCamelCase = (str = ''): string => {
  if (!str) return '';

  return String(str)
    .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
    .replace(/[^A-Za-z0-9]+/g, '$')
    .replace(/([a-z])([A-Z])/g, (m, a, b) => `${a}$${b}`)
    .toLowerCase()
    .replace(/(\$)(\w)/g, (m, a, b) => b.toUpperCase());
};

export const toSnakeCase = (str = ''): string => {
  if (!str) return '';

  return String(str)
    .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
    .replace(/([a-z])([A-Z])/g, (m, a, b) => a + '_' + b.toLowerCase())
    .replace(/[^A-Za-z0-9]+|_+/g, '_')
    .toLowerCase();
};

export const toTextCase = (str = ''): string => {
  if (!str) return '';

  return String(str)
    .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, '')
    .replace(/([a-z])([A-Z])/g, (m, a, b) => a + '_' + b.toLowerCase())
    .replace(/[^A-Za-z0-9]+|_+/g, ' ')
    .toLowerCase();
};

export const escapeRegExp = (text: string): string => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters for regex
};

export const renderHighlightedText = (
  text: string,
  highlightTexts: string[],
  highlightClassName: string,
) => {
  if (!highlightTexts.length) return text; // Return original text if there are no highlights

  const regexPattern = highlightTexts.map((ht) => escapeRegExp(ht)).join('|');
  const regex = new RegExp(`(${regexPattern})`, 'gi');

  const parts = text.split(regex);
  return parts.map((part, i) =>
    highlightTexts.some((ht) => ht.toLowerCase() === part.toLowerCase()) ? (
      <span key={i} className={highlightClassName}>
        {part}
      </span>
    ) : (
      part
    ),
  );
};
