import { getTierWeight, UserTier } from 'common-ui';
import { ReactNode, useCallback } from 'react';
import { RouteProps } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';

import Loader from 'src/components/Loader';
import { useRedirectTo } from 'src/components/Router/hooks/useRedirectTo';

type Props = Omit<RouteProps, 'component' | 'render'> & {
  component: ReactNode;
  isValid: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  requiredAccessTier: UserTier;
  accessTier?: UserTier;
  redirectPath?: string;
};

const PrivateRoute = ({
  path,
  component,
  isValid,
  isLoading,
  isLoaded,
  requiredAccessTier,
  accessTier,
  redirectPath,
  ...rest
}: Props) => {
  const { RedirectToPage } = redirectPath
    ? useRedirectTo(redirectPath)
    : useRedirectTo();
  const render = useCallback(
    (routeProps: RouteComponentProps) => {
      if (!isLoaded) {
        return <></>;
      }
      if (isLoading) {
        return <Loader />;
      }
      const currentTierWeight = getTierWeight(accessTier);
      const requiredTierWeight = getTierWeight(requiredAccessTier);

      if (!isValid || currentTierWeight < requiredTierWeight) {
        // If the user is not logged in, redirect to the original path.
        return RedirectToPage(
          accessTier ? undefined : routeProps.location.pathname,
          routeProps.match.params,
        );
      }

      return component;
    },
    [
      RedirectToPage,
      requiredAccessTier,
      component,
      accessTier,
      isLoaded,
      isLoading,
      isValid,
    ],
  );

  return <Route {...rest} path={path} render={render} />;
};

export default PrivateRoute;
