import classNames from 'classnames';
import { FC, useMemo } from 'react';

import Accordion from 'src/components/Accordion';
import Button from 'src/components/Buttons/Button';
import TextField from 'src/components/Inputs/TextField';

import { Errors } from 'src/helpers/errors';
import { capitalizeFirstLetter, toTextCase } from 'src/helpers/text';

import useTexts from 'src/hooks/useTexts';

import {
  AgentCharacteristics,
  NewCharacteristicForm,
} from 'src/types/dao/agent.types';

import characteristicsHintsData from './hintsData';
import styles from './styles.module.scss';

export type Props = {
  name: string;
  value: string;
  setName: (value: string) => void;
  setValue: (value: string) => void;
  onAdd: () => void;
  added: AgentCharacteristics;
  validationErrors: Errors<Partial<NewCharacteristicForm>> | null;
};

const AddCharacteristic: FC<Props> = ({
  name,
  setName,
  value,
  setValue,
  onAdd,
  added,
  validationErrors,
}) => {
  const { t } = useTexts();

  const actualHints = useMemo<Record<string, string>>(
    () =>
      Object.fromEntries(
        Object.entries(characteristicsHintsData).filter(
          (hint) => !Object.keys(added).includes(hint[0]),
        ),
      ),
    [added],
  );

  const actualHint = useMemo<
    undefined | { name: string; value: string }
  >(() => {
    let actualKey: string | undefined = Object.keys(actualHints)[0];

    if (name) {
      const foundKey = Object.keys(actualHints).find((hint) =>
        toTextCase(hint).toLowerCase().includes(name.toLowerCase()),
      );
      if (foundKey) {
        actualKey = foundKey;
      }
    }

    if (!actualKey) {
      return undefined;
    }

    return {
      name: capitalizeFirstLetter(toTextCase(actualKey)),
      value: actualHints[actualKey],
    };
  }, [actualHints, name]);

  return (
    <div className={classNames(styles['add-characteristic-wrapper'])}>
      <Accordion
        description={t('agent.add_additional_characteristics')}
        className={styles['add-characteristic']}
      >
        {() => (
          <div className={styles['add-characteristic-content']}>
            <TextField
              id='name-input'
              required
              value={name}
              label={name !== '' ? t('form.name') : ''}
              placeholder={'Purpose'}
              onChange={setName}
              className={styles['name-input']}
              {...(validationErrors?.name && {
                error: true,
                helperText: validationErrors.name,
              })}
              size='small'
            />
            <TextField
              id='value-input'
              value={value}
              label={value !== '' ? t('agent.characteristic') : ''}
              placeholder={
                'You want to help those around you progress at Minecraft.'
              }
              onChange={setValue}
              size='small'
            />
            <Button
              id='add-btn'
              onClick={onAdd}
              wrapperClassName={styles['add-btn']}
              size='small'
            >
              {t('form.add')}
            </Button>
          </div>
        )}
      </Accordion>
    </div>
  );
};

export default AddCharacteristic;
