import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useState } from 'react';

import styles from './styles.module.scss';

type SidePanelProps = {
  isOpen: boolean;
  onPanelClose: () => void;
  children: ReactNode;
};

const SidePanel: FC<SidePanelProps> = ({
  isOpen = false,
  onPanelClose,
  children,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(isOpen);

  useEffect(() => {
    // Устанавливаем таймер, чтобы включить видимость с задержкой для плавного появления
    let timer: NodeJS.Timeout;
    if (isOpen) {
      setIsVisible(true);
    } else {
      timer = setTimeout(() => setIsVisible(false), 300); // Устанавливаем таймер для скрытия после завершения перехода
    }

    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsVisible(false);
        setTimeout(onPanelClose, 300);
      }
    };

    window.addEventListener('keydown', handleEscKey);
    document.body.classList.add(styles['no-scroll']);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
      document.body.classList.remove(styles['no-scroll']);
      if (timer) clearTimeout(timer);
    };
  }, [isOpen, onPanelClose]);

  const overlayClass = classNames(styles['side-panel-overlay'], {
    [styles['show']]: isVisible,
  });
  const contentClass = classNames(styles['side-panel-content'], {
    [styles['show']]: isVisible,
  });

  return (
    <>
      <div
        className={overlayClass}
        onClick={() => {
          setIsVisible(false);
          setTimeout(onPanelClose, 300);
        }}
      ></div>
      <div className={contentClass}>{children}</div>
    </>
  );
};

export default SidePanel;
