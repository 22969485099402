import { CurrentUserResponse, UserTier } from 'common-ui';
import { useCallback, useEffect } from 'react';

import { useApiGetCurrentUser } from '../api/user/hooks/useApiGet';
import { useAppDispatch } from '../store/hooks';
import { userSlice } from '../store/reducers/userSlice';

const useGetAndUpdateUserData = () => {
  const dispatch = useAppDispatch();

  const {
    doRequest: getUser,
    responseData,
    isLoading,
    success,
  } = useApiGetCurrentUser({
    onResponse: useCallback(
      (response: CurrentUserResponse) => {
        if (response.access_tier !== UserTier.ADMIN) {
          return;
        }
        dispatch(
          userSlice.actions.setUserProfile({
            id: response.id,
            name: response.name,
            email: response.email,
            picture: response.picture,
            access_tier: response.access_tier,
            minutes: response.minutes,
            subscription: response.subscription,
            last_login: response.last_login ?? new Date(),
            created_at: response.created_at ?? new Date(),
            social_media: response.social_media,
            profile: response.profile,
          }),
        );
      },
      [dispatch],
    ),
  });

  // Fetch user information
  useEffect(() => {
    getUser();
  }, [getUser]);

  return {
    getUser,
    responseData,
    isLoading,
    success,
  };
};

export default useGetAndUpdateUserData;
