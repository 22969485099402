import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { on } from 'events';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useCallback } from 'react';

import IconButton from 'src/components/Buttons/IconButton';
import Loader from 'src/components/Loader';
import NothingToShow from 'src/components/NothingToShow';

import { dateTimeWithCommaFormat } from 'src/constants/date';

import { dateToFormat, getDifferenceInTime } from 'src/helpers/datetime';

import useTexts from 'src/hooks/useTexts';

import { AgentInstanceCheckpoint } from 'src/types/dao/agent.types';
import { GameInstance } from 'src/types/dao/game.types';

import AgentBox from '../../agent/AgentBox';
import UserBox from '../../user/UserBox';
import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

interface AgentCheckpointDisplayProps {
  checkpoint: AgentInstanceCheckpoint;
  onStart: () => void;
}

const AgentCheckpointDisplay: React.FC<AgentCheckpointDisplayProps> = ({
  checkpoint,
  onStart,
}) => {
  const { t } = useTexts();

  const initialState = checkpoint.agent_proto ?? {};
  const updatedState = checkpoint.readable_agent_snapshot ?? {};

  return (
    <div className={styles.checkpointWrapper}>
      <h3 className={styles.checkpointTitle}>
        Checkpoint ID: {checkpoint.id}
        <IconButton
          onClick={onStart}
          tooltip={t('form.start')}
          className={styles['icon']}
        >
          <PlayArrowRoundedIcon />
        </IconButton>
      </h3>
      <table className={styles.checkpointTable}>
        <thead>
          <tr>
            <th className={styles.tableHeader}>Initial State</th>
            <th
              className={`${styles.tableHeader} ${styles.updatedStateHeader}`}
            >
              Updated State at{' '}
              {dateToFormat(
                new Date(checkpoint.last_updated_time),
                dateTimeWithCommaFormat,
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.tableCell}>
              <pre className={styles.stateContent}>
                {JSON.stringify(initialState, null, 2)}
              </pre>
            </td>
            <td className={`${styles.tableCell} ${styles.updatedStateCell}`}>
              <pre className={styles.stateContent}>
                {JSON.stringify(updatedState, null, 2)}
              </pre>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export type GameInstanceDetailProps = {
  gameInstanceId: string;
};
const GameInstanceDetail: React.FC<GameInstanceDetailProps> = ({
  gameInstanceId,
}) => {
  const { t } = useTexts();

  const {
    gameInstance,
    agentCheckpoints,
    agentsInGame,
    playerInGame,
    gameOwners,
    isLoading,
    startGame,
  } = useComponentProps({ gameInstanceId });
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(gameInstance?.id ?? '');
    enqueueSnackbar(t('general.copied'), { variant: 'success' });
  }, [t, gameInstance, enqueueSnackbar]);

  const handleStartGame = (checkpointId: string) => () => {
    startGame({ agent_checkpoint_id: checkpointId });
    enqueueSnackbar('Starting game...', { variant: 'info' });
  };

  if (isLoading) {
    return <Loader />;
  }

  const renderGameInstanceInfo = (instance: GameInstance) => {
    const isOngoing = instance.end_time === null;
    return (
      <div className={styles.gameInstanceInfo}>
        <div className={styles.instanceId}>
          ID: {instance.id}
          <IconButton
            onClick={handleCopyLink}
            tooltip={t('form.copy')}
            className={styles['icon']}
          >
            <ContentCopyIcon />
          </IconButton>
        </div>
        <p className={styles.instanceTime}>
          Start Time:{' '}
          {dateToFormat(new Date(instance.start_time), dateTimeWithCommaFormat)}
        </p>
        <p className={styles.instanceTime}>
          {isOngoing
            ? 'Ongoing'
            : `End Time: ${dateToFormat(new Date(instance.end_time), dateTimeWithCommaFormat)}`}
        </p>
        <p className={styles.playedTime}>
          Total play time:{' '}
          <span className={styles.time}>
            {getDifferenceInTime(
              new Date(instance.start_time),
              isOngoing ? new Date() : new Date(instance.end_time),
            )}
          </span>
        </p>
        {isOngoing && <p className={styles.ongoingBadge}>Ongoing</p>}
      </div>
    );
  };

  return (
    <div className={styles.gameInstanceDetail}>
      <h1 className={styles.title}>{t('gameInstanceDetail.title')}</h1>
      <div className={styles.infoRow}>
        <div className={styles.infoColumn}>
          <h2 className={styles.sectionTitle}>
            {t('gameInstanceDetail.status')}
          </h2>
          {gameInstance && renderGameInstanceInfo(gameInstance)}
        </div>
        <div className={styles.infoColumn}>
          <h2 className={styles.sectionTitle}>Player information</h2>
          {playerInGame && <UserBox data={playerInGame} readonly />}
        </div>
        <div className={styles.infoColumn}>
          <h2 className={styles.sectionTitle}>
            {t('gameInstanceDetail.agents')}
          </h2>
          <ul className={styles.agentList}>
            {agentsInGame?.length === 0 && <NothingToShow />}
            {agentsInGame.map((agent, index) => (
              <li key={index} className={styles.agentItem}>
                <AgentBox agent={agent} users={gameOwners} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>
          {t('gameInstanceDetail.agentCheckpoints')}
        </h2>
        <ul className={styles.checkpointList}>
          {!agentCheckpoints ||
            (agentCheckpoints?.length === 0 && <NothingToShow />)}
          {agentCheckpoints &&
            agentCheckpoints.map((checkpoint) => (
              <li key={checkpoint.id} className={styles.checkpointItem}>
                <AgentCheckpointDisplay
                  checkpoint={checkpoint}
                  onStart={handleStartGame(checkpoint.id)}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default GameInstanceDetail;
