export const inputToNumber = (value: string): number => {
  const stringNumber = value.replaceAll(/\D/g, '');
  let resString = '0';

  if (stringNumber.length > 1) {
    let nonZero = false;
    for (const num of stringNumber) {
      if (num !== '0') {
        resString += num;
        nonZero = true;
      } else if (nonZero) {
        resString += num;
      }
    }
  } else {
    resString = stringNumber;
  }

  if (resString.length > 1 && resString.startsWith('0')) {
    resString = resString.slice(1);
  }

  if (resString.length > 15) {
    resString = resString.slice(0, 15);
  }

  return Number(resString);
};
