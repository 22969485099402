import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

import TextField from 'src/components/Inputs/TextField';

import { inputToNumber } from 'src/helpers/input';

import styles from './styles.module.scss';

type ComponentProps = {
  value: number;
  onChange?: (value: number) => void;
  changed?: boolean;
  wrapperClassName?: string;
  theme?: 'white' | 'black';
};

export type Props = Omit<TextFieldProps, 'value' | 'onChange' | 'inputProps'> &
  ComponentProps;

const NumberField: FC<Props> = ({
  value,
  onChange,
  theme = 'white',
  wrapperClassName,
  className,
  ...props
}) => {
  const handleChange = useCallback(
    (val: string) => {
      if (!onChange) {
        return;
      }
      const number = inputToNumber(val);
      onChange(number < 0 || !val ? 0 : number);
    },
    [onChange],
  );

  const handleIncrease = useCallback(
    () => handleChange((value + 1).toString()),
    [handleChange, value],
  );
  const handleDecrease = useCallback(
    () => handleChange(value === 0 ? '0' : (value - 1).toString()),
    [handleChange, value],
  );

  return (
    <div className={classNames(styles['wrapper'], wrapperClassName)}>
      <TextField
        {...props}
        value={value}
        onChange={handleChange}
        theme={theme}
        className={classNames(className, styles['input'])}
      />
      <div className={styles['buttons-wrapper']}>
        <button className={styles['button']}>
          <KeyboardArrowUpIcon
            onClick={handleIncrease}
            className={styles['icon']}
          />
        </button>
        <button className={styles['button']}>
          <KeyboardArrowDownIcon
            onClick={handleDecrease}
            className={styles['icon']}
          />
        </button>
      </div>
    </div>
  );
};

export default NumberField;
