import { CookieKey } from 'common-ui';

export const setCookie = (name: CookieKey, value: string) => {
  document.cookie = `${name}=${value}`;
};

export const getCookie = (name: CookieKey): string | null => {
  const cookieKey: string = name + '=';
  const cookies: string[] = document.cookie.split(';');

  const foundCookie: string | undefined = cookies.find((item) =>
    item.includes(cookieKey),
  );

  if (!foundCookie) {
    return null;
  }

  return foundCookie.replace(cookieKey, '');
};

export const clearCookie = (name: CookieKey) => {
  document.cookie = `${name}= `;
};
