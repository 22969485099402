import { AdminGetGameInstancesResponse } from 'src/types/api/game.types';

const adminGameInstancesMock: AdminGetGameInstancesResponse = {
  data: [
    {
      id: 'uuid',
      host_name: '0.0.0.0',
      initiator_id: 'uuid',
      start_time: '2024-08-30T18:27:44.623399-04:00',
      end_time: '2024-08-30T18:27:44.623399-04:00',
      agent_names: ['agent1', 'agent2'],
      agent_creator_ids: ['uuid1', 'uuid2'],
    },
    {
      id: 'uuid',
      host_name: '1.1.1.1',
      initiator_id: 'uuid1',
      start_time: '2024-08-23T18:27:41.623399-04:00',
      end_time: '2024-10-23T18:20:44.623399-04:00',
      agent_names: ['agent1'],
      agent_creator_ids: ['uuid1'],
    },
    {
      id: 'uuid',
      host_name: '2.2.2.2',
      initiator_id: 'uuid2',
      start_time: '2024-08-30T18:27:44.623399-04:00',
      end_time: '2024-10-30T18:27:44.623399-04:00',
      agent_names: ['agent2'],
      agent_creator_ids: ['uuid2'],
    },
    {
      id: 'uuid',
      host_name: '3.3.3.3',
      initiator_id: 'uuid3',
      start_time: '2024-08-17T18:27:44.623399-04:00',
      end_time: '2024-08-17T18:27:44.623399-04:00',
      agent_names: ['agent3'],
      agent_creator_ids: ['uuid3'],
    },
  ],
  pagination: {
    current_page: 1,
    having_next_page: false,
  },
};

export default adminGameInstancesMock;
