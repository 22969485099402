// TODO: Check why this isn't suggesting types.ts files instead it's suggesting types.js files.
import { AllFilter } from "src/types/general.types.js";

export enum UserTier {
  ADMIN = "admin",
  REGULAR = "regular",
  PREMIUM = "premium",

  // Legacy roles, should be removed
  GAME_EDITOR = "game_editor",
  RESEARCHER = "researcher",
}

export function getTierWeight(tier?: UserTier): number {
  switch (tier) {
    case UserTier.ADMIN:
      return 4;
    case UserTier.RESEARCHER:
      return 3;
    case UserTier.PREMIUM:
      return 2;
    case UserTier.GAME_EDITOR:
      return 2;
    case UserTier.REGULAR:
      return 1;
    default:
      return 0;
  }
}

export type UserTierFilter = UserTier | AllFilter;

export type User = {
  id: string;
  name: string;
  email: string;
  picture: string;
  minutes: number;
  social_media: string | null;
  subscription: string | null;
  access_tier: UserTier;
  last_login: Date | string;
  created_at: Date | string;
};

export type PlayLog = {
  user_id: string;
  game_instance_id: string;
  log_content: string;
  ip_address: string;
  created_at: string;
  name: string;
  start_time: string;
};
