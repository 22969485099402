import { FC } from 'react';

import PageContainer from 'src/components/PageContainer';

import GoogleLogin from 'src/features/pages/public/GoogleLogin';

const GoogleLoginPage: FC = () => {
  return (
    <PageContainer simple width={false}>
      <GoogleLogin />
    </PageContainer>
  );
};

export default GoogleLoginPage;
