import { api } from 'common-ui';
import { URLS } from 'common-ui';

import { AdminGetUsersResponse } from 'src/types/api/user.types';

type GetUsersProps = {
  page?: number;
  perPage?: number;
  filters?: {
    ids?: string[];
  };
};

export const makeApiAdminGetUsers =
  () =>
  ({ page, perPage, filters }: GetUsersProps = {}) =>
    api.get<AdminGetUsersResponse>(URLS.user.admin.getUsers, {
      params: {
        ...(page && {
          page,
        }),
        ...(perPage && {
          per_page: perPage,
        }),
        ...(filters || {}),
      },
    });
