import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { Container } from '@mui/material';
import { Breakpoint } from '@mui/system';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import TextField from 'src/components/Inputs/TextField';

import useTexts from 'src/hooks/useTexts';

import { FeedbackContext } from './context';
import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  width?: Breakpoint | false;
  maxWidth?: number;
  useFullHeight?: boolean;
  simple?: boolean;
};

const PageContainer: FC<Props> = ({
  children,
  className,
  width = 'xl',
  maxWidth,
  useFullHeight,
  simple,
}) => {
  const {
    formData,
    formDataSetters,
    feedbackPopupOpen,
    toggleFeedbackPopupOpen,
    handleSendFeedBack,
    isSendFeedbackLoading,
    allowFeedback,
    showFeedbackState,
    style,
    errors,
  } = useComponentProps({
    maxWidth,
    useFullHeight,
  });
  const { t } = useTexts();

  return (
    <div
      className={classNames(styles['page-wrapper'], {
        [styles['simple']]: simple,
      })}
    >
      <FeedbackContext.Provider
        value={{
          showFeedback: showFeedbackState[0],
          setShowFeedback: showFeedbackState[1],
        }}
      >
        <Container
          className={classNames(className, styles['container'])}
          maxWidth={width}
          style={style}
        >
          {children}
        </Container>
      </FeedbackContext.Provider>
      {allowFeedback && (
        <div className={styles['feedback-wrapper']}>
          <div className={styles['btns-wrapper']}>
            {!!formData.content && feedbackPopupOpen && (
              <button
                className={classNames(
                  styles['feedback-btn'],
                  styles['send-btn'],
                )}
                onClick={handleSendFeedBack}
                disabled={isSendFeedbackLoading}
              >
                <CheckCircleOutlineIcon className={styles['icon']} />
              </button>
            )}
            <button
              className={styles['feedback-btn']}
              onClick={toggleFeedbackPopupOpen}
            >
              {feedbackPopupOpen ? (
                <CloseIcon className={styles['icon']} />
              ) : (
                <InsertCommentIcon className={styles['icon']} />
              )}
            </button>
          </div>
          {feedbackPopupOpen && (
            <div className={styles['feedback-input-wrapper']}>
              <TextField
                value={formData.content}
                onChange={formDataSetters.content}
                label={t('general.feedback')}
                multiline
                maxRows={4}
                {...(errors?.content && {
                  error: true,
                  helperText: errors.content,
                })}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PageContainer;
