import React, { FC, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Navbar from 'src/components/Navbar';

import useWindowSize from 'src/hooks/useWindowSize';

import { useAppDispatch } from 'src/store/hooks';
import { appSlice } from 'src/store/reducers/appSlice';

import AppRoutes from './components/AppRoutes';
import { useValidateUser } from './hooks/useValidateUser';

const AppRouter: FC = () => {
  const { isValid, isLoading, isLoaded, accessTier } = useValidateUser();
  const windowSize = useWindowSize();

  const navbar = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (navbar.current) {
      dispatch(
        appSlice.actions.setHeaderHeight(navbar.current?.offsetHeight || 0),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, windowSize, navbar?.current?.offsetHeight]);

  return (
    <Router>
      <Navbar ref={navbar} />
      <AppRoutes {...{ isValid, isLoading, isLoaded, accessTier }} />
    </Router>
  );
};

export default AppRouter;
