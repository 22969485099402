import { useThrottleCallback } from '@react-hook/throttle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApiCreateAgent } from 'src/api/agent/hooks/useApiManage';

import { AGENT_EDIT_ROUTE } from 'src/constants/routes';

import { prepareAgentFormData } from 'src/features/components/agent/AgentEditForm/functions/formData';

import usePageLoader from 'src/hooks/usePageLoader';

import { GetAgentSuccessResponse } from 'src/types/api/api.types';
import { AgentFormData } from 'src/types/dao/agent.types';

const useComponentProps = () => {
  const [validateRedirect, setRedirectValidate] = useState<boolean>(true);
  const [isInitialDataChanged, setIsInitialDataChanged] =
    useState<boolean>(false);
  const [formForSave, setFormForSave] = useState<AgentFormData | null>(null);

  const history = useHistory();

  const initialData = useMemo(() => prepareAgentFormData(), []);

  const canRedirect = useMemo<boolean>(() => {
    if (!validateRedirect) {
      return true;
    }
    return isInitialDataChanged;
  }, [isInitialDataChanged, validateRedirect]);

  const redirectToEdit = useThrottleCallback(
    useCallback(
      (id: string) => {
        history.push(`${AGENT_EDIT_ROUTE}/${id}`);
      },
      [history],
    ),
  );

  const { doRequest: save, isLoading: saveLoading } = useApiCreateAgent({
    onResponse: useThrottleCallback(
      useCallback(
        (data: GetAgentSuccessResponse) => {
          setRedirectValidate(false);
          redirectToEdit(data.data.id);
        },
        [redirectToEdit],
      ),
    ),
  });

  useEffect(() => {
    if (formForSave) {
      save({ formData: formForSave });
      setFormForSave(null);
    }
  }, [formForSave, save]);

  usePageLoader({ loading: saveLoading });

  const handleSave = useCallback(
    async (formData: AgentFormData) => {
      save({ formData });
    },
    [save],
  );

  return {
    initialData,
    handleSave,
    canRedirect,
    setIsInitialDataChanged,
  };
};

export default useComponentProps;
