import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import agentSlice from './reducers/agentSlice';
import appSlice from './reducers/appSlice';
import userSlice from './reducers/userSlice';

const reducers = {
  user: userSlice,
  app: appSlice,
  agent: agentSlice,
};

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV === 'development',
  middleware: [thunk],
});

export default store;
