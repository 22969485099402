import React, { FC } from 'react';

import AdminUser from '../../../../../features/pages/admin/AdminUser';
import { useAppSelector } from '../../../../../store/hooks';
import { getSelectedUser } from '../../../../../store/reducers/userSlice/selectors';
import SidePanel from '../../index';

type SidePanelProps = {
  onPanelClose: () => void;
  isOpen: boolean;
};

const UserPanel: FC<SidePanelProps> = ({ isOpen, onPanelClose }) => {
  const selectedUser = useAppSelector(getSelectedUser);
  return (
    <>
      {selectedUser && (
        <SidePanel onPanelClose={onPanelClose} isOpen={isOpen}>
          <AdminUser id={selectedUser.id} />
        </SidePanel>
      )}
    </>
  );
};

export default UserPanel;
