import { FC } from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from 'src/components/PageContainer';

import EditAgent from 'src/features/pages/private/EditAgent';

type PageParams = {
  id: string;
};

const EditAgentPage: FC = () => {
  const { id } = useParams<PageParams>();

  return (
    <PageContainer width='lg'>
      <EditAgent id={id} />
    </PageContainer>
  );
};

export default EditAgentPage;
