import React, { FC } from 'react';

import PageContainer from 'src/components/PageContainer';

import Landing from 'src/features/pages/public/Landing';

const LandingPage: FC = () => {
  return (
    <PageContainer simple width={false}>
      <Landing />
    </PageContainer>
  );
};

export default LandingPage;
