import { FC } from 'react';

import Loader from 'src/components/Loader';
import PageContainer from 'src/components/PageContainer';

import CompletedGameInstances from 'src/features/components/game/CompletedGameInstances';

import styles from './styles.module.scss';

const CompletedGamePlaysPage: FC = () => {
  return (
    <PageContainer simple width={false}>
      <div className={styles['page']}>
        <CompletedGameInstances
          additionalClassName={styles['info-group']}
          customLoader={<Loader absolute backdrop />}
        />
      </div>
    </PageContainer>
  );
};

export default CompletedGamePlaysPage;
