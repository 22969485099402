import React, { FC } from 'react';

import useTexts from 'src/hooks/useTexts';

import styles from '../../styles.module.scss';

export type Props = {
  lineData: { [key: string]: React.ReactNode | string };
  onCellClick?: () => void;
  onActionClick?: () => void;
};

const TableLine: FC<Props> = ({ lineData, onCellClick, onActionClick }) => {
  const { t } = useTexts();

  const handleCellClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onCellClick?.();
  };

  const handleActionClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onActionClick?.();
  };

  return (
    <div className={styles['grid-line']}>
      {Object.entries(lineData).map(([key, data], index) => (
        <div
          key={index}
          className={`${styles['grid-item']} ${key === 'actions' ? styles['actions'] : ''}`}
          onClick={key === 'actions' ? handleActionClick : handleCellClick}
        >
          {data || t('general.n/a')}
        </div>
      ))}
    </div>
  );
};

export default TableLine;
