import { FC, useMemo } from 'react';

import TextField, {
  Props as TextFieldProps,
} from 'src/components/Inputs/TextField';

export type SelectOption = {
  value: string;
  label: string;
};

export type SelectOptions = Array<SelectOption>;

type Props = {
  options: SelectOptions;
  value: string | undefined;
  onSelect: (value: string) => void;
  useBlankOption?: boolean;
  label?: TextFieldProps['label'];
  theme?: TextFieldProps['theme'];
  size?: TextFieldProps['size'];
};

const Select: FC<Props> = ({
  label,
  options,
  value,
  onSelect,
  useBlankOption,
  theme,
  size,
}) => {
  const selectOptions = useMemo<SelectOptions>(
    () => [...(useBlankOption ? [{ label: '-', value: '' }] : []), ...options],
    [options, useBlankOption],
  );

  return (
    <TextField
      select
      label={label}
      value={value || ''}
      selectOptions={selectOptions}
      onChange={onSelect}
      theme={theme}
      size={size || 'medium'}
      sx={{ minWidth: '100px' }}
    />
  );
};

export default Select;
