import { FC } from 'react';

import PageContainer from 'src/components/PageContainer';

import PaymentCanceled from 'src/features/pages/private/PaymentCanceled';

const PaymentCanceledPage: FC = () => {
  return (
    <PageContainer>
      <PaymentCanceled />
    </PageContainer>
  );
};

export default PaymentCanceledPage;
