import { FC } from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from 'src/components/PageContainer';

import GameInstanceDetail from 'src/features/components/game/GameInstanceDetail';

type PageParams = {
  instanceId: string;
};

const GameInstanceDetailPage: FC = () => {
  const { instanceId } = useParams<PageParams>();

  return (
    <PageContainer>
      <GameInstanceDetail gameInstanceId={instanceId} />
    </PageContainer>
  );
};

export default GameInstanceDetailPage;
