import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'src/types/dao/user.types';
import { StoreFetchStatus } from 'src/types/store/slice.types';

type UserState = {
  data: User | undefined;
  selectedUser: {
    instance: User | null;
  };
  status: StoreFetchStatus;
  error?: string;
};

const initialState: UserState = {
  data: undefined,
  selectedUser: {
    instance: null,
  },
  status: StoreFetchStatus.IDLE,
  error: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile(
      state: UserState,
      action: { payload: UserState['data']; type: string },
    ) {
      state.data = action.payload;
    },
    setSelectedUserInstance(state, action: PayloadAction<User | null>) {
      state.selectedUser.instance = action.payload;
    },
  },
});

export const { setUserProfile, setSelectedUserInstance } = userSlice.actions;
export default userSlice.reducer;
