import { GetAgentResponse } from 'src/types/api/agent.types';
import { AgentStatus } from 'src/types/dao/agent.types';

const getAgentMock: GetAgentResponse = {
  id: 'uuid1',
  name: 'Test 1',
  inner_state: {
    age: '30',
  },
  memory: {
    long_term_memory: '',
  },
  avatar_url: 'https://models.readyplayer.me/64b068e6dd9942dce7fa044c.glb',
  status: AgentStatus.PRIVATE,
  owner_id: 'uuid',
  admin_snapshot: { name: '' },
  safety_analysis: { name: '' },
  image_url: '',
  thumbnail_url: '',
  animation_url: '',
  minecraft_skin_url: '',
};

export default getAgentMock;
