import { createSlice } from '@reduxjs/toolkit';

import { AppSliceState } from 'src/types/app.types';
import { StoreFetchStatus } from 'src/types/store/slice.types';

const initialState: AppSliceState = {
  data: {
    isLoading: false,
    headerHeight: 0,
  },
  status: StoreFetchStatus.IDLE,
  error: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading(
      state: AppSliceState,
      action: { payload: AppSliceState['data']['isLoading']; type: string },
    ) {
      state.data = {
        ...state.data,
        isLoading: action.payload,
      };
    },
    setHeaderHeight(
      state: AppSliceState,
      action: { payload: AppSliceState['data']['headerHeight']; type: string },
    ) {
      state.data = {
        ...state.data,
        headerHeight: action.payload,
      };
    },
  },
});

export default appSlice.reducer;
