import { Divider, MenuListProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import classNames from 'classnames';
import {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import styles from './styles.module.scss';

export type DropdownArrayItem = {
  label: ReactNode;
  callback?: () => void;
  disabled?: boolean;
  divider?: boolean;
  type?: 'info';
};

export type Props = {
  list: Array<DropdownArrayItem>;
  children: ReactNode;
  disabled?: boolean;
};

const transformOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'top' };
const anchorOrigin: PopoverOrigin = { horizontal: 'right', vertical: 'bottom' };
const menuListProps: Partial<MenuListProps> = {
  'aria-labelledby': 'dropdown-button',
};

const Dropdown: FC<Props> = ({ children, list, disabled }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (disabled) {
    return children;
  }

  return (
    <div>
      <div
        className={styles['anchor']}
        id='dropdown-button'
        onClick={handleClick}
        role='presentation'
      >
        {children}
      </div>
      <Menu
        id='dropdown-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={menuListProps}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className={classNames(styles['menu'])}
        slotProps={{
          paper: {
            elevation: 0,
            className: styles.customMenuPaper,
          },
        }}
      >
        {list.map((item, index) => (
          <div key={index} className={styles['menu_wrap']}>
            {item.divider && <Divider />}
            <MenuItem
              id={`item-${index}`}
              disabled={item.disabled}
              className={classNames(styles['item'], {
                [styles['info']]: item.type === 'info',
                [styles['hasActive']]: item.callback,
              })}
              {...(item.type !== 'info' && {
                onClick: () => {
                  handleClose();
                  item.callback && item.callback();
                },
              })}
            >
              {item.label}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </div>
  );
};

export default Dropdown;
