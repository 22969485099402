import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { useApiAdminGetInstanceById } from 'src/api/agent/hooks/useApiAdminGet';

import NothingToShow from 'src/components/NothingToShow';

import {
  AgentInstanceCheckpoint,
  InstanceResponse,
} from 'src/types/dao/agent.types';

import CheckpointColumn from './components/CheckpointColumn';
import styles from './styles.module.scss';

interface AgentCheckpointsDisplayProps {
  instances?: AgentInstanceCheckpoint[];
}

const AgentCheckpointsDisplay: React.FC<AgentCheckpointsDisplayProps> = ({
  instances,
}) => {
  const [agentsData, setAgentsData] = useState<
    Record<string, InstanceResponse>
  >({});
  const { doRequest: getAgentsById, responseData } =
    useApiAdminGetInstanceById();

  useEffect(() => {
    if (!instances || instances.every((instance) => agentsData[instance.id]))
      return;

    // Only fetch data for instances that haven't been loaded yet
    const missingInstances = instances.filter(
      (instance) => !agentsData[instance.id],
    );

    // Fetch instances sequentially to avoid race conditions
    const fetchAgentData = () => {
      for (const instance of missingInstances) {
        try {
          getAgentsById({ agent_instance_id: instance.id });
        } catch (error) {
          console.error(`Failed to fetch agent ${instance.id}:`, error);
        }
      }
    };

    fetchAgentData();
  }, [instances, getAgentsById, agentsData]);

  // Update agentsData when responseData changes
  useEffect(() => {
    if (responseData) {
      setAgentsData((prev) => ({
        ...prev,
        // @ts-ignore
        [responseData.data.id]: responseData.data,
      }));
    }
  }, [responseData]);

  // Combine agents data with instance status
  const agentsWithStatus = useMemo(
    () =>
      Object.values(agentsData).map((agent) => ({
        ...agent,
        status:
          instances?.find((instance) => instance.id === agent.id)?.status ?? '',
      })),
    [agentsData, instances],
  );

  return (
    <div className={classNames(styles['agent-checkpoints-display'])}>
      {agentsWithStatus.length ? (
        <div className={classNames(styles['agent-checkpoints-wrap'])}>
          {agentsWithStatus.map((agent) => (
            <CheckpointColumn key={agent.id} instance={agent} />
          ))}
        </div>
      ) : (
        <NothingToShow />
      )}
    </div>
  );
};

export default AgentCheckpointsDisplay;
