import { UserTierFilter } from 'common-ui';
import { AllFilter } from 'common-ui';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useApiAdminGetInvitations } from 'src/api/invitation/hooks/useApiAdminGet';
import {
  useApiCreateInvitation,
  useApiDeleteInvitation,
  useApiSendInvitations,
} from 'src/api/invitation/hooks/useApiAdminManage';
import { useApiAdminGetUsers } from 'src/api/user/hooks/useApiAdminGet';

import useFormData from 'src/hooks/useFormData';
import useGetAndUpdateUserData from 'src/hooks/useGetAndUpdateUserData';
import usePageLoader from 'src/hooks/usePageLoader';

import {
  InvitationFormData,
  InvitationsFormData,
} from 'src/types/dao/invitation.types';

const initialFormData: InvitationFormData = {
  minutes: 0,
  count: 1,
};
const initialInvitationsFormData: InvitationsFormData = {
  minutes: 0,
  emails: [],
};

export const useComponentProps = () => {
  const [search, setSearch] = useState<string>('');
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [filterInstancesByRunning, setFilterInstancesByRunning] =
    useState<boolean>(false);

  const { responseData: user } = useGetAndUpdateUserData();

  const [invitationModalOpen, setInvitationModalOpen] =
    useState<boolean>(false);

  const [invitationsModalOpen, setInvitationsModalOpen] =
    useState<boolean>(false);

  const { formData, formDataSetters, resetFormData } =
    useFormData<InvitationFormData>({ initialData: initialFormData });

  const [accessTierFilter, setAccessTierFilter] = useState<UserTierFilter>(
    AllFilter.ALL,
  );

  const {
    formData: invitationsFormData,
    formDataSetters: invitationsFormDataSetters,
    resetFormData: resetInvitationsFormData,
  } = useFormData<InvitationsFormData>({
    initialData: initialInvitationsFormData,
  });

  // USERS API
  const {
    doRequest: adminGetUsers,
    isLoading: isUsersLoading,
    responseData: adminUsers,
    nextPage,
    prevPage,
  } = useApiAdminGetUsers();

  const debounceSearch = useMemo(
    () =>
      _.debounce((value: string) => adminGetUsers({ search: value }), 300) as (
        value: string,
      ) => void,
    [adminGetUsers],
  );

  const handleSetSearch = useCallback(
    (value: string) => {
      if (searchOpen) {
        setSearch(value);
        debounceSearch(value);
      }
    },
    [debounceSearch, searchOpen],
  );

  // INVITATIONS API
  const {
    doRequest: adminGetInvitations,
    isLoading: isAdminInvitationsLoading,
    responseData: adminInvitations,
  } = useApiAdminGetInvitations();
  const {
    doRequest: adminCreateInvitation,
    isLoading: isCreateInvitationLoading,
  } = useApiCreateInvitation({
    onSuccess: useCallback(() => {
      adminGetInvitations({});
      setInvitationModalOpen(false);
      resetFormData();
    }, [resetFormData, adminGetInvitations]),
  });
  const {
    doRequest: adminDeleteInvitation,
    isLoading: isInvitationDeleteLoading,
  } = useApiDeleteInvitation({
    onSuccess: useCallback(() => {
      adminGetInvitations({});
    }, [adminGetInvitations]),
  });
  const {
    doRequest: adminSendInvitations,
    isLoading: isSendingInvitationsLoading,
  } = useApiSendInvitations({
    onSuccess: useCallback(() => {
      adminGetInvitations({});
      setInvitationsModalOpen(false);
      resetInvitationsFormData();
    }, [resetInvitationsFormData, adminGetInvitations]),
  });

  const handleAddInvitationClick = useCallback(() => {
    setInvitationModalOpen(true);
  }, []);

  const handleAddInvitation = useCallback(() => {
    adminCreateInvitation({ formData });
  }, [adminCreateInvitation, formData]);

  const handleSendInvitationsClick = useCallback(() => {
    setInvitationsModalOpen(true);
  }, []);

  const handleSendInvitations = useCallback(() => {
    adminSendInvitations({ formData: invitationsFormData });
  }, [adminSendInvitations, invitationsFormData]);

  usePageLoader({ loading: isInvitationDeleteLoading });

  const handleRemoveInvitation = useCallback(
    (id: string) => {
      adminDeleteInvitation({ id });
    },
    [adminDeleteInvitation],
  );

  useEffect(() => {
    loadUsers();
    adminGetInvitations({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usersToShow = useMemo(() => {
    if (adminUsers) {
      return adminUsers.data;
    }
    return [];
  }, [adminUsers]);

  const loadUsers = useCallback(
    (page = 1, accessTier = accessTierFilter) => {
      if (accessTierFilter === AllFilter.ALL) {
        adminGetUsers({}, page);
      } else {
        adminGetUsers({ access_tier: accessTier }, page);
      }
    },
    [adminGetUsers, accessTierFilter],
  );

  useEffect(() => {
    loadUsers(1, accessTierFilter);
    adminGetInvitations({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTierFilter]);

  const loadNext = useCallback(() => {
    if (nextPage) {
      loadUsers(nextPage);
    }
  }, [nextPage, loadUsers]);

  const loadPrev = useCallback(() => {
    if (prevPage) {
      loadUsers(prevPage);
    }
  }, [prevPage, loadUsers]);

  return {
    search,
    setSearch: handleSetSearch,
    searchOpen,
    setSearchOpen,
    user,
    formData,
    formDataSetters,
    isLoading: isUsersLoading,
    isAdminInvitationsLoading,
    adminUsers,
    adminInvitations,
    invitationModalOpen,
    handleAddInvitation,
    handleAddInvitationClick,
    setInvitationModalOpen,
    handleRemoveInvitation,
    isCreateInvitationLoading,
    filterInstancesByRunning,
    setFilterInstancesByRunning,
    handleSendInvitationsClick,
    handleSendInvitations,
    invitationsModalOpen,
    setInvitationsModalOpen,
    invitationsFormData,
    invitationsFormDataSetters,
    isSendingInvitationsLoading,
    accessTierFilter,
    setAccessTierFilter,
    usersToShow,
    loadUsers,
    loadNext,
    loadPrev,
    pageNumber: prevPage ? prevPage + 1 : nextPage ? nextPage - 1 : 1,
    canLoadNext: !!nextPage,
    canLoadPrev: !!prevPage,
  };
};
