import { FC } from 'react';
import { useParams } from 'react-router-dom';

import PageContainer from 'src/components/PageContainer';

import AdminUser from 'src/features/pages/admin/AdminUser';

type PageParams = {
  userId: string;
};

const AdminUserPage: FC = () => {
  const { userId } = useParams<PageParams>();

  return (
    <PageContainer width='xl'>
      <AdminUser id={userId} />
    </PageContainer>
  );
};

export default AdminUserPage;
