import { useCallback, useEffect, useState } from 'react';

import {
  useApiAdminGetAgentCheckpoints,
  useApiAdminGetAgents,
} from 'src/api/agent/hooks/useApiAdminGet';
import {
  useApiAdminAgentSummon,
  useApiAdminGetGameInstances,
} from 'src/api/game/hooks/useApiAdminGet';
import { useApiAdminGetUsers } from 'src/api/user/hooks/useApiAdminGet';

import { Agent, AgentInstanceCheckpoint } from 'src/types/dao/agent.types';
import { GameInstance } from 'src/types/dao/game.types';
import { User } from 'src/types/dao/user.types';

const INVALID_ID = '00000000-0000-0000-0000-000000000000';

type Props = {
  gameInstanceId: string;
};

const useComponentProps = ({ gameInstanceId }: Props) => {
  const [gameInstance, setGameInstance] = useState<GameInstance | null>(null);
  const [agentCheckpoints, setAgentCheckpoints] = useState<
    AgentInstanceCheckpoint[]
  >([]);
  const [checkpointsFetched, setCheckpointsFetched] = useState(false);
  const [agentIds, setAgentIds] = useState<string[]>([]);
  const [agentsInGame, setAgentsInGame] = useState<Agent[]>([]);
  const [playerId, setPlayerId] = useState<string | null>(null);
  const [playerInGame, setPlayerInGame] = useState<User | null>(null);
  const [gameOwnerIds, setGameOwnerIds] = useState<string[]>([]);
  const [gameOwners, setGameOwners] = useState<User[]>([]);

  const { doRequest: getGameInstances, isLoading: isGameInstancesLoading } =
    useApiAdminGetGameInstances({
      onResponse(response) {
        if (
          response.data &&
          response.data.length > 0 &&
          response.data[0].id === gameInstanceId
        ) {
          const instances = response.data;
          const instance = instances.find((i) => i.id === gameInstanceId);
          if (instance) {
            setGameInstance(instance);
            if (instance.initiator_id) {
              setPlayerId(instance.initiator_id);
            }
          }
        }
      },
    });

  const { doRequest: adminGetAgents, isLoading: isAgentLoading } =
    useApiAdminGetAgents({
      onResponse(response) {
        if (response.data && response.data.length > 0) {
          const agents = response.data;
          setAgentsInGame((prev) => {
            const newAgents = agents.filter(
              (agent) => !prev.find((a) => a.id === agent.id),
            );
            return [...prev, ...newAgents];
          });
          // Set the owners of the game
          const agentOwnerIds = agents.map((agent) => agent.owner_id);
          setGameOwnerIds((prev) => {
            const newOwnerIds = agentOwnerIds.filter(
              (id) => !prev.includes(id),
            );
            return [...prev, ...newOwnerIds];
          });
        }
      },
    });

  const {
    doRequest: getAgentCheckpoints,
    isLoading: isAgentCheckpointsLoading,
  } = useApiAdminGetAgentCheckpoints({
    onResponse(response) {
      const agentCheckpoints = response.data;
      setAgentCheckpoints(agentCheckpoints);
      setCheckpointsFetched(true);
      if (agentCheckpoints.length > 0) {
        const missingAgentIds = agentCheckpoints
          .map((checkpoint) => checkpoint.proto_id)
          .filter((id) => !agentIds.includes(id));
        if (missingAgentIds.length > 0) {
          setAgentIds((prev) => [...prev, ...missingAgentIds]);
        }
      }
    },
  });

  const { doRequest: getUsersByIds, isLoading: isUsersLoading } =
    useApiAdminGetUsers({
      onResponse(response) {
        if (response.data && response.data.length > 0) {
          // Find the player in the game
          const fetchedUsers = response.data;
          const player = fetchedUsers.find((user) => user.id === playerId);
          if (player) {
            setPlayerInGame(player);
          }
          // Find the owners of the game
          const owners = fetchedUsers.filter((user) =>
            gameOwnerIds.includes(user.id),
          );
          setGameOwners((prev) => {
            const newOwners = owners.filter(
              (owner) => !prev.find((o) => o.id === owner.id),
            );
            return [...prev, ...newOwners];
          });
        }
      },
    });

  const { doRequest: startGame } = useApiAdminAgentSummon();

  useEffect(() => {
    getGameInstances({
      ids: [gameInstanceId],
    });
    getAgentCheckpoints({
      game_instance_id: gameInstanceId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If there are agent information missing, get the agent information
    const missingAgentIds = agentIds
      .filter((id) => !agentsInGame.find((agent) => agent.id === id))
      .filter((id) => id !== INVALID_ID)
      .filter((id) => id !== null && id !== undefined);
    if (missingAgentIds.length > 0) {
      adminGetAgents({
        ids: missingAgentIds,
      });
    }
  }, [agentIds, agentsInGame, adminGetAgents]);

  useEffect(() => {
    if (gameInstance && gameInstance.id && !checkpointsFetched) {
      getAgentCheckpoints({
        game_instance_id: gameInstance.id,
      });
    }
  }, [gameInstance, checkpointsFetched, getAgentCheckpoints]);

  useEffect(() => {
    // If player information is missing, get the player information
    if (playerId && !playerInGame) {
      // Get player info
      getUsersByIds({
        ids: [playerId],
      });
    }
  }, [playerId, playerInGame, getUsersByIds]);

  return {
    gameInstance,
    agentCheckpoints,
    agentsInGame,
    playerInGame,
    gameOwners,
    isLoading:
      isGameInstancesLoading ||
      isAgentCheckpointsLoading ||
      isAgentLoading ||
      isUsersLoading,
    startGame,
  };
};

export default useComponentProps;
