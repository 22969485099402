import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';

import { useApiGetApiKeys } from 'src/api/keys/hooks/useApiGet';
import { useApiCreateApiKey } from 'src/api/keys/hooks/useApiManage';

import useFormData from 'src/hooks/useFormData';

import { ApiKeyFormData } from 'src/types/api/keys.types';

const validationSchema = object({
  name: string().required(),
});

const initialData: ApiKeyFormData = {
  name: '',
};

export const useComponentProps = () => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const { formData, formDataSetters, resetFormData, errors, validate } =
    useFormData<ApiKeyFormData>({
      initialData,
      validationSchema,
    });

  const {
    doRequest: getApiKeys,
    responseData: apiKeys,
    isLoading,
  } = useApiGetApiKeys();

  const { doRequest: createApiKey, isLoading: isCreateApiKeyLoading } =
    useApiCreateApiKey({
      onSuccess: useCallback(() => {
        setCreateModalOpen(false);
        getApiKeys();
        resetFormData();
      }, [getApiKeys, resetFormData]),
    });

  useEffect(() => {
    getApiKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateApiKey = useCallback(() => {
    if (validate()) {
      createApiKey({ formData });
    }
  }, [createApiKey, formData, validate]);

  return {
    apiKeys: apiKeys?.data,
    isLoading,
    getApiKeys,
    createModalOpen,
    setCreateModalOpen,
    handleCreateApiKey,
    isCreateApiKeyLoading,
    formData,
    formDataSetters,
    errors,
  };
};
