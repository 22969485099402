import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Button, { Props as ButtonProps } from 'src/components/Buttons/Button';

import {
  ADMIN_RUNNING_GAME_PLAYS_ROUTE,
  PRICE_ROUTE,
} from 'src/constants/routes';

import useTexts from 'src/hooks/useTexts';

export type Props = Omit<ButtonProps, 'onClick'>;

const CheckoutButton: FC<Props> = ({ ...props }) => {
  const { t } = useTexts();
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(PRICE_ROUTE);
  }, [history]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('paymentSuccess')) {
      enqueueSnackbar(t('payment.payment_success'), { variant: 'success' });
      history.push(ADMIN_RUNNING_GAME_PLAYS_ROUTE);
    }
  }, [enqueueSnackbar, history, t]);

  return (
    <Button {...props} onClick={onClick} variant='outlined'>
      {t('payment.get_more')}
    </Button>
  );
};

export default CheckoutButton;
