import React, { FC, useRef } from 'react';

import {
  STRIPE_PRICING_TABLE_ID,
  STRIPE_PUBLISHABLE_KEY,
} from 'src/constants/app';

import styles from './styles.module.scss';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const StripePricingTable: FC = () => {
  const ref = useRef<HTMLElement | null>(null);

  return (
    <div className={styles['wrapper']}>
      {!!(STRIPE_PRICING_TABLE_ID && STRIPE_PUBLISHABLE_KEY) && (
        <stripe-pricing-table
          ref={ref}
          pricing-table-id={STRIPE_PRICING_TABLE_ID}
          publishable-key={STRIPE_PUBLISHABLE_KEY}
        />
      )}
    </div>
  );
};

export default StripePricingTable;
