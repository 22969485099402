import classNames from 'classnames';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { LOGO_LOCATION } from 'src/constants/app';
import { TABLET_BREAKPOINT } from 'src/constants/breakpoints';
import {
  ADMIN_COMPLETED_GAME_PLAYS_ROUTE,
  ADMIN_INVITATION_ROUTE,
  ADMIN_RUNNING_GAME_PLAYS_ROUTE,
  ADMIN_USERS_ROUTE,
  AGENTS_ROUTE,
} from 'src/constants/routes';

import useTexts from 'src/hooks/useTexts';
import useWindowSize from 'src/hooks/useWindowSize';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import PageSelector from '../PageSelector';
import UserNavbarContent from './components/UserNavbarContent';
import styles from './styles.module.scss';

export type PageKey =
  | 'runningGamePlays'
  | 'completedGamePlays'
  | 'users'
  | 'invitationCodes'
  | 'agents'
  | 'branches';

type Props = {
  className?: string;
};

const Navbar = forwardRef<HTMLDivElement, Props>(({ className }, ref) => {
  const [currentPage, setCurrentPage] = useState<PageKey>('runningGamePlays');
  const { t } = useTexts();

  const windowSize = useWindowSize();
  const location = useLocation();

  const currentUser = useAppSelector(getCurrentUser);
  const isGamePlaysLinkActive = useMemo<boolean>(
    () => location.pathname.includes(ADMIN_RUNNING_GAME_PLAYS_ROUTE),
    [location],
  );

  const pagesNavList: Array<{ key: PageKey; label: string; path: string }> = [
    {
      key: 'runningGamePlays',
      label: t('pages.runningGamePlays'),
      path: ADMIN_RUNNING_GAME_PLAYS_ROUTE,
    },
    {
      key: 'completedGamePlays',
      label: t('pages.completedGamePlays'),
      path: ADMIN_COMPLETED_GAME_PLAYS_ROUTE,
    },
    {
      key: 'users',
      label: t('pages.users'),
      path: ADMIN_USERS_ROUTE,
    },
    {
      key: 'invitationCodes',
      label: t('pages.invitationCodes'),
      path: ADMIN_INVITATION_ROUTE,
    },
    {
      key: 'agents',
      label: t('pages.agents'),
      path: AGENTS_ROUTE,
    },
  ];

  // Track page views with Google Analytics
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location) => {
      ReactGA4.send({
        hitType: 'pageview',
        page: history.location.pathname + location.search,
      });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (location.pathname.includes(ADMIN_RUNNING_GAME_PLAYS_ROUTE)) {
      setCurrentPage('runningGamePlays');
    } else if (location.pathname.includes(ADMIN_COMPLETED_GAME_PLAYS_ROUTE)) {
      setCurrentPage('completedGamePlays');
    } else if (location.pathname.includes(ADMIN_INVITATION_ROUTE)) {
      setCurrentPage('invitationCodes');
    } else if (location.pathname.includes(ADMIN_USERS_ROUTE)) {
      setCurrentPage('users');
    } else if (location.pathname.includes(AGENTS_ROUTE)) {
      setCurrentPage('agents');
    } else if (location.pathname === '/' && currentUser) {
      history.push(ADMIN_RUNNING_GAME_PLAYS_ROUTE);
    }
  }, [location.pathname, currentUser, history]);

  return (
    <div ref={ref} className={classNames(styles['navbar'], className)}>
      <div className={styles['content']}>
        <Link
          to={ADMIN_RUNNING_GAME_PLAYS_ROUTE}
          className={classNames(styles['navbar-logo'], {
            [styles['link-active']]: isGamePlaysLinkActive,
          })}
        >
          <img src={LOGO_LOCATION} alt='logo' />
          <h2>{t('general.company_name')}</h2>
        </Link>
        {currentUser && <UserNavbarContent user={currentUser} />}
      </div>
      {currentUser && windowSize.width < TABLET_BREAKPOINT && (
        <PageSelector currentPageKey={currentPage} list={pagesNavList} />
      )}
    </div>
  );
});

Navbar.displayName = 'Navbar';

export default Navbar;
