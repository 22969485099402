import { FC } from 'react';

import PageContainer from 'src/components/PageContainer';

import Agents from 'src/features/pages/private/Agents';

const AgentsPage: FC = () => {
  return (
    <PageContainer simple width={false}>
      <Agents />
    </PageContainer>
  );
};

export default AgentsPage;
