import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';
import { useListApi } from 'src/services/api/hooks/useListApi';

import {
  GetAgentResponse,
  GetGroupAgentsResponse,
} from 'src/types/api/agent.types';
import { BaseUseApiProps, BaseUseListApiProps } from 'src/types/api/api.types';

import {
  makeApiGetAgent,
  makeApiGetAgentsByGroup,
  makeApiSearchAgent,
} from '../functions/makeApiGet';
import getAgentMock from '../mock/getAgent';
import groupAgentsMock from '../mock/groupAgents';

export const useApiGetAgent = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<GetAgentResponse> = {}) => {
  const doRequest = useMemo(() => makeApiGetAgent(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
    mockData: getAgentMock,
  });
};

export const useApiGetGroupAgents = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseListApiProps<GetGroupAgentsResponse> = {}) => {
  const doRequest = useMemo(() => makeApiGetAgentsByGroup(), []);

  return useListApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
    mockData: groupAgentsMock,
  });
};

export const useApiSearchAgents = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<GetGroupAgentsResponse> = {}) => {
  const doRequest = useMemo(() => makeApiSearchAgent(), []);

  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
    mockData: groupAgentsMock,
  });
};
