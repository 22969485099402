import moment from 'moment';

import { Agent } from 'src/types/dao/agent.types';

export const timestampToDate = (value: number) => new Date(value * 1000);
export const dateToTimestamp = (value: Date) =>
  Math.floor(value.getTime() / 1000);

export const getCurrentTimestamp = () => Date.now() / 1000;

export const dateAddTime = (
  date: Date | number,
  value: number,
  key: 'weeks' | 'days' | 'hours' | 'minutes',
) => new Date(Number(moment(date).add(value, key)));

export const dateToFormat = (date: Date, format: string) =>
  moment(date).format(format);

export function getDifferenceInTime(startDate: Date, endDate = new Date()) {
  startDate = startDate instanceof Date ? startDate : new Date(startDate);
  endDate = endDate instanceof Date ? endDate : new Date(endDate);

  const differenceInMs = endDate.getTime() - startDate.getTime();

  const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  const timeString = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  const output = days > 0 ? `${days} days, ${timeString}` : timeString;

  return output;
}

export const getSortedAgents = (agents: Agent[]): Agent[] => {
  agents.sort((a, b) => {
    if (
      'created_at' in a &&
      'created_at' in b &&
      b.created_at !== undefined &&
      a.created_at !== undefined
    ) {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    } else {
      return (
        (b.created_at?.getTime() || Number.NEGATIVE_INFINITY) -
        (a.created_at?.getTime() || Number.NEGATIVE_INFINITY)
      );
    }
  });
  return agents;
};

export const dateToUnixTimestamp = (date: Date): number =>
  Math.floor(date.getTime() / 1000);
