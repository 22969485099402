import classNames from 'classnames';
import { FC } from 'react';

import useTexts from 'src/hooks/useTexts';

import useGoogleLogin from './hooks/UseGoogleLogin';
const GoogleLogo = require('src/assets/brand_icon/google_icon.svg').default;
import styles from './styles.module.scss';

const Landing: FC = () => {
  const { googleLogin } = useGoogleLogin();
  const { t } = useTexts();

  return (
    <>
      <div className={styles['page']}>
        <div
          className={classNames(styles['content-wrapper'], styles['simple'])}
        >
          <div className={styles['content-border']}>
            <div className={styles['content']}>
              <h1 className={styles['title']}>{t('landing.admin')}</h1>
            </div>
            <div className={styles['google-btn-container']}>
              <button
                className={styles['google-sign-in-btn']}
                onClick={googleLogin}
              >
                <img
                  src={GoogleLogo}
                  alt='Google sign-in'
                  className={styles['google-logo']}
                />
                <span className={styles['btn-title']}>
                  {t('general.sign_in_to_dashboard')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
