import { getTierWeight, UserTier } from 'common-ui';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useApiArchiveAgent,
  useApiCopyAgent,
} from 'src/api/agent/hooks/useApiManage';

import { AGENT_EDIT_ROUTE, AGENT_VIEW_ROUTE } from 'src/constants/routes';

import useTexts from 'src/hooks/useTexts';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import { Agent, AgentStatus } from 'src/types/dao/agent.types';

type Props = {
  agent: Agent;
  onSelect?: (agent: Agent) => void;
  reload?: () => void;
  readonly?: boolean;
};

const useComponentProps = ({ agent, onSelect, readonly, reload }: Props) => {
  const history = useHistory();
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);

  const { t } = useTexts();

  const { doRequest: archiveAgent, isLoading: isArchiveAgentLoading } =
    useApiArchiveAgent({
      onSuccess: useCallback(() => {
        setArchiveModalOpen(false);
        reload && reload();
      }, [reload]),
    });

  const { doRequest: copyAgent, isLoading: isCopyAgentLoading } =
    useApiCopyAgent({
      onSuccess: useCallback(() => {
        setCopyModalOpen(false);
        reload && reload();
      }, [reload]),
    });

  const currentUser = useAppSelector(getCurrentUser);
  const userAccessTier = useMemo<number>(() => {
    return getTierWeight(currentUser?.access_tier);
  }, [currentUser]);

  const isOwner = useMemo<boolean>(() => {
    return currentUser?.id === agent.owner_id;
  }, [currentUser?.id, agent.owner_id]);

  const canEdit = useMemo<boolean>(() => {
    return (
      !readonly &&
      (userAccessTier >= getTierWeight(UserTier.ADMIN) ||
        (userAccessTier >= getTierWeight(UserTier.GAME_EDITOR) && isOwner))
    );
  }, [readonly, userAccessTier, isOwner]);

  const canCopy = useMemo<boolean>(() => {
    return !!reload && userAccessTier >= getTierWeight(UserTier.GAME_EDITOR);
  }, [userAccessTier, reload]);

  const canArchive = useMemo<boolean>(() => {
    return (
      agent.status !== AgentStatus.ARCHIVED &&
      (userAccessTier >= getTierWeight(UserTier.ADMIN) ||
        (userAccessTier >= getTierWeight(UserTier.GAME_EDITOR) && isOwner))
    );
  }, [userAccessTier, isOwner, agent.status]);

  const handleClick = useCallback(() => {
    onSelect && onSelect(agent);
  }, [agent, onSelect]);

  const handleEdit = useCallback(() => {
    history.push(`${AGENT_EDIT_ROUTE}/${agent.id}`);
  }, [agent.id, history]);

  const handleCopy = useCallback(() => {
    setCopyModalOpen(true);
  }, []);

  const onMouseEnter = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
    setMouseOver(true);
  }, []);
  const onMouseLeave = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
    setMouseOver(false);
  }, []);

  const onViewClick = useCallback(() => {
    history.push(`${AGENT_VIEW_ROUTE}/${agent.id}`);
  }, [agent.id, history]);

  return {
    t,
    mouseOver,
    handleClick,
    handleEdit,
    handleCopy,
    onMouseEnter,
    onMouseLeave,
    onViewClick,
    canEdit,
    canCopy,
    canArchive,
    archiveModalOpen,
    setArchiveModalOpen,
    archiveAgent,
    isArchiveAgentLoading,
    copyModalOpen,
    setCopyModalOpen,
    copyAgent,
    isCopyAgentLoading,
  };
};

export default useComponentProps;
