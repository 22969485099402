import { getTierWeight, UserTier } from 'common-ui';
import { AllFilter } from 'common-ui';
import { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import Button from 'src/components/Buttons/Button';
import DatePickerComponent from 'src/components/DatePickerComponent';
import NumberField from 'src/components/Inputs/NumberField';
import Loader from 'src/components/Loader';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import NothingToShow from 'src/components/NothingToShow';
import Pagination from 'src/components/Pagination';
import Select from 'src/components/Select';
import AgentPanel from 'src/components/SidePanels/SidePanel/FeaturedSidePanel/AgentPanel';
import TableList from 'src/components/TableList';

import { ADMIN_USERS_ROUTE } from 'src/constants/routes';

import AgentCheckpointsDisplay from 'src/features/components/agent/AgentInstance';
import CompletedGameInstances from 'src/features/components/game/CompletedGameInstances';
import RunningGameInstances from 'src/features/components/game/RunningGameInstances';
import CheckoutButton from 'src/features/components/payment/CheckoutButton';
import UserBox from 'src/features/components/user/UserBox';

import useTexts from 'src/hooks/useTexts';

import { useAppDispatch } from 'src/store/hooks';
import { setSelectedAgentInstance } from 'src/store/reducers/agentSlice';

import {
  Agent,
  AgentStatus,
  AgentStatusFilter,
} from 'src/types/dao/agent.types';
import { InvitationData } from 'src/types/dao/invitation.types';

import {
  renderAgentCell,
  renderInvitationCell,
  tableAgentHeaders,
  tableInvitationHeaders,
} from './common';
import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

export type Props = {
  id: string;
};

type TextGetter = (key: string) => string | undefined;

export type AgentTableActionConfig = {
  canEdit: boolean;
  handleEdit: () => void;
  canCopy: boolean;
  handleCopy: () => void;
  canArchive: boolean;
  handleArchive: () => void;
  t: TextGetter;
};

const AdminUser: FC<Props> = ({ id }) => {
  const { t } = useTexts();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [selectedAgentForAction, setSelectedAgentForAction] =
    useState<Agent | null>(null);
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);

  const {
    invitationsSubmittedBy,
    invitationsCreatedBy,
    isInvitationsSubmittedByLoading,
    isInvitationsCreatedByLoading,
    invitationModalOpen,
    setInvitationModalOpen,
    handleSendInvitationClick,
    handleSendInvitation,
    formData,
    formDataSetters,
    currentUser,
    accessTierModalOpen,
    setAccessTierModalOpen,
    handleUpdateAccessTierClick,
    handleUpdateAccessTier,
    currentUserAccessTier,
    isLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    agentStatusFilter,
    setAgentStatusFilter,
    agentsToShow,
    loadNextAgent,
    loadPrevAgent,
    canLoadNext,
    canLoadPrev,
    pageNumber,
    canEdit,
    handleEdit,
    canCopy,
    copyModalOpen,
    setCopyModalOpen,
    copyAgent,
    isCopyAgentLoading,
    canArchive,
    archiveModalOpen,
    archiveAgent,
    isArchiveAgentLoading,
    setArchiveModalOpen,
    startAgentDate,
    setStartAgentDate,
    endAgentDate,
    setEndAgentDate,
    instances,
  } = useComponentProps({ id, activeAgent: selectedAgentForAction });

  const roleOptions = Object.values(UserTier)
    .filter((key) => getTierWeight(key) < getTierWeight(UserTier.ADMIN))
    .map((key) => ({
      value: key,
      label: t('user.' + key),
    }));

  const isAdmin = useMemo<boolean>(() => {
    return (
      (currentUser &&
        getTierWeight(currentUser.access_tier) >=
          getTierWeight(UserTier.ADMIN)) ||
      false
    );
  }, [currentUser]);

  const agentStatusOptions = [
    ...Object.values(AllFilter),
    ...Object.values(AgentStatus),
  ].map((key) => ({
    value: key,
    label: t('general.' + key),
  }));

  const handleActionClick = (agent: Agent) => {
    setSelectedAgentForAction(agent);
  };

  const tableActionConfig: AgentTableActionConfig = {
    canEdit,
    handleEdit,
    canCopy,
    handleCopy: () => setCopyModalOpen(true),
    canArchive,
    handleArchive: () => setArchiveModalOpen(true),
    t,
  };

  const onCloseSidePanel = () => {
    setShowSidePanel(false);
    dispatch(setSelectedAgentInstance(null));
    history.push(ADMIN_USERS_ROUTE);
  };

  const handleAgentInfoClick = async (agent: Agent) => {
    await dispatch(setSelectedAgentInstance(agent));
    history.push(`${ADMIN_USERS_ROUTE}/agent/${agent.id}`);
    setShowSidePanel(true);
  };

  if (!currentUser) return null;

  return (
    <div className={styles['wrapper']}>
      <div className={styles['data-container']}>
        <h2>{t('user.profile')}:</h2>
        <DatePickerComponent
          start={startDate}
          end={endDate}
          setStart={setStartDate}
          setEnd={setEndDate}
        />
        {currentUser && (
          <div className={styles['section']}>
            {!isAdmin && (
              <>
                <div className={styles['profile-role-dropdown']}>
                  <Select
                    label={t('user.access_tier')}
                    options={roleOptions}
                    value={currentUser.access_tier}
                    onSelect={handleUpdateAccessTierClick}
                    theme='white'
                  />
                </div>
                <Button onClick={handleSendInvitationClick}>
                  {t('invitation.send_invitation')}
                </Button>
              </>
            )}
            <div className={styles['profile-container']}>
              <UserBox data={currentUser} />
            </div>
          </div>
        )}
      </div>

      <div className={styles['data-container']}>
        <RunningGameInstances
          filters={{ userId: id }}
          fromAdminUser={true}
          startTimeFromAdmin={startDate}
          endTimeFromAdmin={endDate}
        />
      </div>

      <div className={styles['data-container']}>
        <CompletedGameInstances
          filters={{ userId: id }}
          fromAdminUser={true}
          startTimeFromAdmin={startDate}
          endTimeFromAdmin={endDate}
        />
      </div>

      <div className={styles['user-data-container']}>
        <div className={styles['section']}>
          <h2>{t('agent.agents')}:</h2>
          <div className={styles['filter-wrapper']}>
            <p>{t('general.filter_by')}:</p>
            <Select
              label={t('agent.status')}
              options={agentStatusOptions}
              value={agentStatusFilter}
              onSelect={(value: string) =>
                setAgentStatusFilter(value as AgentStatusFilter)
              }
              theme='white'
              size='small'
            />
          </div>
          <div className={styles['filters-date-picker']}>
            <DatePickerComponent
              start={startAgentDate}
              end={endAgentDate}
              setStart={setStartAgentDate}
              setEnd={setEndAgentDate}
            />
          </div>
          {agentsToShow.length ? (
            <>
              <TableList<Agent, AgentTableActionConfig>
                data={agentsToShow}
                headers={tableAgentHeaders}
                renderCell={renderAgentCell}
                onActionClick={handleActionClick}
                onCellClick={handleAgentInfoClick}
                tableActionConfig={tableActionConfig}
                className={styles['table-wrapper']}
              />
              <div className={styles['pagination-wrapper']}>
                <Pagination
                  loadNextPage={loadNextAgent}
                  loadPrevPage={loadPrevAgent}
                  pageNumber={pageNumber}
                  canLoadNext={canLoadNext}
                  canLoadPrev={canLoadPrev}
                />
              </div>
            </>
          ) : (
            <NothingToShow />
          )}
        </div>
      </div>

      <div className={styles['user-data-container']}>
        <div className={styles['section']}>
          <h2 className={styles['title']}>{t('user.agent_instances')}:</h2>
          <AgentCheckpointsDisplay instances={instances} />
        </div>
      </div>

      <div className={styles['user-data-container']}>
        <div className={styles['section']}>
          <h2 className={styles['title']}>
            {t('invitation.invitation_codes_submitted')}:
          </h2>
          {isInvitationsSubmittedByLoading ? (
            <Loader />
          ) : (
            <div>
              {invitationsSubmittedBy?.length ? (
                <TableList<InvitationData, null>
                  headers={tableInvitationHeaders}
                  data={invitationsSubmittedBy}
                  renderCell={(item) => renderInvitationCell(item)}
                />
              ) : (
                <NothingToShow />
              )}
            </div>
          )}
        </div>
      </div>

      <div className={styles['user-data-container']}>
        <div className={styles['section']}>
          <h2 className={styles['title']}>
            {t('invitation.invitation_codes_created')}:
          </h2>
          {isInvitationsCreatedByLoading ? (
            <Loader />
          ) : (
            <div>
              {invitationsCreatedBy?.length ? (
                <TableList<InvitationData, null>
                  headers={tableInvitationHeaders}
                  data={invitationsCreatedBy}
                  renderCell={(item) => renderInvitationCell(item)}
                />
              ) : (
                <NothingToShow />
              )}
            </div>
          )}
        </div>
      </div>

      {invitationModalOpen &&
        (!currentUser ? (
          <Loader fullPage />
        ) : (
          <ConfirmModal
            setOpen={setInvitationModalOpen}
            title={t(
              'invitation.send_invitation_code_to_user_email_with_following_play_minutes',
            )}
            onConfirm={handleSendInvitation}
            confirmButtonLabel={t('form.send')}
            isConfirmLoading={isLoading}
            size='sm'
          >
            <div>
              <p className={styles['modal-desc']}>
                {t('invitation.remaining_play_minutes')}: {currentUser.minutes}
              </p>
              {!currentUser.minutes && (
                <CheckoutButton wrapperClassName={styles['checkout-btn']} />
              )}
              <NumberField
                value={formData.minutes}
                onChange={formDataSetters.minutes}
                wrapperClassName={styles['minutes-input']}
                label={t('invitation.play_minutes')}
                theme='black'
              />
            </div>
          </ConfirmModal>
        ))}

      {accessTierModalOpen &&
        (!currentUser ? (
          <Loader fullPage />
        ) : (
          <ConfirmModal
            setOpen={setAccessTierModalOpen}
            title={t('user.update_access_tier')}
            onConfirm={handleUpdateAccessTier}
            confirmButtonLabel={t('form.save')}
            isConfirmLoading={isLoading}
            size='sm'
          >
            <p className={styles['modal-desc']}>
              {t('user.access_tier_after_updating')}:{' '}
              {t('user.' + currentUserAccessTier)}
            </p>
          </ConfirmModal>
        ))}
      {copyModalOpen && selectedAgentForAction && (
        <ConfirmModal
          setOpen={setCopyModalOpen}
          title={t('agent.copy_agent')}
          onConfirm={() => copyAgent({ agentId: selectedAgentForAction.id })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isCopyAgentLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('agent.copy_agent_desc')}
          </p>
        </ConfirmModal>
      )}
      {archiveModalOpen && selectedAgentForAction && (
        <ConfirmModal
          setOpen={setArchiveModalOpen}
          title={t('agent.archive_agent')}
          onConfirm={() => archiveAgent({ agentId: selectedAgentForAction.id })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isArchiveAgentLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('agent.archive_agent_desc')}
          </p>
        </ConfirmModal>
      )}
      <AgentPanel onPanelClose={onCloseSidePanel} isOpen={showSidePanel} />
    </div>
  );
};

export default AdminUser;
