import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Agent } from 'src/types/dao/agent.types';

type AgentState = {
  selectedAgent: {
    instance: Agent | null;
  };
};

const initialState: AgentState = {
  selectedAgent: {
    instance: null,
  },
};

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setSelectedAgentInstance(state, action: PayloadAction<Agent | null>) {
      state.selectedAgent.instance = action.payload;
    },
  },
});

export const { setSelectedAgentInstance } = agentSlice.actions;
export default agentSlice.reducer;
