import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import React, { FC, useEffect, useState } from 'react';

import DatePickerComponent from 'src/components/DatePickerComponent';
import Loader from 'src/components/Loader';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import NothingToShow from 'src/components/NothingToShow';
import Pagination from 'src/components/Pagination';
import TableList from 'src/components/TableList';

import { GameCellData, setGameTableData } from 'src/helpers/games';

import useTexts from 'src/hooks/useTexts';

import { renderGameCell, tableHeaders } from './common';
import useComponentProps from './hooks/useComponentProps';
import styles from './styles.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export type GameInstancesProps = {
  additionalClassName?: string;
  customLoader?: React.ReactNode;
  fromAdminUser?: boolean;
  startTimeFromAdmin?: Dayjs;
  endTimeFromAdmin?: Dayjs;
  filters?: {
    userId?: string;
  };
};

type TextGetter = (key: string) => string | undefined;

export type GameTableActionConfig = {
  redirectToGame: (gameId: string) => void;
  stopGame?: (gameId: string) => void;
  downloadInstanceLog: (gameId: string) => void;
  copyGameInstanceId: (gameId: string) => void;
  t: TextGetter;
};

const RunningGameInstances: FC<GameInstancesProps> = ({
  additionalClassName,
  customLoader,
  filters,
  fromAdminUser,
  startTimeFromAdmin,
  endTimeFromAdmin,
}) => {
  const { t } = useTexts();
  const [sessionLengthHistogram, setSessionLengthHistogram] = useState<
    number[]
  >([]);
  const {
    runningGameInstances,
    users,
    isLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    stopGameInstance,
    stopModalOpen,
    setStopModalOpen,
    redirectToGame,
    stopGame,
    downloadInstanceLog,
    copyGameInstanceId,
    activeGameId,
    loadNextRunningPage,
    loadPrevRunningPage,
    runningGamePage,
    canLoadNextRunning,
    canLoadPrevRunning,
  } = useComponentProps({ ...filters });

  useEffect(() => {
    if (runningGameInstances.length > 0) {
      const calculateSessionLengthHistogram = () => {
        const histogram = new Array(61).fill(0); // 0-59 minutes, plus 60+ bucket
        runningGameInstances.forEach((game) => {
          const start = new Date(game.start_time).getTime();
          const end = new Date(game.end_time).getTime();
          const durationMinutes = Math.floor((end - start) / (1000 * 60));

          if (durationMinutes >= 60) {
            histogram[60]++;
          } else {
            histogram[durationMinutes]++;
          }
        });
        setSessionLengthHistogram(histogram);
      };
      calculateSessionLengthHistogram();
    }
  }, [runningGameInstances]);

  useEffect(() => {
    if (startTimeFromAdmin && endTimeFromAdmin) {
      setStartDate(startTimeFromAdmin);
      setEndDate(endTimeFromAdmin);
    }
  }, [startTimeFromAdmin, endTimeFromAdmin, setStartDate, setEndDate]);

  const gameTableData: GameCellData[] = setGameTableData(
    runningGameInstances,
    users,
    true,
  );

  const tableActionConfig: GameTableActionConfig = {
    redirectToGame: (id) => redirectToGame(id),
    stopGame: stopGame,
    downloadInstanceLog: (id) => {
      downloadInstanceLog(id);
    },
    copyGameInstanceId: (id) => {
      copyGameInstanceId(id);
    },
    t,
  };

  return (
    <div className={additionalClassName}>
      <div className={styles['games-header-wrapper']}>
        <h2 className={styles['games-header-wrapper_title']}>
          {t('game.game_instances')}:
        </h2>
        <div className={styles['games-header-wrapper_filters']}>
          {!fromAdminUser && (
            <DatePickerComponent
              start={startDate}
              end={endDate}
              setStart={setStartDate}
              setEnd={setEndDate}
            />
          )}
        </div>
      </div>

      <div
        className={classNames(styles['running-games-wrapper'], {
          [styles['has-border']]: !runningGameInstances.length,
        })}
      >
        <h3>{t('game.running_games')}:</h3>
        {isLoading ? (
          customLoader || <Loader />
        ) : runningGameInstances.length ? (
          <div className={styles['instances-wrapper']}>
            <div className={styles['instances']}>
              <TableList<GameCellData, GameTableActionConfig>
                headers={tableHeaders}
                data={gameTableData}
                renderCell={renderGameCell}
                tableActionConfig={tableActionConfig}
              />
            </div>
            <div>
              <Pagination
                loadNextPage={loadNextRunningPage}
                loadPrevPage={loadPrevRunningPage}
                pageNumber={runningGamePage}
                canLoadNext={canLoadNextRunning}
                canLoadPrev={canLoadPrevRunning}
              />
            </div>
          </div>
        ) : (
          <NothingToShow />
        )}
      </div>

      {stopModalOpen && (
        <ConfirmModal
          setOpen={setStopModalOpen}
          title={t('game.stop_instance')}
          onConfirm={() => stopGameInstance({ gameInstanceId: activeGameId })}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isLoading}
          size='sm'
        >
          <p className={styles['modal-subtitle']}>
            {t('game.stop_instance_desc')}
          </p>
        </ConfirmModal>
      )}
    </div>
  );
};

export default RunningGameInstances;
