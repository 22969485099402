import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import classNames from 'classnames';
import { FC, useMemo } from 'react';

import TextField, {
  Props as TextFieldProps,
} from 'src/components/Inputs/TextField';

import styles from './styles.module.scss';

export type Props = TextFieldProps & {
  closeIcon?: boolean;
  onIconClick?: () => void;
};

const SearchField: FC<Props> = ({ closeIcon, onIconClick, ...props }) => {
  const Icon = useMemo(() => (closeIcon ? CloseIcon : SearchIcon), [closeIcon]);

  return (
    <TextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Icon
              className={classNames(styles['icon'], {
                [styles['clickable']]: onIconClick,
              })}
              onClick={onIconClick}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
