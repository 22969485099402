import React, { useEffect, useState } from 'react';

import styles from '../../styles.module.scss';

interface HistogramProps {
  sessionLengthHistogram: number[];
}

const Histogram: React.FC<HistogramProps> = ({ sessionLengthHistogram }) => {
  const [histogramValue, setHistogramValue] = useState(sessionLengthHistogram);
  const maxHistogramValue = Math.max(...histogramValue);

  useEffect(() => {
    setHistogramValue(sessionLengthHistogram);
  }, [sessionLengthHistogram]);

  return (
    <div className={styles['histogram-wrapper']}>
      {histogramValue.map((count, index) => {
        const barHeight =
          maxHistogramValue > 0 ? (count / maxHistogramValue) * 250 : 0;

        return (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '0 2px',
            }}
          >
            <div
              style={{
                width: '10px',
                height: `${barHeight}px`,
                backgroundColor: '#4CAF50',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                color: 'white',
                fontSize: '8px',
              }}
            >
              {count > 0 ? count : ''}
            </div>
            {index % 5 === 0 && (
              <div
                style={{
                  marginTop: '5px',
                  textAlign: 'center',
                  fontSize: '10px',
                }}
              >
                {index === 60 ? '60+' : index}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Histogram;
