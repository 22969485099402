import { UserTier } from 'common-ui';
import { FC, ReactNode } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import {
  ADMIN_CHATS_ROUTE,
  ADMIN_COMPLETED_GAME_PLAYS_ROUTE,
  ADMIN_GAME_INSTANCE_ROUTE,
  ADMIN_INVITATION_ROUTE,
  ADMIN_RUNNING_GAME_PLAYS_ROUTE,
  ADMIN_USER_ROUTE,
  ADMIN_USERS_ROUTE,
  AGENT_CREATE_ROUTE,
  AGENT_EDIT_ROUTE,
  AGENT_VIEW_ROUTE,
  AGENTS_ROUTE,
  APP_ROUTE,
  GOOGLE_LOGIN_ROUTE,
  PAYMENT_CANCELED_ROUTE,
  PRICE_ROUTE,
  SETTINGS_ROUTE,
} from 'src/constants/routes';

import GameInstanceDetailPage from 'src/pages/admin/GameInstance';
import AdminUserPage from 'src/pages/admin/User';
import AgentsPage from 'src/pages/private/AgentsPage';
import ChatsPage from 'src/pages/private/ChatsPage';
import CompletedGamePlaysPage from 'src/pages/private/CompletedGamePlaysPage';
import CreateAgentPage from 'src/pages/private/CreateAgent';
import EditAgentPage from 'src/pages/private/EditAgent';
import InvitationCodesPage from 'src/pages/private/InvintationCodesPage';
import PaymentCanceledPage from 'src/pages/private/PaymentCanceled';
import PricePage from 'src/pages/private/Price';
import RunningGamePlaysPage from 'src/pages/private/RunningGamePlaysPage';
import SettingsPage from 'src/pages/private/SettingsPage';
import UsersPage from 'src/pages/private/Users';
import ViewAgentPage from 'src/pages/private/ViewAgent';
import GoogleLoginPage from 'src/pages/public/GoogleLogin';
import LandingPage from 'src/pages/public/LandingPage';

import AppRoute from './Route/AppRoute';
import PrivateRoute from './Route/PrivateRoute';

type AppRoutesProps = {
  isValid: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  accessTier?: UserTier;
};

const AppRoutes: FC<AppRoutesProps> = ({
  isValid,
  isLoading,
  isLoaded,
  accessTier,
}) => {
  const createPrivateRoute = (
    path: string,
    requiredAccessTier: UserTier,
    component: ReactNode,
    redirectPath?: string,
  ) => (
    <PrivateRoute
      path={path}
      requiredAccessTier={requiredAccessTier}
      {...{ isValid, isLoading, isLoaded, accessTier }}
      redirectPath={redirectPath}
      component={component}
    />
  );

  return (
    <Switch>
      {/* PUBLIC PAGES */}
      <AppRoute
        exact
        path={GOOGLE_LOGIN_ROUTE}
        component={<GoogleLoginPage />}
      />
      <AppRoute exact path={APP_ROUTE} component={<LandingPage />} />
      {/* USER PRIVATE PAGES */}
      {createPrivateRoute(SETTINGS_ROUTE, UserTier.REGULAR, <SettingsPage />)}
      {createPrivateRoute(AGENTS_ROUTE, UserTier.REGULAR, <AgentsPage />)}

      {/* ADMIN PAGES */}
      {/* Need to split the admin pages into different tiers */}
      {createPrivateRoute(
        ADMIN_RUNNING_GAME_PLAYS_ROUTE,
        UserTier.ADMIN,
        <RunningGamePlaysPage />,
      )}
      {createPrivateRoute(
        ADMIN_COMPLETED_GAME_PLAYS_ROUTE,
        UserTier.ADMIN,
        <CompletedGamePlaysPage />,
      )}
      {createPrivateRoute(
        ADMIN_INVITATION_ROUTE,
        UserTier.ADMIN,
        <InvitationCodesPage />,
      )}
      {createPrivateRoute(ADMIN_USERS_ROUTE, UserTier.ADMIN, <UsersPage />)}

      {createPrivateRoute(
        ADMIN_USER_ROUTE + '/:userId',
        UserTier.ADMIN,
        <AdminUserPage />,
      )}
      {/* GAME PAGES */}
      {createPrivateRoute(
        ADMIN_GAME_INSTANCE_ROUTE + '/:instanceId',
        UserTier.ADMIN,
        <GameInstanceDetailPage />,
      )}
      {/* AGENT PAGES */}
      {createPrivateRoute(
        AGENT_CREATE_ROUTE,
        UserTier.GAME_EDITOR,
        <CreateAgentPage />,
        AGENTS_ROUTE,
      )}
      {createPrivateRoute(
        AGENT_EDIT_ROUTE + '/:id',
        UserTier.GAME_EDITOR,
        <EditAgentPage />,
        AGENT_VIEW_ROUTE + '/:id',
      )}
      {createPrivateRoute(
        AGENT_VIEW_ROUTE + '/:id',
        UserTier.REGULAR,
        <ViewAgentPage />,
      )}

      {/* CHAT PAGES */}
      {createPrivateRoute(ADMIN_CHATS_ROUTE, UserTier.ADMIN, <ChatsPage />)}

      {/* PAYMENT PAGES */}
      {createPrivateRoute(
        PAYMENT_CANCELED_ROUTE,
        UserTier.REGULAR,
        <PaymentCanceledPage />,
      )}
      {createPrivateRoute(PRICE_ROUTE, UserTier.ADMIN, <PricePage />)}

      <AppRoute
        path='*'
        component={<Redirect to={ADMIN_RUNNING_GAME_PLAYS_ROUTE} />}
      />
    </Switch>
  );
};

export default AppRoutes;
