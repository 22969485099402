import classNames from 'classnames';
import React, { FC, SyntheticEvent, useCallback, useMemo, useRef } from 'react';

import useTexts from 'src/hooks/useTexts';

import styles from './styles.module.scss';

const defaultImage = require('src/assets/images/default_game_display.jpg');
const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB

export type Props = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  changed?: boolean;
  readonly?: boolean;
  acceptedFileTypes: 'image' | 'video' | 'both';
  title?: string;
};

const UploadImageVideoField: FC<Props> = ({
  value,
  onChange,
  className,
  changed,
  readonly,
  acceptedFileTypes,
  title,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTexts();

  const imageSrc = useMemo(() => value || defaultImage, [value]);

  const onLoadImageError = useCallback(
    (event: SyntheticEvent<HTMLImageElement>) => {
      (event.target as HTMLImageElement).src = defaultImage;
    },
    [],
  );

  const getAcceptString = () => {
    switch (acceptedFileTypes) {
      case 'image':
        return 'image/*';
      case 'video':
        return 'video/mp4';
      case 'both':
        return 'image/*,video/mp4';
      default:
        return 'image/*';
    }
  };

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      const reader = new FileReader();

      if (files && files[0]) {
        if (files[0].size > MAX_FILE_SIZE) {
          alert(t('file_size_too_large'));
          return;
        }

        if (files[0].type.startsWith('image/')) {
          const image = new Image();
          image.src = URL.createObjectURL(files[0]);
          image.onload = () => {
            reader.onloadend = () => {
              onChange(reader.result as string);
            };
            reader.readAsDataURL(files[0]);
          };
        } else if (files[0].type === 'video/mp4') {
          reader.onloadend = () => {
            onChange(reader.result as string);
          };
          reader.readAsDataURL(files[0]);
        }
      }
    },
    [onChange, t],
  );

  return (
    <div className={styles['upload-field-container']}>
      {title && <div className={styles['upload-field-title']}>{title}</div>}
      {(acceptedFileTypes !== 'video' || !value) && (
        <img
          src={imageSrc}
          onError={onLoadImageError}
          alt='img'
          className={classNames(className, styles['field-wrapper'], {
            [styles['updatable']]: !readonly,
            [styles['changed']]: changed,
          })}
          onClick={() => !readonly && fileInputRef.current?.click()}
          role='presentation'
        />
      )}
      {acceptedFileTypes === 'video' && value && (
        <video
          src={value}
          className={classNames(className, styles['field-wrapper'], {
            [styles['updatable']]: !readonly,
            [styles['changed']]: changed,
          })}
          onClick={() => !readonly && fileInputRef.current?.click()}
          autoPlay
          muted
          loop
        >
          <track kind='captions' src='' label={t('captions_label')} />
          {t('video_not_supported')}
        </video>
      )}
      <input
        ref={fileInputRef}
        type='file'
        accept={getAcceptString()}
        onChange={onFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default UploadImageVideoField;
