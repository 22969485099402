export const avatarUrls: Array<string> = [
  'https://models.readyplayer.me/64d6d853cfdd0f000df20a36.glb',
  'https://models.readyplayer.me/64d6e372c603b299c0f8aa7a.glb',
  'https://models.readyplayer.me/64d6e440cfdd0f000df21869.glb',
  'https://models.readyplayer.me/64d6e452cfdd0f000df21895.glb',
  'https://models.readyplayer.me/64d6e51358f50a12df41f092.glb',
  'https://models.readyplayer.me/64d6e4f258f50a12df41f049.glb',
  'https://models.readyplayer.me/64d6e53958f50a12df41f0b1.glb',
  'https://models.readyplayer.me/64d6e4d958f50a12df41f036.glb',
];

export const firstNames: Array<string> = [
  'Aisha',
  'Carlos',
  'Lakshmi',
  'Dmitri',
  'Fatima',
  'Sean',
  'Hikari',
  'Ade',
  'Olga',
  'Kwame',
  'Isabella',
  'Ming',
  'Priya',
  'Jorge',
  'Eshe',
  'Liam',
  'Siti',
  'Hiroshi',
  'Alejandra',
  'Samuel',
  'Jada',
  'Pavel',
  'Zhen',
  'Rosa',
  'Ravi',
  'Naomi',
  'Rashid',
  'Sofia',
  'Youssef',
  'Natalia',
  'Akio',
  'Amirah',
  'Bjorn',
  'Keisha',
  'Tariq',
  'Ingrid',
  'Chinwe',
  'Lars',
  'Deepa',
  'Juan',
  'Ayesha',
  'Raj',
  'Thabo',
  'Lucia',
  'Hamid',
  'Elena',
  'Yaw',
  'Mariam',
  'Sergei',
  'Farida',
];

export const lastNames: Array<string> = [
  'Nguyen',
  'Patel',
  'Smith',
  'García',
  'Kim',
  'Mwangi',
  'Ivanov',
  'Al-Hassan',
  'Suzuki',
  'Singh',
  'Rossi',
  'Cohen',
  'Kone',
  'Chen',
  'Moreno',
  'Okafor',
  'Johansson',
  'Gutierrez',
  'Schmidt',
  'Kapoor',
  'Nakamura',
  'Silva',
  'Osei',
  'Castillo',
  'Gupta',
  'Andersson',
  'Mahmoud',
  'Dubois',
  'Zimmerman',
  'Das',
  'Wangari',
  'Bello',
  'Jansen',
  'Almeida',
  'Hernandez',
  'Krishnan',
  'Ayala',
  'Oluwa',
  'Dube',
  'Ribeiro',
  'Shah',
  'Adebayo',
  'Green',
  'Mendoza',
  'Jamal',
  'Mhlanga',
  'Onyango',
  'Yılmaz',
  'Ramos',
  'Kaur',
];

export const personalities: Array<string> = [
  'Outgoing',
  'Reflective',
  'Analytical',
  'Compassionate',
  'Assertive',
  'Passive',
  'Spontaneous',
  'Methodical',
  'Ambitious',
  'Laid-back',
  'Artistic',
  'Grounded',
  'Optimistic',
  'Pessimistic',
  'Empathetic',
  'Competitive',
  'Introverted',
  'Extraverted',
  'Curious',
  'Steadfast',
  'Impulsive',
  'Deliberate',
  'Charismatic',
  'Shy',
  'Tenacious',
  'Passionate',
  'Reserved',
  'Thoughtful',
  'Easygoing',
  'Motivated',
  'Imaginative',
  'Nurturing',
  'Adventurous',
  'Captivating',
  'Inquisitive',
];

export const occupations: Array<string> = [
  'Software Developer',
  'Nurse',
  'Archaeologist',
  'Chef',
  'Civil Engineer',
  'Journalist',
  'Florist',
  'Astronaut',
  'Accountant',
  'Marine Biologist',
  'Farmer',
  'Ballet Dancer',
  'Urban Planner',
  'Electrician',
  'Translator',
  'Veterinarian',
  'Firefighter',
  'Historian',
  'Gemologist',
  'Carpenter',
  'Radiologist',
  'Pilot',
  'Statistician',
  'Tattoo Artist',
  'Oceanographer',
  'Librarian',
  'Wind Turbine Technician',
  'Zoologist',
  'Social Worker',
  'Magistrate',
  'Animator',
  'Forensic Scientist',
  'Nutritionist',
  'Sound Engineer',
  'Tour Guide',
  'Pharmacist',
  'Meteorologist',
  'Fashion Designer',
  'Actuary',
  'Pastry Chef',
  'Conservationist',
  'Ship Captain',
  'Optometrist',
  'Biomedical Engineer',
  'Jewelry Designer',
  'Cinematographer',
  'Sports Coach',
  'Flight Attendant',
  'Geologist',
  'Music Therapist',
];
