import classNames from 'classnames';
import React, { useState } from 'react';

import { useApiUpdateAgentStatus } from 'src/api/agent/hooks/useApiManage';

import Select from 'src/components/Select';

import { dateTimeWithCommaFormat } from 'src/constants/date';

import { dateToFormat } from 'src/helpers/datetime';

import useTexts from 'src/hooks/useTexts';

import {
  AgentInstanceStatusBackendFormat,
  InstanceResponse,
} from 'src/types/dao/agent.types';

import styles from '../../styles.module.scss';

interface CheckpointColumnProps {
  instance: InstanceResponse;
}

const CheckpointColumn: React.FC<CheckpointColumnProps> = ({ instance }) => {
  const [newStatus, setNewStatus] = useState(instance.status);
  const { t } = useTexts();

  const { doRequest: updateAgentStatus } = useApiUpdateAgentStatus();

  const handleStatusChange = (value: string) => {
    setNewStatus(value as AgentInstanceStatusBackendFormat);
    updateAgentStatus({
      agentInstanceId: instance.id,
      agentInstance: {
        ...instance,
        status: value as AgentInstanceStatusBackendFormat,
      },
    });
  };
  return (
    <div className={styles['checkpoint-column']}>
      <div className={classNames(styles['checkpoint-column_wrap'])}>
        <h3>{instance.name}</h3>
        <div className={styles['column-info']}>
          <p className={styles['last-played']}>
            Last played: <br />
            <strong>
              {dateToFormat(
                new Date(instance.last_updated_time),
                dateTimeWithCommaFormat,
              )}
            </strong>
          </p>
        </div>
        <div className={styles['column-info']}>
          <Select
            label={t('pages.admin_status')}
            value={newStatus}
            onSelect={handleStatusChange}
            theme='white'
            options={[
              ...Object.values(AgentInstanceStatusBackendFormat).map((key) => ({
                value: key,
                label: t('general.' + key),
              })),
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckpointColumn;
