import classNames from 'classnames';
import { FC } from 'react';

import { LoaderIcon } from '../Icons';
import styles from './styles.module.scss';

export type Props = {
  fullPage?: boolean;
  absolute?: boolean;
  backdrop?: boolean;
  className?: string;
};

const Loader: FC<Props> = ({ fullPage, absolute, backdrop, className }) => {
  return (
    <div
      className={classNames(className, styles['loader'], {
        [styles['page-loader-wrapper']]: fullPage,
        [styles['loader-wrapper']]: !fullPage,
        [styles['absolute']]: absolute && !fullPage,
        [styles['backdrop']]: backdrop,
      })}
    >
      <LoaderIcon
        className={classNames('icon', styles['icon'], {
          [styles['full-page']]: fullPage,
        })}
      />
    </div>
  );
};

export default Loader;
