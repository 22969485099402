import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { User } from 'common-ui';
import React, { FC } from 'react';

import NothingToShow from 'src/components/NothingToShow';
import Pagination from 'src/components/Pagination';
import TableList from 'src/components/TableList';

import { GameCellData, setGameTableData } from 'src/helpers/games';

import useTexts from 'src/hooks/useTexts';

import { GameInstance } from 'src/types/dao/game.types';

import { renderGameCell, tableHeaders } from '../../common';
import { GameTableActionConfig } from '../../index';
import styles from '../../styles.module.scss';
import Histogram from '../Histogram';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export type BasicStatisticsProps = {
  stats: {
    totalPlayTime: number;
    totalPlaySessions: number;
    uniquePlayers: number;
    avgPlaySessionLength: number;
    avgPlaySessionLengthAll: number;
  };
  tableActions: Omit<GameTableActionConfig, 't'>;
  reloadInstances?: () => void;
  topUsers: { name: string; time: number }[];
  sessionLengthHistogram: number[];
  completedGameInstances: GameInstance[];
  users: Record<string, User>;
  paginationConfig: {
    loadNextCompletedPage: () => void;
    loadPrevCompletedPage: () => void;
    completedGamePage: number;
    canLoadNextCompleted: boolean;
    canLoadPrevCompleted: boolean;
  };
};

const BasicStatistics: FC<BasicStatisticsProps> = ({
  stats,
  topUsers,
  sessionLengthHistogram,
  completedGameInstances,
  users,
  tableActions,
  paginationConfig,
}) => {
  const { t } = useTexts();

  const maxTime = Math.max(...topUsers.map((user) => user.time));

  const gameTableData: GameCellData[] = setGameTableData(
    completedGameInstances,
    users,
  );

  const tableActionConfig: GameTableActionConfig = {
    redirectToGame: (id) => tableActions.redirectToGame(id),
    downloadInstanceLog: (id) => {
      tableActions.downloadInstanceLog(id);
    },
    copyGameInstanceId: (id) => {
      tableActions.copyGameInstanceId(id);
    },
    t,
  };

  return (
    <div className={styles['']}>
      <div className={styles['basic-stats']}>
        <h3>{t('stats.basic')}</h3>
        <p>
          <span>{t('stats.total_play_time')}:</span>{' '}
          {stats.totalPlayTime.toFixed(2)} seconds
        </p>
        <p>
          <span>{t('stats.total_play_sessions')}:</span>{' '}
          {stats.totalPlaySessions}
        </p>
        <p>
          <span>{t('stats.unique_players')}:</span> {stats.uniquePlayers}
        </p>
        <p>
          <span>{t('stats.avg_play_session_length')}:</span>{' '}
          {stats.avgPlaySessionLength.toFixed(2)} seconds
        </p>
        <p>
          <span>{t('stats.avg_play_session_length_all')}:</span>{' '}
          {stats.avgPlaySessionLengthAll.toFixed(2)} seconds
        </p>

        <h3>{t('stats.host_play_time')}</h3>
        <div
          style={{
            height: `${Math.min(topUsers.length * 50, 800)}px`,
          }}
        >
          {topUsers.map((user, index) => (
            <div
              key={user.name}
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '8px 0',
              }}
            >
              <div
                style={{
                  width: '200px',
                  textAlign: 'right',
                  marginRight: '15px',
                  fontSize: '14px',
                }}
              >
                {user.name}
              </div>
              <div
                style={{
                  height: '25px',
                  width: `${(user.time / maxTime) * 300}px`,
                  backgroundColor: '#4CAF50',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '8px',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                {user.time.toFixed(2)}m
              </div>
            </div>
          ))}
        </div>

        <h3>{t('stats.session_length_histogram')}</h3>
        <Histogram sessionLengthHistogram={sessionLengthHistogram} />
      </div>
      <div className={styles['completed-game-wrapper']}>
        <h3>{t('stats.games')}:</h3>
        {completedGameInstances.length ? (
          <>
            <TableList<GameCellData, GameTableActionConfig>
              headers={tableHeaders}
              data={gameTableData}
              renderCell={renderGameCell}
              tableActionConfig={tableActionConfig}
            />
            <div className={styles['pagination-wrapper']}>
              <Pagination
                loadNextPage={paginationConfig.loadNextCompletedPage}
                loadPrevPage={paginationConfig.loadPrevCompletedPage}
                pageNumber={paginationConfig.completedGamePage}
                canLoadNext={paginationConfig.canLoadNextCompleted}
                canLoadPrev={paginationConfig.canLoadPrevCompleted}
              />
            </div>
          </>
        ) : (
          <NothingToShow />
        )}
      </div>
    </div>
  );
};

export default BasicStatistics;
