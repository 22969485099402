import React, { FC } from 'react';

import PageContainer from 'src/components/PageContainer';

import Users from 'src/features/pages/private/Users';

const UsersPage: FC = () => {
  return (
    <PageContainer simple width={false}>
      <Users />
    </PageContainer>
  );
};

export default UsersPage;
