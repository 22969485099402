import { api } from 'common-ui';
import { URLS } from 'common-ui';

import { InvitationsListResponse } from 'src/types/api/invitation.types';

type GetInvitationsProps = {
  page?: number;
  perPage?: number;
  filters?: {
    created_by?: string;
    redeemed_by?: string;
    from_time?: number;
    to_time?: number;
  };
};

export const makeApiAdminGetInvitations =
  () =>
  ({ page, perPage, filters }: GetInvitationsProps = {}) =>
    api.get<InvitationsListResponse>(URLS.invitation.admin.getInvitations, {
      params: {
        ...(page && { page }),
        ...(perPage && { per_page: perPage }),
        ...(filters || {}),
      },
    });
