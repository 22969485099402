import { useCallback, useEffect } from 'react';
import { Prompt } from 'react-router';

type Props = {
  when: boolean;
  message: string;
};

const useNavigationBlock = ({ when, message }: Props) => {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
    },
    [message],
  );

  const NavigationGuard = useCallback(
    () => <Prompt when={when} message={message} />,
    [message, when],
  );

  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload, when]);

  return {
    NavigationGuard,
  };
};

export default useNavigationBlock;
