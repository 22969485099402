import { useMemo } from 'react';

import { useApi } from 'src/services/api/hooks/useApi';

import { BaseUseApiProps } from 'src/types/api/api.types';
import { InvitationsListResponse } from 'src/types/api/invitation.types';

import { makeApiAdminGetInvitations } from '../functions/makeApiAdminGet';

export const useApiAdminGetInvitations = ({
  onSuccess,
  onResponse,
  onError,
}: BaseUseApiProps<InvitationsListResponse> = {}) => {
  const doRequest = useMemo(() => makeApiAdminGetInvitations(), []);
  return useApi({
    doRequest,
    onSuccess,
    onResponse,
    onError,
  });
};
