import { useCallback, useEffect, useMemo, useState } from 'react';
import { ObjectSchema } from 'yup';

import useValidation from './useValidation';

export type SetFormDataValue<FormData> = <Key extends keyof FormData>(
  key: Key,
  value: FormData[Key],
) => void;

export type FormDataSetters<FormData> = {
  [K in keyof FormData]: (value: FormData[K]) => void;
};

const useFormData = <FormData extends Record<string, unknown>>({
  initialData,
  validationSchema,
}: {
  initialData: FormData;
  validationSchema?: ObjectSchema<Partial<FormData>>;
}) => {
  const [formData, setFormData] = useState<FormData>({ ...initialData });

  const { validate, validateThrottle, errors, resetErrors } = useValidation({
    schema: validationSchema,
    data: formData,
  });

  const setFormDataValue = useCallback<SetFormDataValue<FormData>>(
    (key, value) => {
      setFormData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [],
  );

  const resetFormData = useCallback(() => {
    setFormData({ ...initialData });
  }, [initialData]);

  const formDataSetters = useMemo<FormDataSetters<FormData>>(<
    Key extends keyof FormData,
  >() => {
    const keys: Key[] = Object.keys(formData) as Key[];
    const resArray = [];

    for (const key of keys) {
      const setter = (value: FormData[Key]) => {
        setFormDataValue(key, value);
        if (errors && errors[key]) {
          validateThrottle();
        }
      };
      resArray.push([key, setter]);
    }
    return Object.fromEntries(resArray);
  }, [errors, formData, setFormDataValue, validateThrottle]);

  useEffect(() => {
    setFormData({ ...initialData });
  }, [initialData]);

  return {
    formData,
    formDataSetters,
    resetFormData,

    validate,
    errors,
    resetErrors,
  };
};

export default useFormData;
