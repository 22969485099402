import React, { FC } from 'react';

import AddButton from 'src/components/Buttons/AddButton';
import TextField from 'src/components/Inputs/TextField';
import ConfirmModal from 'src/components/Modals/ConfirmModal';

import ApiKeysList from 'src/features/components/settings/ApiKeysList';

import useTexts from 'src/hooks/useTexts';

import { useComponentProps } from './hooks/useComponentProps';
import styles from './styles.module.scss';

const Settings: FC = () => {
  const {
    apiKeys,
    isLoading,
    getApiKeys,
    createModalOpen,
    setCreateModalOpen,
    handleCreateApiKey,
    isCreateApiKeyLoading,
    formData,
    formDataSetters,
    errors,
  } = useComponentProps();

  const { t } = useTexts();

  return (
    <>
      <div className={styles['container']}>
        <h2 className={styles['title']}>{t('settings.settings')}</h2>
        <div className={styles['create-button-wrapper']}>
          <div
            className={styles['create-button']}
            onClick={() => setCreateModalOpen(true)}
          >
            {t('settings.create_api_key')}
          </div>
        </div>
        <ApiKeysList
          apiKeys={apiKeys || []}
          reload={getApiKeys}
          isLoading={isLoading}
        />
      </div>
      {createModalOpen && (
        <ConfirmModal
          setOpen={setCreateModalOpen}
          title={t('settings.create_api_key')}
          onConfirm={handleCreateApiKey}
          confirmButtonLabel={t('form.confirm')}
          isConfirmLoading={isCreateApiKeyLoading}
          size='sm'
        >
          <div className={styles['modal-container']}>
            <p className={styles['modal-subtitle']}>
              {t('settings.create_api_key_desc')}
            </p>
            <TextField
              value={formData.name}
              label={t('settings.api_key_name')}
              onChange={(value) => {
                formDataSetters.name(value);
              }}
              {...(errors?.name && {
                error: true,
                helperText: errors.name,
              })}
              theme='black'
            />
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default Settings;
