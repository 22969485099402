import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';

import NothingToShow from 'src/components/NothingToShow';
import Pagination from 'src/components/Pagination';
import ChatMessagesPanel from 'src/components/SidePanels/SidePanel/FeaturedSidePanel/ChatMessagesPanel';
import TableList from 'src/components/TableList';

import { ADMIN_CHATS_ROUTE } from 'src/constants/routes';

import useTexts from 'src/hooks/useTexts';

import { ChatData } from 'src/types/api/chat.types';

import { renderChatCell, tableHeaders } from './common';
import { useComponentProps } from './hooks/useComponentProps';
import styles from './styles.module.scss';

const ChatsList: FC = () => {
  const [chatId, setChatId] = useState<string | null>(null);
  const {
    chatListData,
    canLoadNextPage,
    canLoadPrevPage,
    loadNextPage,
    loadPrevPage,
    activeChatPage,
    isChatMessagesLoading,
    chatMessagesData,
    canLoadNextMessagesPage,
    canLoadPrevMessagesPage,
    loadNextMessagesPage,
    loadPrevMessagesPage,
    activeMessagesPage,
  } = useComponentProps({
    chatId,
  });
  const { t } = useTexts();
  const history = useHistory();
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);

  const handleAgentInfoClick = async (chat: ChatData) => {
    history.push(`${ADMIN_CHATS_ROUTE}/${chat.id}`);
    setChatId(chat.id);
    setShowSidePanel(true);
  };

  const onCloseSidePanel = () => {
    setShowSidePanel(false);
    history.push(ADMIN_CHATS_ROUTE);
  };

  const panelConfig = {
    isChatMessagesLoading,
    chatMessagesData,
    canLoadNextMessagesPage,
    canLoadPrevMessagesPage,
    loadNextMessagesPage,
    loadPrevMessagesPage,
    activeMessagesPage,
  };

  return (
    <div className={styles['page']}>
      <div className={styles['title-container']}>
        <h2>{t('pages.chats')}:</h2>
      </div>
      {chatListData.length ? (
        <div className={styles['chat-list-wrapper']}>
          <TableList<ChatData, null>
            headers={tableHeaders}
            data={chatListData}
            onCellClick={handleAgentInfoClick}
            renderCell={renderChatCell}
          />
          <div className={styles['pagination-wrapper']}>
            <Pagination
              loadNextPage={loadNextPage}
              loadPrevPage={loadPrevPage}
              pageNumber={activeChatPage}
              canLoadNext={canLoadNextPage}
              canLoadPrev={canLoadPrevPage}
            />
          </div>
        </div>
      ) : (
        <NothingToShow />
      )}

      <ChatMessagesPanel
        onPanelClose={onCloseSidePanel}
        isOpen={showSidePanel}
        panelConfig={panelConfig}
      />
    </div>
  );
};

export default ChatsList;
