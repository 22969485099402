const en = require('src/data/locales/en.json');
import { useCallback } from 'react';

type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

type DeepKeyOf = NestedKeyOf<typeof en>;

const useTexts = () => {
  const getTextByKey = useCallback((key: DeepKeyOf): string | undefined => {
    const path = key.split('.');
    let result = en;

    for (const pathKey of path) {
      if (result) {
        result = result[pathKey];
      }
    }

    return typeof result !== 'object' ? `${result}` : undefined;
  }, []);

  const t = useCallback(
    (key: DeepKeyOf): string => getTextByKey(key) || '',
    [getTextByKey],
  );

  return { t };
};

export default useTexts;
