import { ChatData } from 'src/types/api/chat.types';

export const renderChatCell = (chat: ChatData) => ({
  name: chat.name,
  createdAt: chat.created_at
    ? new Date(chat.created_at).toLocaleString()
    : null,
  status: chat.status,
});

export const tableHeaders = ['Name', 'Created At', 'Status'];
