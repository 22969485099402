import { api } from 'common-ui';
import { URLS } from 'common-ui';

import { ApiKeyFormData } from 'src/types/api/keys.types';

export const makeApiDeleteApiKey =
  () =>
  ({ apiKeyId }: { apiKeyId: string }) =>
    api.delete<void>(URLS.keys.deleteApiKey, {
      params: { api_key_id: apiKeyId },
    });

export const makeApiCreateApiKey =
  () =>
  ({ formData }: { formData: ApiKeyFormData }) =>
    api.post<void>(URLS.keys.createApiKey, formData);
