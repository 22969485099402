import { getTierWeight, UserTier } from 'common-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useApiAdminGetAgentCheckpoints,
  useApiAdminGetAgentInstances,
} from 'src/api/agent/hooks/useApiAdminGet';
import { useApiGetAgent } from 'src/api/agent/hooks/useApiGet';
import { useApiCopyAgent } from 'src/api/agent/hooks/useApiManage';

import { prepareInitialAgentFormData } from 'src/features/components/agent/AgentEditForm/functions/formData';

import { useAppSelector } from 'src/store/hooks';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import { GetAgentResponse } from 'src/types/api/agent.types';
import { AgentFormData } from 'src/types/dao/agent.types';

type Props = {
  id: string;
};

const useComponentProps = ({ id }: Props) => {
  const [initialData, setInitialData] = useState<AgentFormData | undefined>();
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);

  const history = useHistory();

  const { doRequest: getAgent, isLoading } = useApiGetAgent({
    onResponse: useCallback((data: GetAgentResponse) => {
      setInitialData(prepareInitialAgentFormData(data));
    }, []),
    onError: useCallback(() => {
      history.push('/');
    }, [history]),
  });

  const { doRequest: getAgentCheckpoints, responseData: agentCheckpoints } =
    useApiAdminGetAgentCheckpoints();

  const { doRequest: getAgentInstances, responseData: agentInstances } =
    useApiAdminGetAgentInstances();

  const { doRequest: copyAgent, isLoading: isCopyAgentLoading } =
    useApiCopyAgent({
      onSuccess: useCallback(() => {
        setCopyModalOpen(false);
      }, []),
    });

  useEffect(() => {
    getAgent({ agentId: id });
    getAgentCheckpoints({ agent_id: id });
    getAgentInstances({ ids: [id] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentUser = useAppSelector(getCurrentUser);
  const canCopy = useMemo<boolean>(() => {
    return (
      getTierWeight(currentUser?.access_tier) >=
      getTierWeight(UserTier.GAME_EDITOR)
    );
  }, [currentUser?.access_tier]);

  return {
    isLoading,
    initialData,
    copyModalOpen,
    setCopyModalOpen,
    canCopy,
    copyAgent,
    isCopyAgentLoading,
    checkpoints: agentCheckpoints?.data || [],
    agentInstances: agentInstances?.data || [],
  };
};

export default useComponentProps;
