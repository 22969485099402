import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ADMIN_RUNNING_GAME_PLAYS_ROUTE } from 'src/constants/routes';

import useTexts from 'src/hooks/useTexts';

import styles from './styles.module.scss';

const PaymentCanceled: FC = () => {
  const [timeToRedirect, setTimeToRedirect] = useState<number>(10);

  const { t } = useTexts();
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToRedirect((prevState) => prevState - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (timeToRedirect < 0) {
      history.replace(ADMIN_RUNNING_GAME_PLAYS_ROUTE);
    }
  }, [history, timeToRedirect]);

  return (
    <div className={styles['wrapper']}>
      <h2>{t('payment.checkout_canceled')}</h2>
      <p>
        {t('payment.you_will_be_redirected')}
        {': '}
        {timeToRedirect < 0 ? 0 : timeToRedirect}
      </p>
    </div>
  );
};

export default PaymentCanceled;
