import { FC, useEffect, useState } from 'react';

import { checkImage } from 'src/helpers/checkImage';

import { DefaultAvatarIcon } from '../Icons';

type AvatarProps = {
  image: string;
  avatarClassName: string;
  defaultAvatarClassName: string;
};

const Avatar: FC<AvatarProps> = ({
  image,
  avatarClassName,
  defaultAvatarClassName,
}) => {
  const [imageError, setImageError] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    let imageFormat;
    if (image.endsWith('.glb')) {
      imageFormat = `${image.replace('.glb', '.jpg')}?camera=portrait&retry=0`;
    } else {
      imageFormat = image;
    }

    checkImage(imageFormat).then((exists) => {
      if (!exists) {
        setImageUrl(null);
        setImageError(true);
      } else {
        setImageUrl(imageFormat);
        setImageLoaded(true);
      }
    });
  }, [image]);

  return (
    <>
      {imageLoaded && !imageError ? (
        <div className={avatarClassName}>
          {imageUrl && <img src={imageUrl} alt='avatar' />}
        </div>
      ) : (
        <DefaultAvatarIcon className={defaultAvatarClassName} />
      )}
    </>
  );
};

export default Avatar;
